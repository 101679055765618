import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { BodyText, FallbackImage, IllusEmpty_2 } from '@sg/garnish';

/**
 * Info regarding loyalty rewards availability.
 */
export const BagLoyaltyInfo = (props: BagLoyaltyInfoProps) => {
  return (
    <View style={styles.container}>
      {props.loyaltyInfoBenefits.map((loyaltyInfoBenefit) => (
        <BagLoyaltyInfoRow
          key={loyaltyInfoBenefit.title}
          availablePoints={props.availablePoints}
          loyaltyInfoBenefit={loyaltyInfoBenefit}
        />
      ))}
    </View>
  );
};

const BagLoyaltyInfoRow = (props: BagLoyaltyInfoRowProps) => {
  const {
    availablePoints,
    loyaltyInfoBenefit: { title, points, imageUrl },
  } = props;

  const hasEnoughPoints = availablePoints >= points;

  const { formatMessage } = useIntl();

  return (
    <View style={styles.reward}>
      <View
        style={[
          styles.pointsWrapper,
          hasEnoughPoints ? null : styles.pointsWrapperUnusable,
        ]}
      >
        <BodyText
          bold
          sizeMatch={['12']}
          style={[
            styles.points,
            hasEnoughPoints ? null : styles.pointsUnusable,
          ]}
        >
          {points}
        </BodyText>
      </View>

      <FallbackImage
        contentFit="contain"
        style={styles.image}
        defaultImage={IllusEmpty_2}
        baseUrl={imageUrl}
        contentfulOptions={contentfulOptions}
        aria-label={formatMessage(messages.accessibilityLabel)}
      />

      <BodyText sizeMatch={['16']}>{title}</BodyText>
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  accessibilityLabel: {
    defaultMessage: 'Loyalty reward',
    description: 'Bag | Loyalty info | Accessibility Label',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.CREAM,
    borderColor: theme.colors.KALE,
    borderRadius: theme.radius.medium,
    borderWidth: 1,
    margin: theme.spacing['4'],
    marginTop: theme.spacing['16'],
  },
  reward: {
    flexDirection: 'row',
    gap: theme.spacing['2'],
    paddingHorizontal: theme.spacing['4'],
    alignItems: 'center',
  },
  pointsWrapper: {
    height: theme.spacing['6'],
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: theme.spacing['2'],
    borderRadius: theme.radius.xxxlarge,
    backgroundColor: theme.colors.BASIL,
  },
  pointsWrapperUnusable: {
    backgroundColor: theme.colors.CREAM,
    borderColor: theme.colors.BASIL,
    borderWidth: 1,
  },
  points: {
    marginTop: 2,
    textAlign: 'center',
    minWidth: 50,
    color: theme.colors.CREAM,
  },
  pointsUnusable: {
    color: theme.colors.BASIL,
  },
  image: {
    width: 60,
    height: 60,
  },
});

const contentfulOptions = {
  fm: 'webp' as const,
  q: 90,
  w: 60,
  h: 60,
};

// ─── Types ──────────────────────────────────────────────────────────────────

type BagLoyaltyInfoProps = {
  availablePoints: number;
  loyaltyInfoBenefits: LoyaltyInfoBenefit[];
};

type BagLoyaltyInfoRowProps = {
  availablePoints: number;
  loyaltyInfoBenefit: LoyaltyInfoBenefit;
};

type LoyaltyInfoBenefit = {
  title: string;
  points: number;
  imageUrl: string;
};
