import React, { useCallback, useRef } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  HostedFrame,
  type HostedFrameRef,
  theme,
  useResponsive,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';

import { CreditCardFormControlledContent } from '../../components/CreditCard/CreditCardFormControlledContent';
import { CreditCardFormLoadingState } from '../../components/CreditCard/CreditCardFormLoadingState';
import { useCreditCardForm } from '../../hooks/useCreditCardForm';
import {
  useBraintreeFormSubmission,
  useBraintreeMessages,
  useBraintreeSource,
} from './hooks';
import type { BraintreeVault } from './utils';
import { BRAINTREE_FRAME_ID, FRAME_HEIGHT } from './utils';

export const BraintreeCreditCardForm = (props: FormProps) => {
  const { vault, excludedFields = [], isSubmitting, handleOnSave } = props;

  // ─── Context ─────────────────────────────────────────────────────────

  const { track } = useTelemetry();
  const { match } = useResponsive();
  const { t } = useLocalizationContext();

  // ─── Controlled Form State ───────────────────────────────────────────

  const { form, formData, updateForm } = useCreditCardForm({ default: true });

  // ─── Submission Results ──────────────────────────────────────────────

  const handleBraintreeSuccess = useCallback(
    (nonce: string) => {
      const payload = form.getValues();

      if (payload.default) track('payment_credit.set_default');
      track('payment_credit.save_card');

      handleOnSave({
        id: nonce,
        nickname: payload.nickname,
        isDefault: payload.default,
      });
    },
    [form, track, handleOnSave],
  );

  const handleBraintreeError = useCallback(
    (userError?: string, systemError?: string) => {
      track('payment_credit.save_card_failed', { userError, systemError });
    },
    [track],
  );

  // ─── Webview Setup ───────────────────────────────────────────────────

  const braintreeWebSource = useBraintreeSource(vault);
  const hostedFrameRef = useRef<HostedFrameRef>(null);
  const {
    isLoadingFields,
    isTokenizing,
    hasCardNumber,
    hasExpDate,
    hasCvv,
    hasPostalCode,
    handleBraintreeMessages,
  } = useBraintreeMessages({
    onSuccess: handleBraintreeSuccess,
    onError: handleBraintreeError,
  });

  // ─── Callbacks ───────────────────────────────────────────────────────

  const submitForm = useBraintreeFormSubmission({
    isLoadingFields,
    hasCardNumber,
    hasExpDate,
    hasCvv,
    hasPostalCode,
    hostedFrameRef,
  });

  return (
    <CreditCardFormControlledContent
      canSubmitForm
      mode="add"
      isSaving={isSubmitting || isTokenizing}
      form={formData}
      excludedFields={excludedFields}
      onFormChange={updateForm}
      onSubmitForm={submitForm}
    >
      <View style={styles.wrapper}>
        <CreditCardFormLoadingState
          style={styles.wrapper}
          show={isLoadingFields}
        />
        <HostedFrame
          ref={hostedFrameRef}
          frameId={BRAINTREE_FRAME_ID}
          frameHeight={FRAME_HEIGHT}
          title={t('credit-card-form.title')}
          isLoading={isLoadingFields}
          source={braintreeWebSource}
          onMessage={handleBraintreeMessages}
          style={styles.webView}
          containerStyle={[
            match([styles.containerXs, styles.containerMd]),
            isLoadingFields && styles.loadingFields,
          ]}
        />
      </View>
    </CreditCardFormControlledContent>
  );
};

// ─── Types ─────────────────────────────────────────────────────────────

type FormProps = Readonly<{
  vault: BraintreeVault;
  excludedFields?: ReadonlyArray<'nickname' | 'isDefault'>;
  isSubmitting: boolean;
  handleOnSave: (payload: CreditCardForm) => void;
}>;

type CreditCardForm = Readonly<{
  id: string;
  nickname: string;
  isDefault: boolean;
}>;

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: theme.colors.APP_BACKGROUND,
    height: FRAME_HEIGHT,
  },
  containerXs: {
    backgroundColor: theme.colors.APP_BACKGROUND,
    paddingHorizontal: theme.spacing['4'],
    height: FRAME_HEIGHT,
  },
  containerMd: {
    backgroundColor: theme.colors.APP_BACKGROUND,
    paddingHorizontal: theme.spacing['6'],
    height: FRAME_HEIGHT,
  },
  // for web views to function properly on Android, their container must be at least 1x1 in size
  loadingFields: {
    flex: 0,
    width: 1,
    height: 1,
  },
  webView: {
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
});
