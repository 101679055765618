import React from 'react';
import type { ViewProps, ViewStyle } from 'react-native';
import { StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useStyle } from 'react-native-style-utilities';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../../../hooks';
import { Container } from '../../../../Container';

// ─────────────────────────────────────────────────────────────────────────────

export const PageHeroHeaderContainer = (
  props: PageHeroHeaderContainerProps,
) => {
  const { children, paletteStyle, withTopPadding } = props;

  const { match } = useResponsive();
  const { top: safeAreaTop } = useSafeAreaInsets();

  // ─── Styles ──────────────────────────────────────────────────────────

  const shouldUseSafeAreaInset = safeAreaTop > CONTAINER_TOP_PADDING;

  const containerTopPaddingStyle = useStyle(
    () => ({
      paddingTop: shouldUseSafeAreaInset ? safeAreaTop : CONTAINER_TOP_PADDING,
    }),
    [shouldUseSafeAreaInset, safeAreaTop],
  );
  const containerXsStyles = [
    withTopPadding ? containerTopPaddingStyle : undefined,
    styles.containerXS,
  ];
  const containerResponsiveStyles = match([
    containerXsStyles,
    styles.containerSM,
  ]);
  const containerStyles = [styles.container, containerResponsiveStyles];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Container style={containerStyles} wrapperStyle={paletteStyle}>
      {children}
    </Container>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const CONTAINER_TOP_PADDING = theme.spacing['10'];

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  containerXS: {
    paddingBottom: theme.spacing['10'],
  },
  containerSM: {
    paddingVertical: theme.spacing['20'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type PageHeroHeaderContainerProps = Readonly<{
  paletteStyle: ViewStyle;
  children: ViewProps['children'];
  withTopPadding?: boolean;
}>;
