import React, { type ComponentProps } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { DisplayText, HorizontalScrollRail, useResponsive } from '@sg/garnish';

export const LoyaltyHomeChallengesContainer = (
  props: LoyaltyHomeChallengesContainerProps,
) => {
  const { minWidth, match } = useResponsive();

  return (
    <View style={match([styles.containerXs, styles.containerSm])}>
      {minWidth.isSM ? (
        <DisplayText style={styles.title} sizeMatch={['24']}>
          <FormattedMessage {...messages.offers} />
        </DisplayText>
      ) : null}

      <HorizontalScrollRail
        showNavControls={false}
        withoutHeaderBorder
        gap={theme.spacing['4']}
        outerOffset={-match([theme.spacing['10'], theme.spacing['30']])}
        itemVisiblePercentThreshold={99}
      >
        {props.children}
      </HorizontalScrollRail>
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  offers: {
    defaultMessage: 'Offers',
    description: 'Loyalty Home | Offers | Title',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerXs: {
    backgroundColor: theme.colors.FOREST,
    padding: theme.spacing['10'],
  },
  containerSm: {
    backgroundColor: theme.colors.FOREST,
    paddingVertical: theme.spacing['10'],
    paddingHorizontal: theme.spacing['30'],
    gap: theme.spacing['6'],
  },
  title: {
    color: theme.colors.WHITE,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyHomeChallengesContainerProps = Pick<
  ComponentProps<typeof HorizontalScrollRail>,
  'children'
>;
