import { addMinutes, formatISO } from 'date-fns';
import { type AddressType } from '@sg/garnish';

import {
  type DeliveryOrderDetail,
  DeliveryPreferenceType,
  type Ledger,
  type Order,
  OrderType,
  type PartialLineItem,
} from '@order/graphql';
import { ignoreTimezone } from '@order/utils';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A function that takes an {Order} and returns the cart info to be sent to the machine.
 */
export const prepareCart = (cart: Partial<Order>) => {
  const {
    id: orderId = '',
    orderType,
    restaurant,
    deliveryOrderDetail: maybeDeliveryOrderDetail,
    availableWantedTimes: availableTimes,
    lineItems: maybeLineItems,
    canTrackOrderStatus = false,
    ledger = {} as Ledger,
  } = cart;

  const {
    isOutpost,
    id: restaurantId = '',
    slug: restaurantSlug = '',
    name: restaurantName = '',
    deliveryMinSubtotal = 0,
    availableDropOffLocations,
  } = restaurant ?? {};

  const deliveryOrderDetail = (maybeDeliveryOrderDetail ??
    {}) as DeliveryOrderDetail;
  const { address } = deliveryOrderDetail;
  const { id: addressId, name: addressName } = address ?? {};

  const locationName =
    orderType === OrderType.Delivery
      ? addressName ?? restaurantName
      : restaurantName;

  const deliveryPreferences = {
    type: address?.deliveryPreference ?? DeliveryPreferenceType.LeaveAtDoor,
    notes: address?.notes ?? '',
  };

  const orderChannel: AddressType = (
    isOutpost ? 'outpost' : orderType?.toLowerCase() ?? 'pickup'
  ) as AddressType;

  const wantedTimes = (availableTimes ?? [])
    .map(mapTime)
    .filter(Boolean) as string[];

  const dropoffLocations =
    availableDropOffLocations?.map((entry) => ({
      value: entry.id,
      label: entry.name.toLowerCase(),
    })) ?? [];

  const lineItems = (maybeLineItems ?? []) as readonly PartialLineItem[];

  const appliedDiscounts = new Set(
    ledger?.discounts?.map((discount) => discount.name) ?? [],
  );

  return {
    orderId,
    orderChannel,
    locationName,
    wantedTimes,
    dropoffLocations,
    restaurantId,
    restaurantSlug,
    addressId,
    deliveryOrderDetail,
    deliveryPreferences,
    deliveryMinSubtotal,
    canTrackOrderStatus,
    lineItems,
    ledger,
    appliedDiscounts,
  };
};

// ─── Helpers ────────────────────────────────────────────────────────────────

const mapTime = ({ time, deliveryOffset }: WantedTime) => {
  const validTime = ignoreTimezone(time);

  if (!validTime) return undefined;

  return formatISO(addMinutes(validTime, deliveryOffset));
};

type WantedTime = {
  time: string;
  deliveryOffset: number;
};
