import { type WithTimingConfig } from 'react-native-reanimated';
import { theme } from '@garnish/constants';

import { ANIMATED_HEADER_SIZE } from '../AnimatedHeader';

export const ANIMATED_DIALOG_ITEM_TRANSITION = 400;
export const ANIMATED_DIALOG_ITEM_TRANSITION_DELAY = 400;
export const ANIMATED_DIALOG_ANIMATION_CONFIG: WithTimingConfig = {
  duration: ANIMATED_DIALOG_ITEM_TRANSITION,
};

export const ANIMATED_DIALOG_ITEM_PADDING_STYLE = {
  paddingTop: ANIMATED_HEADER_SIZE,
  paddingHorizontal: theme.spacing['4'],
  paddingBottom: theme.spacing['10'],
};
