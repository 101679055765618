import React, { type ComponentProps, type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';

import { type IconLink } from '../Icon';
import { ICON_SIZE } from './AnimatedHeader.constants';
import { AnimatedHeaderIcon } from './AnimatedHeader.Icon';

export const AnimatedHeaderButton = (props: AnimatedHeaderButtonProps) => {
  const { children, alignment } = props;

  return (
    <View
      style={[
        styles.container,
        alignment === 'start' ? styles.alignStart : null,
        alignment === 'end' ? styles.alignEnd : null,
      ]}
    >
      {children}
    </View>
  );
};

export const AnimatedHeaderBackButton = (
  props: Omit<ComponentProps<typeof IconLink>, 'name'>,
) => {
  const { accessibilityLabel = 'Go back', onPress } = props;

  return (
    <AnimatedHeaderButton alignment="start">
      {onPress ? (
        <AnimatedHeaderIcon
          name="IconArrowLeft"
          accessibilityLabel={accessibilityLabel}
          {...props}
        />
      ) : null}
    </AnimatedHeaderButton>
  );
};

export const AnimatedHeaderCloseButton = (
  props: Omit<ComponentProps<typeof IconLink>, 'name'>,
) => {
  const { accessibilityLabel = 'Close', onPress } = props;

  return (
    <AnimatedHeaderButton alignment="end">
      {onPress ? (
        <AnimatedHeaderIcon
          name="IconClose"
          accessibilityLabel={accessibilityLabel}
          {...props}
        />
      ) : null}
    </AnimatedHeaderButton>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    width: 100,
    height: ICON_SIZE,
  },
  alignStart: {
    alignSelf: 'flex-start',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  alignEnd: {
    alignSelf: 'flex-end',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type AnimatedHeaderButtonProps = PropsWithChildren & {
  alignment?: 'start' | 'end';
};
