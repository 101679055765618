import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Modal } from '@sg/garnish';

import { Loyalty } from '@order/features/loyalty';
import { useTrackEventEffect } from '@order/Telemetry';

import { useLoyaltyHowItWorksContent } from './useLoyaltyHowItWorksContent';

export const LoyaltyHowItWorksModal = (props: LoyaltyHowItWorksModalProps) => {
  const { visible, onRequestClose } = props;
  const content = useLoyaltyHowItWorksContent();

  useTrackEventEffect({ name: 'loyalty.how_it_works.view', skip: !visible });

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <Loyalty.HowItWorks.Container>
        <Loyalty.HowItWorks.Header>
          <Loyalty.HowItWorks.HeaderTitle>
            <FormattedMessage {...messages.header} />
          </Loyalty.HowItWorks.HeaderTitle>

          <Loyalty.HowItWorks.HeaderClose onPress={onRequestClose} />
        </Loyalty.HowItWorks.Header>

        <Loyalty.HowItWorks.CardsContainer>
          {content.map((item) => (
            <Loyalty.HowItWorks.Card.Container
              key={item.id}
              containerColor={item.backgroundColor}
            >
              <Loyalty.HowItWorks.Card.Title style={{ color: item?.textColor }}>
                {item.title}
              </Loyalty.HowItWorks.Card.Title>

              <Loyalty.HowItWorks.Card.Text style={{ color: item.textColor }}>
                {item.description}
              </Loyalty.HowItWorks.Card.Text>
            </Loyalty.HowItWorks.Card.Container>
          ))}
        </Loyalty.HowItWorks.CardsContainer>

        <Loyalty.HowItWorks.Footer />
      </Loyalty.HowItWorks.Container>
    </Modal>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  header: {
    defaultMessage: 'How it works',
    description: 'Loyalty | How it works | Header',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyHowItWorksModalProps = {
  visible: boolean;
  onRequestClose: () => void;
};
