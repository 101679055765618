import type { ComponentProps } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import Animated, {
  interpolate,
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
} from 'react-native-reanimated';
import { theme } from '@garnish/constants';

import { FadeView } from '../../FadeView';
import { IconLink } from '../../Icon';
import { HStack } from '../../Stack';
import { HorizontalScrollRailCount } from './HorizontalScrollRailCount';

// ────────────────────────────────────────────────────────────────────────────────

export const HorizontalScrollRailNav = (props: HorizontalScrollNavProps) => {
  const {
    count,
    showNavControls = false,
    disablePrevBtn = true,
    disableNextBtn = true,
    prevBtnA11yLabel = 'Previous item',
    nextBtnA11yLabel = 'Next item',
    onPrevBtnPress,
    onNextBtnPress,
  } = props;

  const prevBtnAnimatedStyle = useNavBtnAnimatedStyle({
    disabled: disablePrevBtn,
  });
  const nextBtnAnimatedStyle = useNavBtnAnimatedStyle({
    disabled: disableNextBtn,
  });

  return (
    <View style={styles.rowCenter}>
      <HorizontalScrollRailCount count={count} />
      <FadeView show={showNavControls}>
        <HStack gap={theme.spacing['4']}>
          <HorizontalScrollRailNavBtn
            name="IconArrowLeft"
            onPress={onPrevBtnPress}
            disabled={disablePrevBtn}
            style={prevBtnAnimatedStyle}
            accessibilityLabel={prevBtnA11yLabel}
          />

          <HorizontalScrollRailNavBtn
            name="IconArrowRight"
            onPress={onNextBtnPress}
            disabled={disableNextBtn}
            style={nextBtnAnimatedStyle}
            accessibilityLabel={nextBtnA11yLabel}
          />
        </HStack>
      </FadeView>
    </View>
  );
};

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

const HorizontalScrollRailNavBtn = (props: HorizontalScrollRailNavBtnProps) => {
  const { style, ...iconLinkProps } = props;

  return (
    <Animated.View style={style}>
      <IconLink
        color={theme.colors.BLACK}
        width={22}
        height={22}
        accessibilityRole="button"
        {...iconLinkProps}
      />
    </Animated.View>
  );
};

// ─── HOOKS ──────────────────────────────────────────────────────────────────────

const useNavBtnAnimatedStyle = (props: UseNavBtnAnimatedStyleProps) => {
  const { disabled } = props;

  const btnStateAnimation = useDerivedValue(() => {
    return withTiming(disabled ? 0 : 1, { duration: theme.transitions.base });
  }, [disabled]);

  return useAnimatedStyle(() => {
    return {
      opacity: interpolate(btnStateAnimation.value, [0, 1], [0.2, 1]),
    };
  }, []);
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type HorizontalScrollNavProps = Readonly<{
  count: number;
  showNavControls?: boolean;
  disablePrevBtn?: boolean;
  disableNextBtn?: boolean;
  onPrevBtnPress?: () => void;
  onNextBtnPress?: () => void;
  prevBtnA11yLabel?: string;
  nextBtnA11yLabel?: string;
}>;

type HorizontalScrollRailNavBtnProps = ComponentProps<typeof IconLink> &
  Pick<ComponentProps<typeof Animated.View>, 'style'>;

type UseNavBtnAnimatedStyleProps = Readonly<{
  disabled: boolean;
}>;

const styles = StyleSheet.create({
  rowCenter: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing['4'],
  },
});
