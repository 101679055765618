import { LoyaltyHomeBody } from './LoyaltyHomeBody';
import { LoyaltyHomeChallengesContainer } from './LoyaltyHomeChallengesContainer';
import { LoyaltyHomeContainer } from './LoyaltyHomeContainer';
import { LoyaltyHomeFooter } from './LoyaltyHomeFooter';
import { LoyaltyHomeHeader } from './LoyaltyHomeHeader';
import { LoyaltyHomeRewardsContainer } from './LoyaltyHomeRewardsContainer';

export const LoyaltyHome = {
  Container: LoyaltyHomeContainer,
  Header: LoyaltyHomeHeader,
  Body: LoyaltyHomeBody,
  Footer: LoyaltyHomeFooter,
  ChallengesContainer: LoyaltyHomeChallengesContainer,
  RewardsContainer: LoyaltyHomeRewardsContainer,
};
