import {
  CustomNavigationHeaderButton,
  CustomNavigationHeaderContainer,
  CustomNavigationHeaderLink,
  CustomNavigationHeaderText,
} from './components';

export const CustomNavigationHeader = {
  Container: CustomNavigationHeaderContainer,
  Button: CustomNavigationHeaderButton,
  Text: CustomNavigationHeaderText,
  Link: CustomNavigationHeaderLink,
};
