/**
 * Our sizes are based on multipliers of a base value of 4px.
 */

const BASE_SIZE_UNIT = 4;

export const SIZES = {
  '1': Number(BASE_SIZE_UNIT), // 4px
  '2': 2 * BASE_SIZE_UNIT, // 8px
  '3': 3 * BASE_SIZE_UNIT, // 12px
  '4': 4 * BASE_SIZE_UNIT, // 16px
  '5': 5 * BASE_SIZE_UNIT, // 20px
  '6': 6 * BASE_SIZE_UNIT, // 24px
  '8': 8 * BASE_SIZE_UNIT, // 32px
  '10': 10 * BASE_SIZE_UNIT, // 40px
  '12': 12 * BASE_SIZE_UNIT, // 48px
  '14': 14 * BASE_SIZE_UNIT, // 56px
  '15': 15 * BASE_SIZE_UNIT, // 60px
  '16': 16 * BASE_SIZE_UNIT, // 64px
  '20': 20 * BASE_SIZE_UNIT, // 80px
  '24': 24 * BASE_SIZE_UNIT, // 96px
  '30': 30 * BASE_SIZE_UNIT, // 120px
  '35': 35 * BASE_SIZE_UNIT, // 140px
};
