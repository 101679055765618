import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { type LoyaltyReward } from '../../GraphQL';
import { useLoyaltyInfoQuery } from '../../GraphQL/LoyaltyInfo.generated';

export const useLoyaltyInfo = (isLoggedIn: boolean) => {
  const [response] = useLoyaltyInfoQuery({
    requestPolicy: 'cache-and-network',
    pause: !isLoggedIn,
  });

  const { formatMessage } = useIntl();
  const guest = formatMessage(messages.guest);

  const isLoading = response.fetching;

  return useMemo(() => {
    const customer = response.data?.currentCustomer;
    const loyaltyProfile = response.data?.loyaltyProfile;
    const challenges = response.data?.challenges ?? [];
    const rewards: LoyaltyReward[] = [
      ...(loyaltyProfile?.rewards ?? []),
      ...(loyaltyProfile?.pointsRewards ?? []),
    ];

    return {
      isLoading,
      firstName: customer?.firstName ?? guest,
      availablePoints: loyaltyProfile?.points?.available ?? 0,
      pendingPoints: loyaltyProfile?.points?.pending ?? 0,
      totalPoints: loyaltyProfile?.points?.total ?? 0,
      challenges,
      rewards,
    };
  }, [
    guest,
    isLoading,
    response.data?.challenges,
    response.data?.currentCustomer,
    response.data?.loyaltyProfile,
  ]);
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  guest: {
    defaultMessage: 'Guest',
    description: 'Loyalty | Customer Name | Fallback',
  },
});
