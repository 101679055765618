import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import {
  BodyText,
  FallbackImage,
  IllusEmpty_2,
  theme,
  TitleText,
} from '@sg/garnish';

/**
 * Challenge for loyalty.
 */
export const LoyaltyChallenge = (props: LoyaltyChallengeProps) => {
  const { title, descriptiveTitle, imageUrl } = props;

  const { formatMessage } = useIntl();

  return (
    <View style={styles.container}>
      <FallbackImage
        contentFit="contain"
        style={styles.image}
        defaultImage={IllusEmpty_2}
        baseUrl={imageUrl}
        cloudinaryConfig={cloudinaryConfig}
        aria-label={formatMessage(messages.accessibilityLabel)}
      />

      <View style={styles.content}>
        <TitleText sizeMatch={['24']} style={styles.title} numberOfLines={2}>
          {title}
        </TitleText>

        <BodyText sizeMatch={['16']} style={styles.title}>
          {descriptiveTitle}
        </BodyText>
      </View>
    </View>
  );
};

// ─── Constants ──────────────────────────────────────────────────────────────

const WIDTH = 340;
const IMAGE_HEIGHT = 190;
const HEIGHT = 380;
const cloudinaryConfig = {
  width: WIDTH,
  height: IMAGE_HEIGHT,
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  accessibilityLabel: {
    defaultMessage: 'Image describing this offer',
    description: 'Loyalty | Offer | Offer A11y',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    width: WIDTH,
    height: HEIGHT,
    borderTopLeftRadius: theme.radius.large,
    borderTopRightRadius: theme.radius.large,
    backgroundColor: theme.colors.KALE,
  },
  image: {
    width: WIDTH,
    height: IMAGE_HEIGHT,
    borderTopLeftRadius: theme.radius.large,
    borderTopRightRadius: theme.radius.large,
  },
  content: {
    padding: theme.spacing['4'],
    gap: theme.spacing['4'],
  },
  title: {
    color: theme.colors.WHITE,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyChallengeProps = {
  title: string;
  descriptiveTitle: string;
  imageUrl?: string | null;
};
