import React from 'react';
import type { ImageSourcePropType } from 'react-native';
import { ScrollView, StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../../hooks';
import { TagLabel } from '../../../TagLabel';
import { useModalBottomSpacingStyle } from '../../hooks';
import { Modal } from '../../Modal';

/**
 * Modals / Generic / Modal-Dialogue according to figma's specifications.
 * @see {@link https://www.figma.com/file/8rLhbY9578ml8urgV2NCpb/Garnish---React-Library?node-id=2427%3A369&t=n7oDOuprW5aBUUXw-0 Figma | Modals}
 */

// ─────────────────────────────────────────────────────────────────────────────

export const ModalDialogue = (props: ModalDialogueContentProps) => {
  const {
    children,
    animationType,
    size = 'medium',
    headline,
    subhead,
    body,
    tag,
    imageSrc,
    palette,
    withBottomPadding = false,
    onRequestClose,
    ...modalProps
  } = props;

  const { match } = useResponsive();

  // ─────────────────────────────────────────────────────────────────────

  const isHeadlineToContentWithoutSubhead = Boolean(headline && !subhead);
  const shouldRenderCloseBtn = onRequestClose !== undefined;

  // ─── Styles ──────────────────────────────────────────────────────────

  const modalDialogBottomSpacingStyle = useModalBottomSpacingStyle();
  const modalDialogStyles = [
    match([styles.modalDialogXS, styles.modalDialogSM]),
    withBottomPadding && modalDialogBottomSpacingStyle,
    shouldRenderCloseBtn
      ? match([styles.modalDialogWithCloseBtnXS, undefined])
      : undefined,
  ];
  const modalDialogInnerContentStyles = [
    styles.modalDialogInnerContent,
    shouldRenderCloseBtn
      ? match([undefined, styles.modalDialogInnerContentWithCloseBtnSM])
      : undefined,
  ];
  const modalCloseBtnStyles = match([
    styles.modalCloseBtnXS,
    styles.modalCloseBtnSM,
  ]);
  const modalImageStyles = match([styles.modalImageXS, styles.modalImageSM]);
  const modalDialogContentContainerStyles = match([
    undefined,
    styles.modalDialogContentContainerSM,
  ]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Modal
      animationType={animationType}
      size={size}
      palette={palette}
      onRequestClose={onRequestClose}
      {...modalProps}
    >
      {shouldRenderCloseBtn ? (
        <Modal.FloatingCloseBtn
          onPress={onRequestClose}
          palette={palette}
          style={modalCloseBtnStyles}
        />
      ) : null}

      <ScrollView
        style={modalDialogStyles}
        contentContainerStyle={modalDialogContentContainerStyles}
      >
        {imageSrc ? (
          <Modal.Image imageUrl={imageSrc} style={modalImageStyles} />
        ) : null}

        <View style={modalDialogInnerContentStyles}>
          {tag ? (
            <Modal.Row style={styles.modalTagContainer}>
              <TagLabel palette="kiwi" size={3}>
                {tag}
              </TagLabel>
            </Modal.Row>
          ) : null}

          {headline ? (
            <Modal.Headline
              palette={palette}
              style={
                isHeadlineToContentWithoutSubhead
                  ? styles.headlineToBodyTextGap
                  : undefined
              }
            >
              {headline}
            </Modal.Headline>
          ) : null}

          {subhead ? (
            <Modal.Subhead palette={palette}>{subhead}</Modal.Subhead>
          ) : null}

          {body ? (
            <Modal.BodyText palette={palette}>{body}</Modal.BodyText>
          ) : null}

          {children}
        </View>
      </ScrollView>
    </Modal>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const MODAL_CLOSE_BTN_CONTAINER_HEIGHT = theme.spacing['6'];
const MODAL_DIALOG_SPACING_VERTICAL = theme.spacing['6'];
const MODAL_DIALOG_SPACING_HORIZONTAL_XS = theme.spacing['4'];
const MODAL_DIALOG_SPACING_HORIZONTAL_SM = theme.spacing['6'];

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  modalDialogXS: {
    marginTop: MODAL_DIALOG_SPACING_VERTICAL,
  },
  modalDialogSM: {
    marginTop: MODAL_DIALOG_SPACING_VERTICAL,
  },
  modalDialogWithCloseBtnXS: {
    marginTop:
      MODAL_CLOSE_BTN_CONTAINER_HEIGHT +
      MODAL_DIALOG_SPACING_VERTICAL +
      theme.spacing['4'],
  },
  modalDialogContentContainerSM: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  modalCloseBtnXS: {
    margin: 0, // override default style
    marginTop: MODAL_DIALOG_SPACING_VERTICAL,
    marginRight: MODAL_DIALOG_SPACING_HORIZONTAL_XS,
  },
  modalCloseBtnSM: {
    margin: 0, // override default style
    marginTop: MODAL_DIALOG_SPACING_VERTICAL,
    marginRight: MODAL_DIALOG_SPACING_HORIZONTAL_SM,
  },
  modalDialogInnerContent: {
    flex: 1,
  },
  modalDialogInnerContentWithCloseBtnSM: {
    paddingTop: MODAL_CLOSE_BTN_CONTAINER_HEIGHT + theme.spacing['4'],
  },
  modalImageXS: {
    marginBottom: theme.spacing['6'],
  },
  modalImageSM: {
    marginLeft: MODAL_DIALOG_SPACING_HORIZONTAL_SM,
  },
  modalTagContainer: {
    alignItems: 'flex-start',
    marginBottom: theme.spacing['4'],
  },
  headlineToBodyTextGap: {
    marginBottom: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ModalDialogueContentProps = Readonly<{
  headline?: string;
  subhead?: string;
  body?: string;
  tag?: string;
  imageSrc?: ImageSourcePropType;

  /**
   * If the contents of the Modal do not contain a footer.
   * Then this prop will add the padding the footer would've added.
   */
  withBottomPadding?: boolean;
}> &
  React.ComponentProps<typeof Modal>;
