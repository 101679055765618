import { StyleSheet } from 'react-native';
import { COLORS, OPACITY } from '@garnish/constants';

import { ButtonPalette as Palette, ButtonSize as Size } from '../Button.types';

/**
 * The styles are organized by component type: Box, Text, Icon
 *
 * Each component's stylesheet has styles for the disabled-state-- and then
 * palette-specific groups, with definitions for both standard + hovered/pressed
 *
 * These styles are highly structured because there's mapping complexity which
 * relies on a standardized signature.
 */

const PRESSED = 'pressed';
const DISABLED = 'disabled';

// -----------------------------------------------------------------------------
// ---- BOX STYLES -------------------------------------------------------------
// -----------------------------------------------------------------------------

export const boxStyles = StyleSheet.create({
  // -----------------------------------------
  // ---- DISABLED ---------------------------
  [DISABLED]: { backgroundColor: COLORS.OPACITY.DARK_KALE.LIGHTEST },

  // -----------------------------------------
  // ---- PRIMARY ----------------------------
  [`${Palette.primary}`]: { backgroundColor: COLORS.KALE },
  [`${Palette.primary}:${PRESSED}`]: { backgroundColor: COLORS.SPINACH },

  // -----------------------------------------
  // ---- PRIMARY LIME -----------------------
  [`${Palette.primaryLime}`]: { backgroundColor: COLORS.KALE },
  [`${Palette.primaryLime}:${PRESSED}`]: { backgroundColor: COLORS.SPINACH },

  // -----------------------------------------
  // ---- PRIMARY OUTLINE --------------------
  [`${Palette.primaryOutline}`]: {
    backgroundColor: COLORS.OPACITY.KALE.LIGHTEST,
    borderColor: COLORS.KALE,
    borderWidth: 1,
  },
  [`${Palette.primaryOutline}:${PRESSED}`]: {
    backgroundColor: COLORS.OPACITY.KALE.LIGHTER,
    borderColor: COLORS.KALE,
    borderWidth: 1,
  },
  [`${Palette.primaryOutline}:${DISABLED}`]: {
    backgroundColor: COLORS.OPACITY.DARK_KALE.LIGHTEST,
    borderColor: COLORS.OPACITY.DARK_KALE.LIGHTEST,
    borderWidth: 1,
  },

  // -----------------------------------------
  // ---- SECONDARY --------------------------
  [`${Palette.secondary}`]: {
    backgroundColor: OPACITY.TRANSPARENT,
    borderColor: COLORS.DARK_KALE,
    borderWidth: 1,
  },
  [`${Palette.secondary}:${PRESSED}`]: {
    backgroundColor: OPACITY.KALE.LIGHTER,
    borderColor: COLORS.KALE,
    borderWidth: 1,
  },

  // -----------------------------------------
  // ---- LIGHT ------------------------------
  [`${Palette.light}`]: { backgroundColor: COLORS.WHITE },
  [`${Palette.light}:${PRESSED}`]: { backgroundColor: COLORS.WHITE },
  [`${Palette.light}:${DISABLED}`]: { backgroundColor: COLORS.WHITE },

  // -----------------------------------------

  // -----------------------------------------
  // ---- KALE -------------------------------
  [`${Palette.kale}`]: {
    backgroundColor: OPACITY.TRANSPARENT,
    borderColor: COLORS.KALE,
    borderWidth: 1,
  },
  [`${Palette.kale}:${PRESSED}`]: {
    backgroundColor: OPACITY.KALE.LIGHTER,
    borderColor: COLORS.KALE,
    borderWidth: 1,
  },

  // ---- KALE LIGHTEST ----------------------
  [`${Palette.kaleLightest}`]: {
    backgroundColor: COLORS.OPACITY.KALE.LIGHTEST,
  },
  [`${Palette.kaleLightest}:${PRESSED}`]: {
    backgroundColor: COLORS.OPACITY.KALE.LIGHTER,
  },

  // -----------------------------------------
  // ---- OUTLINE ----------------------
  [`${Palette.outline}`]: {
    borderColor: COLORS.BLACK,
    borderWidth: 1,
    backgroundColor: OPACITY.TRANSPARENT,
  },
  [`${Palette.outline}:${PRESSED}`]: {
    borderColor: COLORS.BLACK,
    borderWidth: 1,
    backgroundColor: OPACITY.SPINACH.LIGHTEST,
  },
  [`${Palette.outline}:${DISABLED}`]: {
    borderColor: COLORS.CHARCOAL,
    borderWidth: 1,
    backgroundColor: OPACITY.TRANSPARENT,
  },

  // -----------------------------------------
  // ---- MUTED ------------------------------
  [`${Palette.muted}`]: {
    backgroundColor: OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
    borderColor: COLORS.OPACITY.TRANSPARENT,
    borderWidth: 1,
  },
  [`${Palette.muted}:${PRESSED}`]: {
    backgroundColor: OPACITY.DARK_KALE.LIGHTEST,
    borderColor: COLORS.OPACITY.TRANSPARENT,
    borderWidth: 1,
  },

  // -----------------------------------------
  // ---- SPINACH ----------------------------
  [`${Palette.spinach}`]: {
    backgroundColor: COLORS.SPINACH,
    borderColor: COLORS.OPACITY.TRANSPARENT,
    borderWidth: 1,
  },
  [`${Palette.spinach}:${PRESSED}`]: {
    backgroundColor: OPACITY.SPINACH.DARKER,
    borderColor: COLORS.OPACITY.TRANSPARENT,
    borderWidth: 1,
  },

  // -----------------------------------------
  // ---- SPINACH MUTED ----------------------
  [`${Palette.spinachMuted}`]: {
    backgroundColor: OPACITY.SPINACH.NEAR_LIGHTEST,
    borderColor: COLORS.OPACITY.TRANSPARENT,
    borderWidth: 1,
  },
  [`${Palette.spinachMuted}:${PRESSED}`]: {
    backgroundColor: OPACITY.SPINACH.LIGHTER,
    borderColor: COLORS.OPACITY.TRANSPARENT,
    borderWidth: 1,
  },

  // -----------------------------------------
  // ---- CAUTION ----------------------------
  [`${Palette.caution}`]: {
    borderColor: COLORS.CAUTION,
    borderWidth: 1,
    backgroundColor: OPACITY.TRANSPARENT,
  },
  [`${Palette.caution}:${PRESSED}`]: {
    borderColor: COLORS.CAUTION,
    borderWidth: 1,
    backgroundColor: OPACITY.CAUTION.LIGHTER,
  },

  // -----------------------------------------
  // ---- LIME -------------------------------
  [`${Palette.lime}`]: { backgroundColor: COLORS.LIME },
  [`${Palette.lime}:${PRESSED}`]: { backgroundColor: COLORS.LIME },
  [`${Palette.lime}:${DISABLED}`]: { backgroundColor: COLORS.LIME },

  // -----------------------------------------
  // ---- LIME OUTLINE -----------------------
  [`${Palette.limeOutline}`]: {
    backgroundColor: COLORS.LIME,
    borderColor: COLORS.DARK_KALE,
    borderWidth: 1,
  },
  [`${Palette.limeOutline}:${PRESSED}`]: {
    backgroundColor: COLORS.LIME_HOVER,
    borderColor: COLORS.DARK_KALE,
    borderWidth: 1,
  },

  // -----------------------------------------
  // ---- LIME OUTLINE FULL ------------------
  [`${Palette.limeOutlineFull}`]: {
    backgroundColor: COLORS.KALE,
    borderColor: COLORS.LIME,
    borderWidth: 1,
  },
  [`${Palette.limeOutlineFull}:${PRESSED}`]: {
    backgroundColor: COLORS.LIME_HOVER,
    borderColor: COLORS.LIME,
    borderWidth: 1,
  },

  // -----------------------------------------
  // ---- LIME ON GREEN ----------------------
  [`${Palette.limeOnGreen}`]: {
    backgroundColor: COLORS.KALE,
  },
  [`${Palette.limeOnGreen}:${PRESSED}`]: {
    backgroundColor: COLORS.SPINACH,
  },

  // -----------------------------------------
  // ---- QUINOA -----------------------------
  [`${Palette.quinoa}`]: {
    borderColor: COLORS.DARK_KALE,
    borderWidth: 1,
    backgroundColor: COLORS.QUINOA,
  },
  [`${Palette.quinoa}:${PRESSED}`]: {
    borderColor: COLORS.DARK_KALE,
    borderWidth: 1,
    backgroundColor: COLORS.QUINOA_HOVER,
  },
  [`${Palette.quinoa}:${DISABLED}`]: {
    borderColor: COLORS.DARK_KALE,
    borderWidth: 1,
    backgroundColor: COLORS.QUINOA,
  },

  // -----------------------------------------
  // ---- OATMEAL ----------------------------
  [`${Palette.oatmeal}`]: {
    borderColor: COLORS.DARK_KALE,
    borderWidth: 1,
    backgroundColor: COLORS.OATMEAL,
  },
  [`${Palette.oatmeal}:${PRESSED}`]: {
    borderColor: COLORS.DARK_KALE,
    borderWidth: 1,
    backgroundColor: COLORS.OATMEAL_HOVER,
  },
  [`${Palette.oatmeal}:${DISABLED}`]: {
    borderColor: COLORS.DARK_KALE,
    borderWidth: 1,
    backgroundColor: COLORS.OATMEAL,
  },

  // -----------------------------------------
  // ---- BLACK ------------------------------
  [`${Palette.black}`]: {
    backgroundColor: COLORS.BLACK,
  },
  [`${Palette.black}:${PRESSED}`]: {
    backgroundColor: COLORS.CHARCOAL,
  },

  // -----------------------------------------
  // ---- WHITE ------------------------------
  [`${Palette.white}`]: {
    backgroundColor: OPACITY.TRANSPARENT,
    borderColor: COLORS.WHITE,
    borderWidth: 1,
  },
  [`${Palette.white}:${PRESSED}`]: {
    backgroundColor: OPACITY.KALE.LIGHTER,
    borderColor: COLORS.WHITE,
    borderWidth: 1,
  },
  [`${Palette.white}:${DISABLED}`]: {
    backgroundColor: OPACITY.DARK_KALE.LIGHTEST,
  },

  // -----------------------------------------
  // ---- SIZES ------------------------------
  [Size.xsmall]: { paddingHorizontal: 12, height: 24 },
  [Size.small]: { paddingHorizontal: 16, height: 32 },
  [Size.medium]: { paddingHorizontal: 24, height: 40 },
  [Size.large]: { paddingHorizontal: 24, height: 48 },
  [Size['large-wide']]: { paddingHorizontal: 64, height: 64 },
  [Size.xlarge]: { paddingHorizontal: 32, height: 100 },
});

// -----------------------------------------------------------------------------
// ---- TEXT AND ICON STYLES ---------------------------------------------------
// -----------------------------------------------------------------------------

const SHARED_TEXT_COLOR_STYLES = {
  // -----------------------------------------
  // ---- DISABLED ---------------------------
  disabled: { color: COLORS.OPACITY.DARK_KALE.DARK },

  // -----------------------------------------
  // ---- PRIMARY ----------------------------
  [`${Palette.primary}`]: { color: COLORS.WHITE },
  [`${Palette.primary}:${PRESSED}`]: { color: COLORS.WHITE },

  // -----------------------------------------
  // ---- PRIMARY LIME -----------------------
  [`${Palette.primaryLime}`]: { color: COLORS.LIME },
  [`${Palette.primaryLime}:${PRESSED}`]: { color: COLORS.LIME },

  // -----------------------------------------
  // ---- PRIMARY OUTLINE --------------------
  [`${Palette.primaryOutline}`]: { color: COLORS.KALE },
  [`${Palette.primaryOutline}:${PRESSED}`]: { color: COLORS.TEXT_COLOR },

  // -----------------------------------------
  // ---- SECONDARY --------------------------
  [`${Palette.secondary}`]: { color: COLORS.TEXT_COLOR },
  [`${Palette.secondary}:${PRESSED}`]: { color: COLORS.TEXT_COLOR },

  // -----------------------------------------
  // ---- LIGHT ------------------------------
  [`${Palette.light}`]: { color: COLORS.TEXT_COLOR },
  [`${Palette.light}:${PRESSED}`]: { color: COLORS.SPINACH },
  [`${Palette.light}:${DISABLED}`]: { color: COLORS.OPACITY.DARK_KALE.LIGHT },

  // ---- KALE LIGHTEST ----------------------
  [`${Palette.kaleLightest}`]: { color: COLORS.DARK_KALE },

  // -----------------------------------------
  // ---- MUTED ------------------------------
  [`${Palette.light}`]: { color: COLORS.TEXT_COLOR },
  [`${Palette.light}:${PRESSED}`]: { color: COLORS.KALE },
  [`${Palette.light}:${DISABLED}`]: { color: COLORS.OPACITY.DARK_KALE.LIGHT },

  // -----------------------------------------
  // ---- CAUTION ----------------------------
  [`${Palette.caution}`]: { color: COLORS.CAUTION },
  [`${Palette.caution}:${PRESSED}`]: { color: COLORS.CAUTION },

  // -----------------------------------------
  // ---- KALE -------------------------------
  [`${Palette.kale}`]: { color: COLORS.KALE },
  [`${Palette.kale}:${PRESSED}`]: { color: COLORS.KALE },

  // -----------------------------------------
  // ---- LIME -------------------------------
  [`${Palette.lime}`]: { color: COLORS.DARK_KALE },
  [`${Palette.lime}:${PRESSED}`]: { color: COLORS.DARK_KALE },

  // -----------------------------------------
  // ---- LIME OUTLINE -----------------------
  [`${Palette.limeOutline}`]: { color: COLORS.DARK_KALE },
  [`${Palette.limeOutline}:${PRESSED}`]: { color: COLORS.DARK_KALE },

  // -----------------------------------------
  // ---- LIME ON GREEN ----------------------
  [`${Palette.limeOnGreen}`]: { color: COLORS.LIME },
  [`${Palette.limeOnGreen}:${PRESSED}`]: { color: COLORS.LIME },

  // -----------------------------------------
  // ---- LIME OUTLINE FULL-------------------
  [`${Palette.limeOutlineFull}`]: { color: COLORS.LIME },
  [`${Palette.limeOutlineFull}:${PRESSED}`]: { color: COLORS.KALE },

  // -----------------------------------------
  // ---- QUINOA -----------------------------
  [`${Palette.quinoa}`]: { color: COLORS.TEXT_COLOR },
  [`${Palette.quinoa}:${PRESSED}`]: { color: COLORS.TEXT_COLOR },

  // -----------------------------------------
  // ---- SPINACH ----------------------------
  [`${Palette.spinach}`]: { color: COLORS.WHITE },
  [`${Palette.spinach}:${PRESSED}`]: { color: COLORS.WHITE },

  // -----------------------------------------
  // ---- SPINACH MUTED ----------------------
  [`${Palette.spinachMuted}`]: { color: COLORS.KALE },
  [`${Palette.spinachMuted}:${PRESSED}`]: { color: COLORS.KALE },

  // -----------------------------------------
  // ---- BLACK ------------------------------
  [`${Palette.black}`]: {
    color: COLORS.WHITE,
  },
  [`${Palette.black}:${PRESSED}`]: {
    color: COLORS.WHITE,
  },

  // -----------------------------------------
  // ---- WHITE ------------------------------
  [`${Palette.white}`]: {
    color: COLORS.WHITE,
  },
  [`${Palette.white}:${PRESSED}`]: {
    color: COLORS.WHITE,
  },
  [`${Palette.white}:${DISABLED}`]: {
    color: OPACITY.DARK_KALE.DARK,
  },
};

export const textStyles = StyleSheet.create({
  ...SHARED_TEXT_COLOR_STYLES,

  // -----------------------------------------
  // ---- SIZES ------------------------------
  [Size.xsmall]: { fontSize: 12 },
  [Size.small]: { fontSize: 14 },
  [Size.medium]: { fontSize: 14 },
  [Size.large]: { fontSize: 16 },
  [Size['large-wide']]: { fontSize: 18 },
  [Size.xlarge]: { fontSize: 24 },
});

export const iconStyles = StyleSheet.create({
  ...SHARED_TEXT_COLOR_STYLES,

  // -----------------------------------------
  // ---- SIZES ------------------------------
  [Size.xsmall]: { width: 16 },
  [Size.small]: { width: 16 },
  [Size.medium]: { width: 16 },
  [Size.large]: { width: 24 },
  [Size['large-wide']]: { width: 24 },
  [Size.xlarge]: { width: 32 },
});
