import React from 'react';

import type { TabItems } from '../../Tabs.types';
import { Tab } from '../Tab';
import { TabBarContainer } from '../TabBarContainer';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders the controlled "tablist" component along with the relevant "tab"
 * components to change between tab panels.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tablist_role ARIA: tablist role}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tab_role ARIA: tab role}
 */
export const TabBar = (props: TabBarProps) => {
  const { tabs, activeTabId, onChange } = props;

  return (
    <TabBarContainer>
      {tabs.map((tab) => {
        const {
          id,
          panelId,
          label,
          'aria-label': ariaLabel,
          isDisabled,
          isFluid,
        } = tab;

        return (
          <Tab
            key={id}
            id={id}
            panelId={panelId}
            label={label}
            isActive={activeTabId === id}
            aria-label={ariaLabel}
            isDisabled={isDisabled}
            isFluid={isFluid}
            onPress={onChange}
          />
        );
      })}
    </TabBarContainer>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type TabBarProps = Readonly<{
  tabs: TabItems;
  activeTabId: string;
  onChange: (value: string) => void;
}>;
