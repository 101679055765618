import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

// ─────────────────────────────────────────────────────────────────────────────

export const TabBarContainer = (props: PropsWithChildren) => {
  return (
    <View style={styles.container} accessibilityRole="tablist">
      {props.children}
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.GRAY,
    paddingHorizontal: theme.spacing['1'],
  },
});
