import React, { type PropsWithChildren } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import Animated, {
  useAnimatedRef,
  useScrollViewOffset,
} from 'react-native-reanimated';
import {
  AnimatedHeader,
  BodyText,
  CustomNavigationHeader,
  theme,
} from '@sg/garnish';

export const LoyaltyPointsHistoryContainer = (
  props: LoyaltyPointsHistoryContainerProps,
) => {
  const { children, onClose } = props;

  const { formatMessage } = useIntl();
  const animatedRef = useAnimatedRef<Animated.ScrollView>();
  const scrollOffset = useScrollViewOffset(animatedRef);

  return (
    <Animated.ScrollView
      ref={animatedRef}
      contentContainerStyle={styles.container}
      stickyHeaderIndices={[0]}
    >
      <CustomNavigationHeader.Container scrollOffsetSV={scrollOffset}>
        <AnimatedHeader.Content scrollOffset={scrollOffset}>
          <CustomNavigationHeader.Text textAlignment="center" numberOfLines={1}>
            <BodyText style={styles.headerText} sizeMatch={['16']}>
              <FormattedMessage {...messages.title} />
            </BodyText>
          </CustomNavigationHeader.Text>
        </AnimatedHeader.Content>

        <CustomNavigationHeader.Button.Right
          name="IconClose"
          accessibilityLabel={formatMessage(messages.close)}
          onPress={onClose}
        />
      </CustomNavigationHeader.Container>

      <View style={styles.content}>{children}</View>
    </Animated.ScrollView>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  title: {
    defaultMessage: 'Points history',
    description: 'Loyalty | Points History | Title',
  },
  close: {
    defaultMessage: 'Close',
    description: 'Loyalty | Points History | Close',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    paddingBottom: theme.spacing['10'],
    backgroundColor: theme.colors.CREAM,
  },
  content: {
    flex: 1,
    gap: theme.spacing['6'],
  },
  headerText: {
    textAlign: 'center',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyPointsHistoryContainerProps = PropsWithChildren & {
  onClose: () => void;
};
