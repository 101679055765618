/* eslint-disable functional/immutable-data */

import React, { type ComponentProps, forwardRef, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withTiming,
} from 'react-native-reanimated';

import {
  ANIMATED_DIALOG_ANIMATION_CONFIG,
  ANIMATED_DIALOG_ITEM_TRANSITION_DELAY,
} from './AnimatedDialog.constants';

export const AnimatedDialogScrollView = forwardRef<
  Animated.ScrollView,
  AnimatedDialogScrollViewProps
>((props, ref) => {
  const { children, style, ...rest } = props;
  const { opacitySV, animatedStyles } =
    useAnimatedDialogScrollViewStyles(style);

  // ─── Effects ──────────────────────────────────────────────────────────────
  // Fade in/out component on mount/unmount

  useEffect(() => {
    opacitySV.value = withDelay(
      ANIMATED_DIALOG_ITEM_TRANSITION_DELAY,
      withTiming(1, ANIMATED_DIALOG_ANIMATION_CONFIG),
    );

    return () => {
      opacitySV.value = withTiming(0, ANIMATED_DIALOG_ANIMATION_CONFIG);
    };
  }, [opacitySV]);

  // ──────────────────────────────────────────────────────────────────────────

  return (
    <Animated.ScrollView
      {...rest}
      ref={ref}
      automaticallyAdjustKeyboardInsets
      stickyHeaderIndices={[0]}
      style={animatedStyles}
    >
      {children}
    </Animated.ScrollView>
  );
});

export const AnimatedDialogScrollViewMounted = forwardRef<
  Animated.ScrollView,
  AnimatedDialogScrollViewMountedProps
>((props, ref) => {
  const { children, show, style, ...rest } = props;
  const { opacitySV, animatedStyles } =
    useAnimatedDialogScrollViewStyles(style);

  // ─── Effects ──────────────────────────────────────────────────────────────
  // Fade in/out component on {show} prop value change.

  useEffect(() => {
    opacitySV.value = withDelay(
      ANIMATED_DIALOG_ITEM_TRANSITION_DELAY,
      withTiming(show ? 1 : 0, ANIMATED_DIALOG_ANIMATION_CONFIG),
    );
  }, [opacitySV, show]);

  // ──────────────────────────────────────────────────────────────────────────

  return (
    <Animated.ScrollView
      {...rest}
      ref={ref}
      pointerEvents={show || show === undefined ? 'auto' : 'none'}
      automaticallyAdjustKeyboardInsets
      stickyHeaderIndices={[0]}
      style={animatedStyles}
    >
      {children}
    </Animated.ScrollView>
  );
});

// ─── Helpers ────────────────────────────────────────────────────────────────

const useAnimatedDialogScrollViewStyles = (
  style: AnimatedDialogScrollViewProps['style'],
) => {
  const opacitySV = useSharedValue(0);

  const containerAnimatedStyles = useAnimatedStyle(() => {
    return {
      opacity: opacitySV.value,
    };
  }, [opacitySV]);

  const animatedStyles = [styles.container, containerAnimatedStyles, style];

  return { opacitySV, animatedStyles };
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    opacity: 0,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type AnimatedDialogScrollViewProps = Omit<
  ComponentProps<typeof Animated.ScrollView>,
  'ref'
>;

type AnimatedDialogScrollViewMountedProps = AnimatedDialogScrollViewProps & {
  show?: boolean;
};
