import React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';

import type {
  CollapsibleChildrenProps,
  CollapsibleSummaryProps,
} from '../Collapsible.types';
import { CollapseIndicatorIcon } from './CollapseIndicatorIcon';

const testID = 'sg-collapsible-summary';

type PropsType = CollapsibleSummaryProps & CollapsibleChildrenProps;

export const Summary = (props: CollapsibleSummaryProps) => {
  const { children, collapsed, onCollapse, options } = props as PropsType;
  const { iconName, hideIcon } = options ?? {};
  const label = `${testID}-${collapsed ? 'collapsed' : 'expanded'}`;

  return (
    <Pressable
      testID={testID}
      accessibilityRole="button"
      onPress={onCollapse}
      accessibilityLabel={`${label}-label`}
      accessibilityHint={`${label}-hint`}
      style={styles.details}
    >
      <View style={styles.content}>{children}</View>

      {hideIcon ? null : (
        <CollapseIndicatorIcon expanded={!collapsed} iconName={iconName} />
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  details: {
    flexDirection: 'row',
  },
  content: {
    flex: 1,
  },
});
