import React from 'react';
import { Platform, StyleSheet } from 'react-native';
import type { AddressType } from '@sg/garnish';
import {
  Button,
  DisplayText,
  HStack,
  IconLink,
  Modal,
  ModalCloseBtn,
  theme,
  useResponsive,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { useNavigationActions } from '../../hooks';

export const ReorderModalHeader = ({
  orderType,
  onClose,
}: Readonly<{
  orderType: AddressType;
  onClose: () => void;
}>) => {
  const { t } = useLocalizationContext();

  return (
    <>
      <ModalCloseBtn
        onPress={onClose}
        accessibilityHint=""
        accessibilityLabel={t('general.close')}
        accessibilityRole="imagebutton"
      />
      <ReorderTitle orderType={orderType} />
    </>
  );
};

export const ReorderTitle = ({
  orderType,
}: Readonly<{
  orderType: AddressType;
}>) => {
  const { t } = useLocalizationContext();
  const { currentBreakpoint } = useResponsive();

  return (
    <DisplayText
      size={4}
      style={[styles.headline, currentBreakpoint.isXS && styles.mobileHeadline]}
    >
      {t('reorder.confirm-location', { channel: orderType })}
    </DisplayText>
  );
};

export const ReorderModalFooter = ({
  isLoading,
  onContinue,
  onMoreLocations,
}: FooterProps) => {
  const { t } = useLocalizationContext();
  const { currentBreakpoint } = useResponsive();

  return (
    <Modal.Footer style={styles.footer} withoutTopBorder>
      <HStack
        style={currentBreakpoint.isXS ? styles.mobileStack : undefined}
        itemsPerRow={2}
        gap={theme.spacing['4']}
      >
        <Button
          size="large"
          palette="secondary"
          onPress={onMoreLocations}
          testID="reorder.more-locations"
          accessibilityLabel={t('reorder.more-locations')}
          accessibilityHint={t('reorder.more-locations')}
          accessibilityRole="button"
        >
          {t('reorder.more-locations')}
        </Button>
        <Button
          size="large"
          onPress={onContinue}
          isLoading={isLoading}
          testID="reorder.continue-to-bag"
          accessibilityLabel={t('reorder.continue-to-bag')}
          accessibilityHint={t('reorder.continue-to-bag')}
          accessibilityRole="button"
        >
          {t('reorder.continue-to-bag')}
        </Button>
      </HStack>
    </Modal.Footer>
  );
};

export const useReorderLeftIcon = () => {
  const { t } = useLocalizationContext();
  const { onBack } = useNavigationActions();

  return (
    <IconLink
      testID="location.search-bar.back-button"
      name="IconArrowLeft"
      style={Platform.OS === 'web' ? styles.icon : undefined}
      accessibilityLabel={t('general.back')}
      accessibilityHint={t('home.navigation')}
      onPress={onBack}
    />
  );
};

export const useReorderRightIcon = () => {
  const { t } = useLocalizationContext();
  const { onClose } = useNavigationActions();

  return (
    <IconLink
      testID="location.search-bar.close-button"
      name="IconClose"
      style={Platform.OS === 'web' ? styles.icon : undefined}
      accessibilityLabel={t('general.close')}
      accessibilityHint={t('home.navigation')}
      onPress={onClose}
    />
  );
};

export type FooterProps = Readonly<{
  isLoading?: boolean;
  onContinue: () => void;
  onMoreLocations: () => void;
}>;

const styles = StyleSheet.create({
  mobileHeadline: {
    fontSize: 18,
    paddingHorizontal: theme.spacing['4'],
    paddingBottom: 0,
    paddingTop: 0,
  },
  headline: {
    paddingHorizontal: theme.spacing['6'],
    paddingBottom: theme.spacing['2'],
  },
  footer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingHorizontal: theme.spacing['4'],
    marginTop: theme.spacing['4'],
  },
  mobileStack: {
    borderTopWidth: 1,
    borderTopColor: theme.colors.LIGHT,
    paddingTop: theme.spacing['4'],
  },
  icon: {
    marginHorizontal: theme.spacing['4'],
  },
});
