import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet } from 'react-native';
import { BodyText } from '@sg/garnish';

export const BagAnimatedDialogTitle = (props: BagAnimatedDialogTitleProps) => {
  const {
    isShowingCheckoutLedger,
    isChangingTime,
    isChangingDeliveryPreferences,
    isChangingPaymentMethod,
    isShowingPaymentMethodForm,
    isShowingGiftCardForm,
    isShowingLoyaltyInfo,
  } = props;

  return (
    <BodyText style={styles.title} sizeMatch={TEXT_SIZE}>
      {isShowingCheckoutLedger ? (
        <FormattedMessage {...messages.checkout} />
      ) : null}

      {isChangingTime ? <FormattedMessage {...messages.chooseTime} /> : null}

      {isChangingDeliveryPreferences ? (
        <FormattedMessage {...messages.editDeliveryPreferences} />
      ) : null}

      {isChangingPaymentMethod ? (
        <FormattedMessage {...messages.selectPaymentOption} />
      ) : null}

      {isShowingPaymentMethodForm ? (
        <FormattedMessage {...messages.addCard} />
      ) : null}

      {isShowingGiftCardForm ? (
        <FormattedMessage {...messages.redeemGiftCard} />
      ) : null}

      {isShowingLoyaltyInfo ? (
        <FormattedMessage {...messages.loyaltyInfo} />
      ) : null}
    </BodyText>
  );
};

// ─── Constants ──────────────────────────────────────────────────────────────

const TEXT_SIZE = ['22' as const];

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  title: {
    textAlign: 'center',
  },
});

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  addCard: {
    defaultMessage: 'Add card',
    description: 'Checkout | Add card | Title',
  },
  checkout: {
    defaultMessage: 'Checkout',
    description: 'Checkout | Checkout | Title',
  },
  chooseTime: {
    defaultMessage: 'Time',
    description: 'Checkout | Choose a time | Title',
  },
  editDeliveryPreferences: {
    defaultMessage: 'Delivery',
    description: 'Checkout | Edit delivery preferences | Title',
  },
  selectPaymentOption: {
    defaultMessage: 'Payments',
    description: 'Checkout | Select payment option | Title',
  },
  redeemGiftCard: {
    defaultMessage: 'Redeem',
    description: 'Checkout | Redeem gift card | Title',
  },
  loyaltyInfo: {
    defaultMessage: 'Loyalty',
    description: 'Checkout | Loyalty info | Title',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagAnimatedDialogTitleProps = {
  isShowingCheckoutLedger: boolean;
  isChangingTime: boolean;
  isChangingDeliveryPreferences: boolean;
  isChangingPaymentMethod: boolean;
  isShowingPaymentMethodForm: boolean;
  isShowingGiftCardForm: boolean;
  isShowingLoyaltyInfo: boolean;
};
