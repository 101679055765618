import React, { useMemo } from 'react';
import { theme } from '@garnish/constants';
import { Image as ExpoImage } from 'expo-image';

import { type ImageProps } from './Image.types';
import { getImageForCurrentScreen, withHttpsProtocol } from './Image.utils';

// ─────────────────────────────────────────────────────────────────────────────

export const Image = (props: ImageProps) => {
  const { source, ...rest } = props;

  // ─────────────────────────────────────────────────────────────────────

  const formattedSource = useMemo(
    () => getImageForCurrentScreen(withHttpsProtocol(source)),
    [source],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <ExpoImage
      source={formattedSource}
      transition={theme.transitions.image}
      {...rest}
    />
  );
};
