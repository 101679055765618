import type { ComponentProps } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import type { EdgeInsets } from 'react-native-safe-area-context';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useStyle } from 'react-native-style-utilities';
import { useIsFocused } from '@react-navigation/native';
import { Nav } from '@expo/html-elements';
import {
  Container,
  theme,
  useInaccessibleElement,
  useResponsive,
} from '@sg/garnish';

import { useChallengesAndRewards } from '@order/ChallengesAndRewards';
import { useCart } from '@order/hooks';
import { useNavigateToLastInteractedStore } from '@order/LastInteractedStore';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';

import {
  isRouteInMainTab,
  useNavigateBack,
  useNavigateToMainTabs,
} from '../../navigation';
import type {
  MainTabName,
  ModalStackScreenName,
} from '../../navigation/AppNavigation.props';
import { DesktopHeaderItem } from './components';
import {
  DESKTOP_HEADER_HEIGHT,
  DESKTOP_HEADER_ITEM_SPACING,
} from './constants';
import { DesktopHeaderBagItem } from './DesktopHeader.items.bag';
import { DesktopHeaderLogoItem } from './DesktopHeader.items.logo';
import { useMenuTabName } from './Header.hooks';
import type { HeaderContentProps, HeaderProps } from './Header.props';

// ─────────────────────────────────────────────────────────────────────────────

export const DesktopHeader = ({
  testID = 'nav-bar',
  navigation,
  route,
  options,
  dividerHidden,
}: HeaderProps) => {
  const { t } = useLocalizationContext();
  const { minWidth } = useResponsive();
  const { track } = useTelemetry();

  // ─── Flags ───────────────────────────────────────────────────────────

  const isInAppGiftCardEnabled = useFeatureFlag(
    'CELS-1449-in-app-gift-cards-enabled',
  );

  // ─────────────────────────────────────────────────────────────────────

  const itemTestID = React.useCallback(
    (suffix: string) => `${testID}.${suffix}`,
    [testID],
  );

  const safeAreaInsets = useSafeAreaInsets();
  const { hasUnseenChallenges } = useChallengesAndRewards();

  const navigateToMainTabs = useNavigateToMainTabs(navigation);

  const { name: routeName } = route;

  // ——— BAG ———
  const { lineItemsCount } = useCart();

  const bagRouteName: ModalStackScreenName<'Bag'> = 'Bag';
  const isBagOpen = routeName === bagRouteName;

  // ——— HANDLERS ———
  const handlePressHome = React.useCallback(() => {
    void navigateToMainTabs('HomeTab');
  }, [navigateToMainTabs]);

  const handlePressMenu = useNavigateToLastInteractedStore();

  const handlePressLoyalty = React.useCallback(() => {
    void navigateToMainTabs('LoyaltyTab');
  }, [navigateToMainTabs]);

  const handlePressGiftCard = React.useCallback(() => {
    track('gift.jump_nav');

    navigation.navigate('MainTabs', {
      screen: 'GiftCardsTab',
      params: {
        screen: 'GiftCards',
      },
    });
  }, [navigation, track]);

  const handlePressAccount = React.useCallback(() => {
    void navigateToMainTabs('AccountTab');
  }, [navigateToMainTabs]);

  const navigateBack = useNavigateBack();
  const handlePressBag = React.useCallback(() => {
    if (isBagOpen) {
      navigateBack();
    } else {
      navigation.navigate('Modal', {
        screen: 'Bag',
        params: { referrer: routeName },
      });
    }
  }, [navigation, isBagOpen, routeName, navigateBack]);

  // ——— FOCUSED TAB ———

  const isTabActive = React.useCallback(
    (tabName: MainTabName) => isRouteInMainTab(tabName, routeName),
    [routeName],
  );

  const {
    headerLeft: headerLeftFn,
    headerRight: headerRightFn,
    headerBackTitle,
    headerTintColor,
  } = options;

  const canGoBack = navigation.canGoBack();

  // ––– HEADER LEFT & RIGHT

  const headerLeft = React.useMemo(() => {
    if (!headerLeftFn) return undefined;

    return headerLeftFn({
      label: headerBackTitle,
      tintColor: headerTintColor,
      canGoBack,
    });
  }, [headerLeftFn, headerBackTitle, headerTintColor, canGoBack]);

  const headerRight = React.useMemo(() => {
    if (!headerRightFn) return undefined;

    return headerRightFn({
      tintColor: headerTintColor,
      canGoBack,
    });
  }, [headerRightFn, headerTintColor, canGoBack]);

  // ─── Left side items ─────────────────────────────────────────────────

  // ─── Loyalty ─────────────────────────────────

  const sweetpassItem: HeaderItem = {
    testID: itemTestID('sweetpass'),
    key: 'sweetpass',
    title: t('nav-bar.sweetpass.title'),
    hasBadge: hasUnseenChallenges,
    isActive: isTabActive('LoyaltyTab'),
    onPress: handlePressLoyalty,
  };
  const loyaltyTabItem: HeaderItem = {
    testID: itemTestID('loyalty'),
    key: 'loyalty',
    title: t('nav-bar.loyalty.title'),
    hasBadge: hasUnseenChallenges,
    isActive: isTabActive('LoyaltyTab'),
    onPress: handlePressLoyalty,
  };

  const loyaltyItem = isInAppGiftCardEnabled ? loyaltyTabItem : sweetpassItem;

  // ─── Gift Cards ──────────────────────────────

  const giftCardsTabItem: HeaderItem = {
    testID: itemTestID('gift'),
    key: 'gift',
    title: t('nav-bar.gift-cards.title'),
    isActive: isTabActive('GiftCardsTab'),
    onPress: handlePressGiftCard,
  };

  const giftCardsItem = isInAppGiftCardEnabled ? giftCardsTabItem : undefined;

  // ─── Menu ────────────────────────────────────

  const menuTitle = useMenuTabName();
  const menuItemStyle = minWidth.isMD ? styles.menuItemMD : {};
  const menuItem: HeaderItem = {
    testID: itemTestID('menu'),
    key: 'menu',
    title: menuTitle,
    isActive: isTabActive('MenuTab'),
    style: menuItemStyle,
    onPress: handlePressMenu,
  };

  // ─────────────────────────────────────────────

  const leftSideItems = [loyaltyItem, giftCardsItem, menuItem].filter(
    Boolean,
  ) as HeaderItem[];

  // ─── Right side items ────────────────────────────────────────────────

  const accountItem: HeaderItem = {
    testID: itemTestID('account'),
    key: 'menu',
    title: isInAppGiftCardEnabled ? undefined : t('nav-bar.account.title'),
    accessibilityLabel: t('nav-bar.account.title'),
    iconName: isInAppGiftCardEnabled ? 'IconProfileStroke' : undefined,
    isActive: isTabActive('AccountTab'),
    onPress: handlePressAccount,
  };

  // ─────────────────────────────────────────────

  const rightSideItems: HeaderItem[] = [accountItem].filter(Boolean);

  // ─────────────────────────────────────────────────────────────────────

  // ––– HOME ITEM –––

  const homeItem: HomeItemProps = {
    testID: itemTestID('home'),
    accessibilityLabel: t('nav-bar.home.accessibility-label'),
    onPress: handlePressHome,
  };

  // ––– BAG ITEM –––

  const bagItem: BagItemProps | undefined =
    lineItemsCount > 0 && routeName !== 'Checkout'
      ? {
          testID: itemTestID('bag'),
          accessibilityLabel: isBagOpen
            ? t('nav-bar.bag.open.accessibility-label')
            : t('nav-bar.bag.closed.accessibility-label'),
          isOpen: isBagOpen,
          count: lineItemsCount,
          onPress: handlePressBag,
        }
      : undefined;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <DesktopHeaderContent
      testID={testID}
      accessibilityLabel={t('nav-bar.a11y.label', { routeName })}
      height={DESKTOP_HEADER_HEIGHT}
      dividerHidden={dividerHidden}
      insets={{
        top: 0,
        bottom: 0,
        left: safeAreaInsets.left,
        right: safeAreaInsets.right,
      }}
      headerLeft={headerLeft}
      headerRight={headerRight}
      leftSideItems={leftSideItems}
      rightSideItems={rightSideItems}
      homeItem={homeItem}
      bagItem={bagItem}
    />
  );
};

// ─────────────────────────────────────────────────────────────────────────────

export const DesktopHeaderContent = ({
  height,
  insets,
  homeItem,
  leftSideItems,
  rightSideItems,
  bagItem,
  headerLeft,
  headerRight,
  dividerHidden,
  testID,
  style,
  ...props
}: DesktopHeaderContentProps) => {
  const isVisible = useIsFocused();
  const a11yProps = useInaccessibleElement(!isVisible);

  const navStyles = [styles.nav, !dividerHidden && styles.navWithBorder, style];
  const contentStyle = useStyle(
    () => ({
      height: height + insets.top + insets.bottom,
      paddingTop: insets.top,
      paddingBottom: insets.bottom,
      paddingLeft: insets.left,
      paddingRight: insets.right,
    }),
    [height, insets],
  );

  return (
    <Nav style={navStyles} {...a11yProps} testID={testID} {...props}>
      <Container>
        <View style={[styles.content, contentStyle]}>
          <View style={[styles.sideItems, styles.leftItems]}>
            <>
              {leftSideItems.map(({ key, ...item }) => (
                <DesktopHeaderItem key={key} {...item} />
              ))}
              {headerLeft}
            </>
          </View>

          <View style={styles.centerItems}>
            <DesktopHeaderLogoItem {...homeItem} />
          </View>

          <View style={[styles.sideItems, styles.rightItems]}>
            <>
              {rightSideItems.map(({ key, ...item }) => (
                <DesktopHeaderItem key={key} {...item} />
              ))}

              {bagItem ? <DesktopHeaderBagItem {...bagItem} /> : null}

              {headerRight}
            </>
          </View>
        </View>
      </Container>
    </Nav>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  nav: {
    height: DESKTOP_HEADER_HEIGHT,
    backgroundColor: theme.colors.APP_BACKGROUND,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.OPACITY.TRANSPARENT,
  },
  navWithBorder: {
    borderBottomColor: theme.colors.GREEN_1,
  },
  content: {
    flexDirection: 'row',
  },
  centerItems: {
    justifyContent: 'center',
  },
  sideItems: {
    flex: 1,
    alignItems: 'center',
  },
  leftItems: {
    flexDirection: 'row',
    paddingRight: theme.spacing['6'],
    marginLeft: -DESKTOP_HEADER_ITEM_SPACING,
  },
  rightItems: {
    flexDirection: 'row-reverse',
    paddingLeft: theme.spacing['6'],
    marginRight: -DESKTOP_HEADER_ITEM_SPACING,
  },
  menuItemMD: {
    maxWidth: '45%',
    flexShrink: 1,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type HeaderItem = Readonly<{
  key: string;
}> &
  ComponentProps<typeof DesktopHeaderItem>;

type HomeItemProps = ComponentProps<typeof DesktopHeaderLogoItem>;

type BagItemProps = ComponentProps<typeof DesktopHeaderBagItem>;

type DesktopHeaderContentProps = Readonly<{
  height: number;
  insets: EdgeInsets;
  headerLeft?: React.ReactNode;
  headerRight?: React.ReactNode;
  leftSideItems: HeaderItem[];
  rightSideItems: HeaderItem[];
  homeItem: HomeItemProps;
  bagItem?: BagItemProps;
}> &
  HeaderContentProps &
  Omit<ComponentProps<typeof Nav>, 'children'>;
