import React from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { BodyText } from '../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const ScanCardText = (props: ScanCardBaseProps) => {
  const { children } = props;

  return (
    <BodyText sizeMatch={['18']} style={styles.container}>
      {children}
    </BodyText>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    textAlign: 'center',
    marginBottom: theme.spacing['10'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ScanCardBaseProps = Readonly<{
  children: string;
}>;
