import {
  AnimatedHeaderBackButton,
  AnimatedHeaderButton,
  AnimatedHeaderCloseButton,
} from './AnimatedHeader.Button';
import {
  AnimatedHeaderContainer,
  AnimatedHeaderFloatingContainer,
} from './AnimatedHeader.Container';
import { AnimatedHeaderContent } from './AnimatedHeader.Content';
import { AnimatedHeaderIcon } from './AnimatedHeader.Icon';

/**
 * This is a header that, when used together with an animated scrollview, changes its opacity based on the scroll amount.
 */
export const AnimatedHeader = {
  Container: AnimatedHeaderContainer,
  FloatingContainer: AnimatedHeaderFloatingContainer,
  Content: AnimatedHeaderContent,
  Button: AnimatedHeaderButton,
  BackButton: AnimatedHeaderBackButton,
  CloseButton: AnimatedHeaderCloseButton,
  Icon: AnimatedHeaderIcon,
};
