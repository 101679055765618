import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { QRCodeGenerator } from '../../../QRCode/QRCodeGenerator';

// ─────────────────────────────────────────────────────────────────────────────

export const ScanCardQrCode = (props: ScanCardBaseProps) => {
  const { value } = props;

  return (
    <View style={styles.container}>
      <QRCodeGenerator value={value} backgroundColor={QR_CODE_BG_COLOR} />
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const QR_CODE_CONTAINER_SIZE = 180;
const QR_CODE_BG_COLOR = theme.colors.OATMEAL;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    width: QR_CODE_CONTAINER_SIZE,
    height: QR_CODE_CONTAINER_SIZE,
    borderRadius: QR_CODE_CONTAINER_SIZE,
    backgroundColor: QR_CODE_BG_COLOR,
    marginTop: 'auto',
    padding: theme.spacing['10'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ScanCardBaseProps = Readonly<{
  value: string;
}>;
