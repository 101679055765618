import React, { type PropsWithChildren, useCallback, useMemo } from 'react';
import { StyleSheet, View, type ViewStyle } from 'react-native';
import { type LayoutChangeEvent } from 'react-native/Libraries/Types/CoreEventTypes';
import Animated, {
  interpolate,
  type SharedValue,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { theme } from '@garnish/constants';
import { LinearGradient } from 'expo-linear-gradient';

import {
  CUSTOM_NAVIGATION_HEADER_BUTTON_SIZE,
  CUSTOM_NAVIGATION_HEADER_CONTENT_HORIZONTAL_PADDING,
  CUSTOM_NAVIGATION_HEADER_HEIGHT,
  CUSTOM_NAVIGATION_HEADER_HORIZONTAL_PADDING,
} from '../../CustomNavigationHeader.constants';

// ─────────────────────────────────────────────────────────────────────────────

export const CustomNavigationHeaderContainer = (
  props: CustomNavigationHeaderContainerProps,
) => {
  const {
    children,
    safeAreaEdges = ['top'],
    palette = 'default',
    scrollOffsetSV,
    gradientColors = GRADIENT_DEFAULT_COLORS,
    testID,
  } = props;

  const safeAreaInsets = useSafeAreaInsets();

  // ─── Animation Helpers ───────────────────────────────────────────────

  const containerHeightSV = useSharedValue(0);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const trackLayoutChanges = useCallback(
    (event: LayoutChangeEvent) => {
      // eslint-disable-next-line functional/immutable-data
      containerHeightSV.value = event.nativeEvent.layout.height;
    },
    [containerHeightSV],
  );

  // ─── Styles ──────────────────────────────────────────────────────────

  const outerContainerPaletteStyles = paletteStyles[palette];
  const outerContainerDynamicStyles = useMemo<ViewStyle | undefined>(() => {
    if (!safeAreaEdges || safeAreaEdges.length === 0) return;

    const edges = new Set(safeAreaEdges);
    const { top, right, left } = safeAreaInsets;

    return {
      paddingTop: edges.has('top') ? Math.min(top, SAFE_AREA_MAX) : 0,
      paddingRight: edges.has('right') ? Math.min(right, SAFE_AREA_MAX) : 0,
      paddingLeft: edges.has('left') ? Math.min(left, SAFE_AREA_MAX) : 0,
    };
  }, [safeAreaEdges, safeAreaInsets]);

  const outerContainerStyles = [
    styles.outerContainer,
    outerContainerPaletteStyles,
    outerContainerDynamicStyles,
  ];

  const gradientContainerAnimatedStyles = useAnimatedStyle(() => {
    return {
      opacity: scrollOffsetSV
        ? interpolate(
            scrollOffsetSV.value,
            [0, containerHeightSV.value],
            [0, 1],
          )
        : 0,
    };
  }, [scrollOffsetSV, containerHeightSV]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View
      testID={testID}
      onLayout={trackLayoutChanges}
      style={outerContainerStyles}
    >
      <View style={styles.innerContainer}>{children}</View>

      <Animated.View style={gradientContainerAnimatedStyles}>
        <LinearGradient
          locations={[0, 1]}
          pointerEvents="none"
          colors={gradientColors}
          style={styles.gradient}
        />
      </Animated.View>
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const SAFE_AREA_MAX = theme.spacing['12'];

const GRADIENT_HEIGHT = 25;
const GRADIENT_DEFAULT_COLORS = [
  theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
  theme.colors.OPACITY.DARK_KALE.TRANSPARENT,
];

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  outerContainer: {
    zIndex: 1,
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
  innerContainer: {
    flexDirection: 'row',
    minHeight: CUSTOM_NAVIGATION_HEADER_HEIGHT,
    gap: theme.spacing['2'],
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: theme.spacing['2'],
    paddingHorizontal:
      CUSTOM_NAVIGATION_HEADER_BUTTON_SIZE +
      CUSTOM_NAVIGATION_HEADER_HORIZONTAL_PADDING +
      CUSTOM_NAVIGATION_HEADER_CONTENT_HORIZONTAL_PADDING,
  },
  gradient: {
    height: GRADIENT_HEIGHT,
    position: 'absolute',
    right: 0,
    bottom: -(GRADIENT_HEIGHT - 1), // to prevent 1px space that can occur on some browsers
    left: 0,
  },
});

const paletteStyles: Record<CustomNavigationHeaderContainerPalette, ViewStyle> =
  {
    default: {
      backgroundColor: theme.colors.APP_BACKGROUND,
    },
    oatmeal: {
      backgroundColor: theme.colors.OATMEAL,
    },
    cream: {
      backgroundColor: theme.colors.CREAM,
    },
  };

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomNavigationHeaderContainerProps = PropsWithChildren<{
  safeAreaEdges?: readonly ('top' | 'right' | 'left')[] | null;
  testID?: string;

  /**
   * An optional shared value of the associated scroll view offset, which can be
   * used for animation.
   */
  scrollOffsetSV?: SharedValue<number>;
  gradientColors?: string[];
  palette?: CustomNavigationHeaderContainerPalette;
}>;

type CustomNavigationHeaderContainerPalette = 'default' | 'oatmeal' | 'cream';
