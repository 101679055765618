import React, { type PropsWithChildren, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import Animated, {
  interpolateColor,
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
} from 'react-native-reanimated';
import { theme } from '@garnish/constants';

/**
 * Dots indicator, rendering one dot per item, with the active item being fully colored.
 */
export const DotsIndicator = (props: DotsIndicatorProps) => {
  const { active, length } = props;
  const dots = useMemo(() => [...Array.from({ length }).keys()], [length]);

  return (
    <DotsContainer>
      <AnimatedDotRail active={active}>
        {dots.map((dot) => (
          <AnimatedDot key={dot} dot={dot} active={active} />
        ))}
      </AnimatedDotRail>
    </DotsContainer>
  );
};

/**
 * Container for the dots, with enough width and height to ensure it will have enough space.
 * Items are shifted 50% to the right to ensure active dot is in the middle.
 */
const DotsContainer = (props: PropsWithChildren) => {
  return <View style={styles.container}>{props.children}</View>;
};

/**
 * This rail moves to the left as the active dot moves to the right.
 */
const AnimatedDotRail = (props: AnimatedDotRailProps) => {
  const { active, children } = props;

  const dotPosition = useDerivedValue(
    () =>
      withTiming(decideRailPosition(active), {
        duration: theme.transitions.base,
      }),
    [active],
  );

  const positionStyle = useAnimatedStyle(
    () => ({ transform: [{ translateX: dotPosition.value }] }),
    [dotPosition],
  );

  return (
    <Animated.View style={[styles.animatedRail, positionStyle]}>
      {children}
    </Animated.View>
  );
};

/**
 * This dot changes size and color depending on which one is active.
 */
const AnimatedDot = (props: AnimatedDotProps) => {
  const { active, dot } = props;

  const dotScale = useDerivedValue(
    () =>
      withTiming(decideDotScale(active, dot), {
        duration: theme.transitions.base,
      }),
    [active, dot],
  );

  const animatedDotStyle = useAnimatedStyle(() => {
    const scale = dotScale.value;
    const range = [0, 1];

    return {
      transform: [{ scale }],
      backgroundColor: interpolateColor(scale, range, [
        theme.colors.OPACITY.KALE.LIGHTER,
        theme.colors.KALE,
      ]),
    };
  }, [active, dot]);

  return (
    <View accessibilityLabel="Indicator" style={styles.dotWrapper}>
      <Animated.View style={[styles.dot, animatedDotStyle]} />
    </View>
  );
};

/**
 * The rail moves to the left as the active index increases, in accordance to the dot size.
 */
const decideRailPosition = (active: number) => {
  return -active * DOT_SIZE_WITH_GAP;
};

/**
 * The farther the dot is from the active dot, the smaller its size.
 */
const decideDotScale = (active: number, dot: number) => {
  if (dot === active) return 1;

  const distanceFromActive = Math.abs(active - dot);

  if (distanceFromActive === 1) return 0.5;
  if (distanceFromActive === 2) return 0.25;
  return 0;
};

// ─── Constants ──────────────────────────────────────────────────────────────

const MAX_NUMBER_OF_VISIBLE_DOTS = 5;
const DOT_SIZE = 8;
const GAP_SIZE = theme.spacing['2'];
const DOT_SIZE_WITH_GAP = DOT_SIZE + GAP_SIZE;

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    width: DOT_SIZE_WITH_GAP * MAX_NUMBER_OF_VISIBLE_DOTS,
    height: DOT_SIZE,
    transform: [{ translateX: '50%' }],
  },
  animatedRail: {
    position: 'absolute',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    gap: GAP_SIZE,
  },
  dotWrapper: {
    width: DOT_SIZE,
    height: DOT_SIZE,
    alignItems: 'center',
    justifyContent: 'center',
  },
  dot: {
    transform: [{ scale: 1 }],
    width: DOT_SIZE,
    height: DOT_SIZE,
    borderRadius: DOT_SIZE,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type DotsIndicatorProps = {
  active: number;
  length: number;
};

type AnimatedDotRailProps = {
  children: PropsWithChildren['children'];
  active: number;
};

type AnimatedDotProps = {
  active: number;
  dot: number;
};
