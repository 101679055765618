import React, { useLayoutEffect } from 'react';
import Animated, {
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { COLORS } from '@garnish/constants';

import { useResponsive } from '../../../hooks';
import { Icon } from '../../Icon';

export const CollapseIndicatorIcon = (props: IconProps) => {
  const { expanded, iconName, color, ...restProps } = props;
  const { currentBreakpoint } = useResponsive();
  const expandedAnimatedValue = useSharedValue(0);
  const animatedStyles = useAnimatedStyle(() => {
    const rotateDegree = interpolate(
      expandedAnimatedValue.value,
      [0, 1],
      [0, 180],
    );

    return { transform: [{ rotate: `${rotateDegree}deg` }] };
  }, []);

  // rotate icon on {expanded} prop change
  useLayoutEffect(() => {
    // eslint-disable-next-line functional/immutable-data
    expandedAnimatedValue.value = withTiming(Number(expanded), {
      duration: 200,
    });
  }, [expanded, expandedAnimatedValue]);

  return (
    <Animated.View style={animatedStyles} testID="sg-collapse-indicator-icon">
      <Icon
        name={
          iconName ?? currentBreakpoint.isXS
            ? 'IconCaretRight'
            : 'IconCaretDown'
        }
        color={color ?? COLORS.NEUTRAL_2}
        width={currentBreakpoint.isXS ? 18 : 24}
        height={currentBreakpoint.isXS ? 18 : 24}
        {...restProps}
      />
    </Animated.View>
  );
};

//
// ─── TYPES ──────────────────────────────────────────────────────────────────────
//

type IconProps = Readonly<{
  expanded: boolean;
  iconName?: React.ComponentProps<typeof Icon>['name'];
}> &
  Omit<React.ComponentProps<typeof Icon>, 'name'>;
