import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { useResponsive } from '@sg/garnish';

export const LoyaltyHomeBody = (props: PropsWithChildren) => {
  const { children } = props;
  const { match } = useResponsive();

  return (
    <View style={match([styles.containerXs, styles.containerSm])}>
      {children}
    </View>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerXs: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing['2'],
    padding: theme.spacing['4'],
  },
  containerSm: {
    alignItems: 'center',
    gap: theme.spacing['6'],
    padding: theme.spacing['16'],
  },
});
