import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { Platform, ScrollView, StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Header } from '@expo/html-elements';
import { AnimatedHeader, theme, TitleText, VStack } from '@sg/garnish';

import { Bag } from '@order/features/ordering';
import type {
  DeliveryOrderDetail,
  DeliveryOrderDetailInput,
  Product,
} from '@order/graphql';
import { useTelemetry, useTrackEffect } from '@order/Telemetry';

import { UpsellCategory } from './components';

// ─────────────────────────────────────────────────────────────────────────────

export const CategoryUpsells = (props: CategoryUpsellsProps) => {
  const {
    isFetchingCart = false,
    upsellIdsBeingAdded,
    categories,
    deliveryOrderDetail,
    addUpsellToBag,
    onClose,
    onContinue,
  } = props;

  const { formatMessage } = useIntl();
  const { track } = useTelemetry();

  useTrackEffect('category_upsells.viewed');

  const handleOnContinue = useCallback(() => {
    track('category_upsells.continue.pressed');
    onContinue();
  }, [onContinue, track]);

  const handleOnClose = useCallback(() => {
    track('category_upsells.close.pressed');
    onClose();
  }, [onClose, track]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <>
      <ScrollView
        stickyHeaderIndices={[0]}
        contentContainerStyle={styles.container}
      >
        <SafeAreaView edges={safeAreaEdges}>
          <Header style={styles.header}>
            <TitleText sizeMatch={['24']}>
              <FormattedMessage {...messages.title} />
            </TitleText>

            <AnimatedHeader.CloseButton
              accessibilityLabel={formatMessage(messages.closeBag)}
              onPress={handleOnClose}
            />
          </Header>
        </SafeAreaView>

        <VStack style={styles.content} gap={theme.spacing['4']}>
          {categories.map((category) => (
            <UpsellCategory
              key={category.id}
              name={category.name}
              products={category.products}
              deliveryOrderDetail={deliveryOrderDetail}
              upsellIdsBeingAdded={upsellIdsBeingAdded}
              addUpsellToBag={addUpsellToBag}
            />
          ))}
        </VStack>
      </ScrollView>

      <Bag.FloatingCtas>
        <Bag.ContinueToBagCta
          isDisabled={isFetchingCart}
          onPress={handleOnContinue}
        />
      </Bag.FloatingCtas>
    </>
  );
};

// ─── Constants ──────────────────────────────────────────────────────────────

const safeAreaEdges = Platform.select({
  android: ['top'] as const,
  default: [],
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  header: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.APP_BACKGROUND,
    padding: theme.spacing['4'],
  },
  content: {
    paddingHorizontal: theme.spacing['4'],
    paddingBottom: theme.spacing['30'],
  },
});

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  title: {
    defaultMessage: 'Anything else?',
    description: 'Category Upsells | Title',
  },
  dismissUpsells: {
    defaultMessage: 'Dismiss upsells',
    description: 'Category Upsells | Dismiss upsells',
  },
  closeBag: {
    defaultMessage: 'Close Bag',
    description: 'Category Upsells | Close bag button',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CategoryUpsellsProps = {
  categories: UpsellCategories;
  isFetchingCart?: boolean;
  upsellIdsBeingAdded: string[];
  deliveryOrderDetail?: DeliveryOrderDetail;
  addUpsellToBag: (payload: {
    product: Partial<Product>;
    deliveryDetails?: DeliveryOrderDetailInput;
  }) => void;
  onClose: () => void;
  onContinue: () => void;
};

type UpsellCategories = {
  id: string;
  name: string;
  categoryRanking: number;
  products: UpsellProduct[];
}[];

type UpsellProduct = Readonly<{
  id: string;
  name: string;
  cost: number;
  imageUrl: string;
  outOfStock: boolean;
  menuId: number;
}>;
