import React, { type PropsWithChildren } from 'react';
import { ScrollView, StyleSheet } from 'react-native';

export const LoyaltyHomeContainer = (props: PropsWithChildren) => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      {props.children}
    </ScrollView>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
});
