import type { ComponentProps } from 'react';
import type { ImageRequireSource } from 'react-native';

import { IllusCredit_1, IllusEmpty_1, IllusMushroom } from '../../assets';
import type { RewardCard } from '../RewardCard';
import {
  IllusBerry,
  IllusCarrotPremium,
  IllusFlower,
  IllusMushroomPremium,
  IllusPeasPremium,
  IllusSprout,
  IllusTomatoPremium,
} from './assets';

// ────────────────────────────────────────────────────────────────────────────────

export function getRewardIllustrationByPalette(
  palette: RewardCardPalette,
  index: number,
) {
  const illustrations = illustrationForPalette[palette];

  return illustrations[Math.abs(index % illustrations.length)];
}

// ─────────────────────────────────────────────────────────────────────────────

const illustrationForPalette: IllustrationsForPalettes = {
  quinoa: [IllusBerry, IllusFlower, IllusSprout],
  kale: [
    IllusCarrotPremium,
    IllusPeasPremium,
    IllusMushroomPremium,
    IllusTomatoPremium,
  ],
  cucumber: [IllusCredit_1, IllusEmpty_1, IllusMushroom],
};

// ─── Types ───────────────────────────────────────────────────────────────────

type IllustrationsForPalettes = Record<
  RewardCardPalette,
  readonly ImageRequireSource[]
>;

type RewardCardPalette = NonNullable<
  ComponentProps<typeof RewardCard>['palette']
>;
