import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useContentfulContentTypeEntry } from '@order/Contentful';
import { ApplyRewardFailureCode } from '@order/graphql';

/**
 * Fetches reward error messages from Contentful (with intl fallbacks).
 * @see {@link https://app.contentful.com/spaces/wme4s8lvzccr/entries/12qSE321S24oul9rg2VXnx}
 */
export const useApplyRewardErrorMessages = () => {
  const { data, fetching } =
    useContentfulContentTypeEntry<ApplyRewardErrorMessages>({
      contentType: APPLY_REWARD_ERROR_MESSAGES,
    });

  const fallbackMessages = useFallbackMessages();
  const messages = data?.fields ?? fallbackMessages;

  const defaultError = messages.REWARD_NOT_APPLICABLE;
  const networkError = messages.NETWORK_ERROR;
  const multipleErrors = messages.REWARD_MULTIPLE_ERRORS;

  const getApplyRewardFailureCode = useCallback(
    (code = '') => {
      switch (code) {
        case ApplyRewardFailureCode.LoyaltyNotProcessed: {
          return messages.REWARD_NOT_APPLICABLE;
        }

        case ApplyRewardFailureCode.LoyaltyProgramUnavailable: {
          return messages.REWARD_NOT_APPLICABLE;
        }

        case ApplyRewardFailureCode.RestaurantNotAcceptingOrders: {
          return messages.RESTAURANT_NOT_ACCEPTING_ORDERS;
        }

        case ApplyRewardFailureCode.RewardExpired: {
          return messages.REWARD_EXPIRED;
        }

        case ApplyRewardFailureCode.RewardNotApplicable: {
          return messages.REWARD_NOT_APPLICABLE;
        }

        case ApplyRewardFailureCode.RewardNotFound: {
          return messages.REWARD_NOT_FOUND;
        }

        default: {
          return messages.REWARD_NOT_APPLICABLE;
        }
      }
    },
    [
      messages.RESTAURANT_NOT_ACCEPTING_ORDERS,
      messages.REWARD_EXPIRED,
      messages.REWARD_NOT_APPLICABLE,
      messages.REWARD_NOT_FOUND,
    ],
  );

  const getApplyRewardFailureReason = useCallback(
    (reason = '') => {
      switch (reason) {
        case 'CHANNEL_RESTRICTION': {
          return messages.CHANNEL_RESTRICTION;
        }

        case 'DAY_RESTRICTION': {
          return messages.DAY_RESTRICTION;
        }

        case 'MISSING_SKU': {
          return messages.MISSING_SKU;
        }

        case 'PRICE_MAXIMUM': {
          return messages.PRICE_MAXIMUM;
        }

        case 'PRICE_MINIMUM': {
          return messages.PRICE_MINIMUM;
        }

        case 'RESTAURANT_NOT_ACCEPTING_ORDERS': {
          return messages.RESTAURANT_NOT_ACCEPTING_ORDERS;
        }

        case 'REWARD_EXPIRED': {
          return messages.REWARD_EXPIRED;
        }

        case 'CART_NOT_FOUND': {
          return messages.REWARD_NOT_APPLICABLE;
        }

        case 'LOYALTY_LOCATION_NOT_FOUND': {
          return messages.REWARD_NOT_APPLICABLE;
        }

        case 'REWARD_NOT_APPLICABLE': {
          return messages.REWARD_NOT_APPLICABLE;
        }

        case 'REWARD_NOT_FOUND': {
          return messages.REWARD_NOT_FOUND;
        }

        case 'TIME_RESTRICTION': {
          return messages.TIME_RESTRICTION;
        }

        default: {
          return messages.REWARD_NOT_APPLICABLE;
        }
      }
    },
    [
      messages.CHANNEL_RESTRICTION,
      messages.DAY_RESTRICTION,
      messages.MISSING_SKU,
      messages.PRICE_MAXIMUM,
      messages.PRICE_MINIMUM,
      messages.RESTAURANT_NOT_ACCEPTING_ORDERS,
      messages.REWARD_EXPIRED,
      messages.REWARD_NOT_APPLICABLE,
      messages.REWARD_NOT_FOUND,
      messages.TIME_RESTRICTION,
    ],
  );

  return useMemo(
    () => ({
      isLoadingErrorMessages: fetching,
      defaultError,
      networkError,
      multipleErrors,
      getApplyRewardFailureCode,
      getApplyRewardFailureReason,
    }),
    [
      defaultError,
      fetching,
      multipleErrors,
      networkError,
      getApplyRewardFailureCode,
      getApplyRewardFailureReason,
    ],
  );
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

const useFallbackMessages = () => {
  const { formatMessage } = useIntl();

  return {
    NETWORK_ERROR: formatMessage(FALLBACKS.NETWORK_ERROR),
    REWARD_MULTIPLE_ERRORS: formatMessage(FALLBACKS.REWARD_MULTIPLE_ERRORS),
    REWARD_NOT_APPLICABLE: formatMessage(FALLBACKS.REWARD_NOT_APPLICABLE),
    REWARD_EXPIRED: formatMessage(FALLBACKS.REWARD_EXPIRED),
    REWARD_NOT_FOUND: formatMessage(FALLBACKS.REWARD_NOT_FOUND),
    RESTAURANT_NOT_ACCEPTING_ORDERS: formatMessage(
      FALLBACKS.RESTAURANT_NOT_ACCEPTING_ORDERS,
    ),
    MISSING_SKU: formatMessage(FALLBACKS.MISSING_SKU),
    PRICE_MINIMUM: formatMessage(FALLBACKS.PRICE_MINIMUM),
    PRICE_MAXIMUM: formatMessage(FALLBACKS.PRICE_MAXIMUM),
    TIME_RESTRICTION: formatMessage(FALLBACKS.TIME_RESTRICTION),
    CHANNEL_RESTRICTION: formatMessage(FALLBACKS.CHANNEL_RESTRICTION),
    DAY_RESTRICTION: formatMessage(FALLBACKS.DAY_RESTRICTION),
  };
};

// ─── Constants ───────────────────────────────────────────────────────────────

const APPLY_REWARD_ERROR_MESSAGES = 'applyRewardErrorMessages';
const FALLBACKS = defineMessages<keyof ApplyRewardErrorMessages>({
  NETWORK_ERROR: {
    defaultMessage: 'Something went wrong with the reward.',
    description: 'Error message for {NETWORK_ERROR}',
  },
  REWARD_MULTIPLE_ERRORS: {
    defaultMessage:
      'This order might not meet the conditions of the reward selected, so it will not be applied at checkout.',
    description: 'Error message for {REWARD_MULTIPLE_ERRORS}',
  },
  REWARD_NOT_APPLICABLE: {
    defaultMessage:
      'This order might not meet the conditions of the reward selected, so it will not be applied at checkout.',
    description: 'Error message for {REWARD_NOT_APPLICABLE}',
  },
  REWARD_EXPIRED: {
    defaultMessage:
      'Your selected reward has expired and will not be applied at checkout.',
    description: 'Error message for {REWARD_EXPIRED}',
  },
  REWARD_NOT_FOUND: {
    defaultMessage:
      'We were unable to apply your selected reward, so it will not be applied at checkout.',
    description: 'Error message for {REWARD_NOT_FOUND}',
  },
  RESTAURANT_NOT_ACCEPTING_ORDERS: {
    defaultMessage:
      'Online ordering is currently unavailable at this location.',
    description: 'Error message for {RESTAURANT_NOT_ACCEPTING_ORDERS}',
  },
  MISSING_SKU: {
    defaultMessage:
      'This order does not meet the conditions of the reward selected, please add required item to apply the reward.',
    description: 'Error message for {MISSING_SKU}',
  },
  PRICE_MINIMUM: {
    defaultMessage:
      'This order does not meet the price minimum of the reward selected, please adjust your cart to apply the reward.',
    description: 'Error message for {PRICE_MINIMUM}',
  },
  PRICE_MAXIMUM: {
    defaultMessage:
      'This order does not meet the price maximum of the reward selected, please adjust your cart to apply the reward.',
    description: 'Error message for {PRICE_MAXIMUM}',
  },
  TIME_RESTRICTION: {
    defaultMessage:
      'This order does not meet the time restriction(s) of the reward selected.',
    description: 'Error message for {TIME_RESTRICTION}',
  },
  CHANNEL_RESTRICTION: {
    defaultMessage:
      'This order does not meet the conditions of the reward selected, please switch to a (channel) order to apply the reward.',
    description: 'Error message for {CHANNEL_RESTRICTION}',
  },
  DAY_RESTRICTION: {
    defaultMessage:
      'This offer is valid to redeem on (days). Please apply this reward on a valid day.',
    description: 'Error message for {DAY_RESTRICTION}',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ApplyRewardErrorMessages = Readonly<{
  NETWORK_ERROR: string;
  REWARD_MULTIPLE_ERRORS: string;
  REWARD_NOT_APPLICABLE: string;
  REWARD_EXPIRED: string;
  REWARD_NOT_FOUND: string;
  RESTAURANT_NOT_ACCEPTING_ORDERS: string;
  MISSING_SKU: string;
  PRICE_MINIMUM: string;
  PRICE_MAXIMUM: string;
  TIME_RESTRICTION: string;
  CHANNEL_RESTRICTION: string;
  DAY_RESTRICTION: string;
}>;
