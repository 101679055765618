import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { TextLink } from '@sg/garnish';

export const HowItWorksCta = (props: HowItWorksCtaProps) => {
  const { onPress } = props;

  const { formatMessage } = useIntl();
  const label = formatMessage(messages.howItWorks);

  return (
    <TextLink
      underline
      sizeMatch={['14']}
      accessibilityRole="link"
      accessibilityLabel={label}
      onPress={onPress}
    >
      {label}
    </TextLink>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  howItWorks: {
    defaultMessage: 'How it works',
    description: 'Loyalty | How it works | CTA',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type HowItWorksCtaProps = {
  onPress: () => void;
};
