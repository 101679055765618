import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { type SharedValue } from 'react-native-reanimated';
import { AnimatedHeader } from '@sg/garnish';

import { Bag } from '@order/features/ordering';

import { type useOrderState } from '../../hooks';

/**
 * Header for the animated dialog.
 */
export const BagAnimatedDialogHeader = (
  props: BagAnimatedDialogHeaderProps,
) => {
  const { orderState, scrollOffset } = props;
  const {
    isChangingTime,
    isChangingDeliveryPreferences,
    isShowingCheckoutLedger,
    isChangingPaymentMethod,
    isShowingPaymentMethodForm,
    isShowingGiftCardForm,
    isShowingLoyaltyInfo,
    cancelRequest,
    goBack,
  } = orderState;

  const { formatMessage } = useIntl();

  // ──────────────────────────────────────────────────────────────────────────

  return (
    <AnimatedHeader.FloatingContainer
      palette="cream"
      scrollOffset={scrollOffset}
    >
      <AnimatedHeader.BackButton
        accessibilityLabel={formatMessage(messages.back)}
        onPress={goBack}
      />

      <AnimatedHeader.Content pointerEvents="none" scrollOffset={scrollOffset}>
        <Bag.AnimatedDialogTitle
          isShowingCheckoutLedger={isShowingCheckoutLedger}
          isChangingTime={isChangingTime}
          isChangingDeliveryPreferences={isChangingDeliveryPreferences}
          isChangingPaymentMethod={isChangingPaymentMethod}
          isShowingPaymentMethodForm={isShowingPaymentMethodForm}
          isShowingGiftCardForm={isShowingGiftCardForm}
          isShowingLoyaltyInfo={isShowingLoyaltyInfo}
        />
      </AnimatedHeader.Content>

      <AnimatedHeader.CloseButton
        accessibilityLabel={formatMessage(messages.close)}
        onPress={cancelRequest}
      />
    </AnimatedHeader.FloatingContainer>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  back: {
    defaultMessage: 'Go back',
    description: 'Bag | Header | Go back',
  },
  close: {
    defaultMessage: 'Close',
    description: 'Bag | Header | Close',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagAnimatedDialogHeaderProps = {
  orderState: ReturnType<typeof useOrderState>;
  scrollOffset: SharedValue<number>;
};
