import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';
import { Button } from '@sg/garnish';

export const LoyaltyPointsHistorySeeMoreCta = (
  props: LoyaltyPointsHistorySeeMoreCtaProps,
) => {
  const { isLoading, onPress } = props;

  const { formatMessage } = useIntl();
  const label = formatMessage(messages.seeMore);

  return (
    <Button
      size="large-wide"
      palette="kale"
      accessibilityRole="button"
      accessibilityLabel={label}
      isLoading={isLoading}
      style={styles.cta}
      onPress={onPress}
    >
      {label}
    </Button>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  seeMore: {
    defaultMessage: 'See more',
    description: 'Loyalty | Points History | See more',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  cta: {
    alignSelf: 'center',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyPointsHistorySeeMoreCtaProps = {
  isLoading: boolean;
  onPress: () => void;
};
