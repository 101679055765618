import React from 'react';
import { Platform, StyleSheet, type ViewProps } from 'react-native';
import Animated, {
  interpolateColor,
  type SharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { theme } from '@garnish/constants';

import { ANIMATED_HEADER_SIZE } from './AnimatedHeader.constants';

export const AnimatedHeaderContainer = (
  props: AnimatedHeaderContainerProps,
) => {
  const { children, scrollOffset, palette, ...rest } = props;

  const { top } = useSafeAreaInsets();
  const topInset = Platform.OS === 'android' ? top : 0;

  const { containerStyle } = useAnimatedHeaderContainerStyle({
    scrollOffset,
    palette,
    topInset,
  });

  return (
    <Animated.View pointerEvents="box-none" style={containerStyle} {...rest}>
      {children}
    </Animated.View>
  );
};

export const AnimatedHeaderFloatingContainer = (
  props: AnimatedHeaderContainerProps,
) => {
  const { children, scrollOffset, palette, ...rest } = props;
  const { containerStyle } = useAnimatedHeaderContainerStyle({
    scrollOffset,
    palette,
  });

  return (
    <Animated.View
      pointerEvents="box-none"
      style={[styles.floatingContainer, containerStyle]}
      {...rest}
    >
      {children}
    </Animated.View>
  );
};

// ─── Helpers ────────────────────────────────────────────────────────────────

export const useAnimatedHeaderContainerStyle = (
  props: AnimatedHeaderContainerProps,
) => {
  const { scrollOffset, palette = 'oatmeal', topInset = 0 } = props;

  const headerHeight = ANIMATED_HEADER_SIZE + topInset;

  const animatedContainerStyle = useAnimatedStyle(
    () => ({
      maxHeight: headerHeight,
      paddingTop: theme.spacing['4'] + topInset,
      backgroundColor: interpolateColor(
        scrollOffset?.value ?? 0,
        [0, headerHeight],
        paletteColors[palette],
      ),
      borderColor: interpolateColor(
        scrollOffset?.value ?? 0,
        [0, headerHeight],
        [theme.colors.OPACITY.TRANSPARENT, theme.colors.DARK_KALE],
      ),
    }),
    [scrollOffset, topInset, headerHeight],
  );

  const containerStyle = [styles.container, animatedContainerStyle];

  return { containerStyle };
};

// ─── Types ──────────────────────────────────────────────────────────────────

type AnimatedHeaderContainerProps = ViewProps & {
  scrollOffset?: SharedValue<number>;
  topInset?: number;
  palette?: 'cream' | 'oatmeal';
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopLeftRadius: theme.radius.large,
    borderTopRightRadius: theme.radius.large,
    borderBottomWidth: 1,
    padding: theme.spacing['4'],
  },
  floatingContainer: {
    marginBottom: -theme.spacing['16'],
  },
});

const paletteColors = {
  oatmeal: [
    theme.colors.OPACITY.OATMEAL.ALMOST_TRANSPARENT,
    theme.colors.OATMEAL,
  ],
  cream: [theme.colors.OPACITY.CREAM.ALMOST_TRANSPARENT, theme.colors.CREAM],
};
