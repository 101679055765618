/* eslint-disable react-native/no-unused-styles */

import type { ReactNode } from 'react';
import React from 'react';
import type { ViewStyle } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

// ─────────────────────────────────────────────────────────────────────────────

export const ScanCardContainer = (props: ScanCardContainerProps) => {
  const { children, palette = 'quinoa' } = props;

  const containerStyles = [styles.container, paletteStyles[palette]];

  return <View style={containerStyles}>{children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    borderRadius: theme.radius.large,
    paddingVertical: theme.spacing['12'],
    paddingHorizontal: theme.spacing['6'],
  },
});

const paletteStyles = StyleSheet.create<
  Record<ScanCardContainerPalette, ViewStyle>
>({
  quinoa: {
    backgroundColor: theme.colors.QUINOA,
  },
  cucumber: {
    backgroundColor: theme.colors.CUCUMBER,
  },
  kale: {
    backgroundColor: theme.colors.KALE,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ScanCardContainerProps = Readonly<{
  children: ReactNode;
  palette?: ScanCardContainerPalette;
}>;

type ScanCardContainerPalette = 'quinoa' | 'cucumber' | 'kale';
