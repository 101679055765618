import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import * as Clipboard from 'expo-clipboard';
import { Button, openNativeShare, theme } from '@sg/garnish';

import { type PartialLineItem } from '@order/graphql';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';
import { useLineItemDescription, useLineItemName } from '@order/shared/hooks';
import { getHostName } from '@order/utils';

import { useReorderState } from '../../screens/ReorderingScreen/hooks';
import { useDietaryRestrictions } from '../DietaryRestrictions';
import { ProductCard } from '../ProductCard';

export const SharedLineItem = ({
  lineItem,
  onAddToBag,
  onFavorite,
}: SharedLineItemProps) => {
  const {
    id,
    slug,
    quantity,
    product,
    removedIngredients,
    addedIngredients,
    favorited,
  } = lineItem;

  const { t, formatPrice, formatNumber } = useLocalizationContext();
  const getRestrictionNotice = useDietaryRestrictions();
  const { reordering, lineItemId } = useReorderState();

  const shouldHidePriceOnFavoritesAndShare = useFeatureFlag(
    'CELS-2569-hide-price-on-favorites-and-share',
    { listenForChanges: true },
  );

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleShare = useCallback(() => {
    const nativeText = t('general.share-favorite', {
      productName: product.name,
    });
    const url = `${getHostName()}/share/${slug}`;

    void openNativeShare({
      message: nativeText,
      url,
      options: {
        subject: nativeText,
      },
    });
    void Clipboard.setStringAsync(url);
  }, [t, product.name, slug]);

  // ─────────────────────────────────────────────────────────────────────

  const lineItemDescription = useLineItemDescription(lineItem);
  const quantityLabel = t('account.favorites.quantity', {
    quantity,
  });

  const productName = useLineItemName(product, lineItem);
  const price = shouldHidePriceOnFavoritesAndShare
    ? undefined
    : formatPrice(product.cost ?? 0, 'USD');

  return (
    <ProductCard
      cloudinaryImageSrc={product.asset?.url ?? ''}
      restrictionNotice={getRestrictionNotice(product.dietaryProperties)}
      name={productName}
      addedIngredientsLabel={t('product-added-ingredients-label')}
      addedIngredients={addedIngredients}
      removedIngredientsLabel={t('product-removed-ingredients-label')}
      removedIngredients={removedIngredients}
      description={lineItemDescription}
      quantity={quantityLabel}
      label={product.label?.name}
      price={price}
      calories={`${formatNumber(product.calories ?? 0)} cal`}
      onFavorite={onFavorite}
      favorited={favorited}
      onShare={slug ? handleShare : undefined}
      FooterElement={
        onAddToBag ? (
          <Button
            testID="shared-line-item.add-to-bag"
            palette="primary"
            width="100%"
            accessibilityRole="button"
            accessibilityLabel={t('account.favorites.add-to-bag')}
            accessibilityHint={t('account.favorites.add-to-bag')}
            style={styles.addToBagButton}
            disabled={
              reordering ? Number(id) !== Number(lineItemId) : undefined
            }
            isLoading={
              reordering ? Number(id) === Number(lineItemId) : undefined
            }
            onPress={onAddToBag}
          >
            {t('account.favorites.add-to-bag')}
          </Button>
        ) : null
      }
    />
  );
};

type SharedLineItemProps = Readonly<{
  lineItem: PartialLineItem;
  onAddToBag?: () => void;
  onFavorite?: () => Promise<void>;
}>;

const styles = StyleSheet.create({
  addToBagButton: {
    marginTop: theme.spacing['4'],
  },
});
