import { defineMessages } from 'react-intl';

export const fallbacks = defineMessages({
  bagHeaderLoggedOut: {
    defaultMessage: 'Complete your order + earn',
    description: 'Bag | Loyalty | Loyalty Title Logged Out',
  },
  bagHeaderNewUser: {
    defaultMessage: 'You’re about to get rewarded',
    description: 'Bag | Loyalty | Loyalty Title New User',
  },
  bagHeaderRecurringUser: {
    defaultMessage: 'You have {points} points',
    description: 'Bag | Loyalty | Loyalty Title Recurring User',
  },
  bagHeaderSubtitle: {
    defaultMessage: 'Apply one available reward to this order',
    description: 'Bag | Loyalty | Loyalty Subtitle',
  },
  bagHeaderLoggedOutImageMessage: {
    defaultMessage:
      'Get a free side of Ripple Fries when you create an account',
    description: 'Bag | Loyalty | Loyalty Image Text',
  },
});

export const loyaltyInfoBenefitsFallback = [
  {
    title: 'Crispy Rice Treat',
    points: 500,
    imageUrl:
      '//images.ctfassets.net/wme4s8lvzccr/13xwNAloLgS5ajrOyzHU0n/a6c61b7c012a0b4518ee844621d67eae/Loyalty_-_Benefit_-_Crispy_Rice_Treat.png',
  },
  {
    title: 'Drink',
    points: 700,
    imageUrl:
      '//images.ctfassets.net/wme4s8lvzccr/4Wd41PMz6R9WMIy9G9cyoa/f88bddc4d86e63f09da8746d09bec640/Loyalty_-_Benefit_-_Drink.png',
  },
  {
    title: 'Crispy Potatoes',
    points: 1000,
    imageUrl:
      '//images.ctfassets.net/wme4s8lvzccr/3S9kW1yfi18W68RMG7j1Od/26b0c79b4d910f2aab641cf1bd6c9db7/Loyalty_-_Benefit_-_Crispy_Potatoes.png',
  },
  {
    title: '50% off entree',
    points: 1500,
    imageUrl:
      '//images.ctfassets.net/wme4s8lvzccr/Mcm3WeSZRnhO1F0mVwpRm/3978214ae32eedacf0ebd07d4ba2b659/Loyalty_-_Benefit_-_50-_off_entree.png',
  },
  {
    title: 'Free entree up to $18',
    points: 2500,
    imageUrl:
      '//images.ctfassets.net/wme4s8lvzccr/xc8G6XNYaAA5x7rZSoBKw/0ee2e8e248ca5a382491ee011c91a0e1/Loyalty_-_Benefit_-_Free_entree_up_to__18.png',
  },
];
