import type { ComponentProps } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { SectionHeader } from '../../Header';
import { type IconName } from '../../Icon';
import { HorizontalScrollRailNav } from './HorizontalScrollRailNav';

// ────────────────────────────────────────────────────────────────────────────────

export const HorizontalScrollRailHeader = (
  props: HorizontalScrollRailHeaderProps,
) => {
  const {
    heading = '',
    headingIcon,
    subtitle = '',
    headerVariation,
    withoutHeaderBorder,
    count,
    countPosition,
    ...railNavProps
  } = props;

  return (
    <View style={styles.headerWrapper}>
      <SectionHeader
        heading={heading}
        headingIcon={headingIcon}
        subtitle={subtitle}
        subtitleStyle={styles.subtitle}
        variation={headerVariation}
        withoutBorder={withoutHeaderBorder}
        count={countPosition === 'left' ? count : 0}
      >
        <HorizontalScrollRailNav
          {...railNavProps}
          count={countPosition === 'right' ? count : 0}
        />
      </SectionHeader>
    </View>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  headerWrapper: {
    paddingBottom: theme.spacing['4'],
  },
  subtitle: {
    color: theme.colors.CHARCOAL,
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

export type HorizontalScrollRailHeaderProps = Readonly<{
  heading?: string;
  headingIcon?: IconName;
  count?: number;
  subtitle?: string;
  countPosition?: 'left' | 'right';
  headerVariation?: ComponentProps<typeof SectionHeader>['variation'];
  withoutHeaderBorder?: ComponentProps<typeof SectionHeader>['withoutBorder'];
}> &
  ComponentProps<typeof HorizontalScrollRailNav>;
