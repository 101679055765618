import type { ComponentProps } from 'react';
import React from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../../hooks';
import { IconLink } from '../../../Icon';
import type { PaletteProps } from '../../Modal.types';
import { ModalRow } from '../ModalRow';

export const ModalCloseBtn = (props: ModalCloseBtnProps) => {
  const { palette, ...rest } = props;

  const { match } = useResponsive();
  const responsiveStyles = match([
    styles.closeBtnWrapperMobile,
    styles.closeBtnWrapperTabletUp,
  ]);

  const paletteSpecificColor = palette ? iconColors[palette] : undefined;

  return (
    <ModalRow style={[styles.closeBtnWrapper, responsiveStyles, props.style]}>
      <IconLink
        name="IconClose"
        accessibilityLabel="Close dialog"
        color={paletteSpecificColor}
        {...rest}
      />
    </ModalRow>
  );
};

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  closeBtnWrapper: {
    alignItems: 'flex-end',
    paddingBottom: theme.spacing['4'],
  },
  closeBtnWrapperMobile: {
    paddingTop: theme.spacing['4'],
  },
  closeBtnWrapperTabletUp: {
    paddingTop: theme.spacing['6'],
  },
});

// ─── Palette Styles ────────────────────────────────────────────────────

const iconColors = {
  primary: undefined,
  neutral: theme.colors.BLACK,
  secondary: theme.colors.WHITE,
  lime: theme.colors.WHITE,
};

// ─── Types ─────────────────────────────────────────────────────────────

type ModalCloseBtnProps = Omit<
  ComponentProps<typeof IconLink>,
  'name' | 'palette'
> &
  PaletteProps;
