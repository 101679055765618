import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import type { Asset, Entry } from 'contentful';

import { useContentfulContentTypeEntry } from '@order/Contentful';

import { fallbacks, loyaltyInfoBenefitsFallback } from './fallbacks';

/**
 * Returns the contentful data for the bag.
 */
export const useLoyaltyContent = (availablePoints = 0) => {
  const content = useContentfulContentTypeEntry<LoyaltyContainer>({
    contentType: 'loyalty',
  });

  const contentfulData = content.data?.fields;

  const { formatMessage } = useIntl();

  const fallbackData = {
    loyaltyInfoBenefits: loyaltyInfoBenefitsFallback,
    bagHeaderLoggedOut: formatMessage(fallbacks.bagHeaderLoggedOut),
    bagHeaderNewUser: formatMessage(fallbacks.bagHeaderNewUser),
    bagHeaderRecurringUser: formatMessage(fallbacks.bagHeaderRecurringUser, {
      points: availablePoints,
    }),
    bagHeaderSubtitle: formatMessage(fallbacks.bagHeaderSubtitle),
    bagHeaderLoggedOutImageMessage: formatMessage(
      fallbacks.bagHeaderLoggedOutImageMessage,
    ),
  };

  const loyaltyInfoBenefits =
    contentfulData?.loyaltyInfoBenefits
      ?.map(extractLoyaltyInfoBenefit)
      ?.filter(filterOutInvalidLoyaltyInfoBenefits) ??
    fallbackData.loyaltyInfoBenefits;

  const faqLoyaltyHome = (contentfulData?.faqLoyaltyHome ?? EMPTY_FAQ)
    ?.map(extractLoyaltyFAQ)
    ?.filter(filterOutInvalidLoyaltyFAQ);

  const faqPointHistory = (contentfulData?.faqPointHistory ?? EMPTY_FAQ)
    ?.map(extractLoyaltyFAQ)
    ?.filter(filterOutInvalidLoyaltyFAQ);

  const bagHeaderLoggedOut =
    contentfulData?.bagHeaderLoggedOut ?? fallbackData?.bagHeaderLoggedOut;
  const bagHeaderNewUser =
    contentfulData?.bagHeaderNewUser ?? fallbackData?.bagHeaderNewUser;
  const bagHeaderRecurringUser =
    contentfulData?.bagHeaderRecurringUser ??
    fallbackData?.bagHeaderRecurringUser;
  const bagHeaderSubtitle =
    contentfulData?.bagHeaderSubtitle ?? fallbackData?.bagHeaderSubtitle;
  const bagHeaderLoggedOutImageMessage =
    contentfulData?.bagHeaderLoggedOutImageMessage ??
    fallbackData?.bagHeaderLoggedOutImageMessage;
  const bagHeaderLoggedOutImage =
    contentfulData?.bagHeaderLoggedOutImage?.fields?.file?.url;

  return useMemo(
    () => ({
      loyaltyInfoBenefits,
      faqLoyaltyHome,
      faqPointHistory,
      bagHeaderLoggedOut,
      bagHeaderNewUser,
      bagHeaderRecurringUser,
      bagHeaderSubtitle,
      bagHeaderLoggedOutImageMessage,
      bagHeaderLoggedOutImage,
    }),
    [
      loyaltyInfoBenefits,
      faqLoyaltyHome,
      faqPointHistory,
      bagHeaderLoggedOut,
      bagHeaderNewUser,
      bagHeaderRecurringUser,
      bagHeaderSubtitle,
      bagHeaderLoggedOutImageMessage,
      bagHeaderLoggedOutImage,
    ],
  );
};

// ─── Container ──────────────────────────────────────────────────────────────

type LoyaltyContainer = {
  loyaltyInfoBenefits: Entry<LoyaltyInfoBenefitEntry>[];
  faqLoyaltyHome: Entry<LoyaltyFAQ>[];
  faqPointHistory: Entry<LoyaltyFAQ>[];

  bagHeaderLoggedOut?: string;
  bagHeaderNewUser?: string;
  bagHeaderRecurringUser?: string;
  bagHeaderSubtitle?: string;
  bagHeaderLoggedOutImageMessage?: string;
  bagHeaderLoggedOutImage?: Asset;
};

// ─── Loyalty Info Benefits ──────────────────────────────────────────────────

type LoyaltyInfoBenefitEntry = {
  title?: string;
  description?: string;
  image?: Asset;
};

type LoyaltyInfoBenefit = {
  title: string;
  points: number;
  imageUrl: string;
};

const extractLoyaltyInfoBenefit = (
  loyaltyInfoBenefit?: Entry<LoyaltyInfoBenefitEntry>,
) => {
  return {
    title: loyaltyInfoBenefit?.fields?.title,
    points: Number(loyaltyInfoBenefit?.fields?.description),
    imageUrl: loyaltyInfoBenefit?.fields?.image?.fields?.file?.url,
  };
};

const filterOutInvalidLoyaltyInfoBenefits = (
  entry: Partial<LoyaltyInfoBenefit>,
): entry is Required<LoyaltyInfoBenefit> => {
  return Boolean(entry.title && entry.points && entry.imageUrl);
};

// ─── Loyalty FAQ ────────────────────────────────────────────────────────────

type LoyaltyFAQ = {
  title?: string;
  description?: string;
};

const extractLoyaltyFAQ = (loyaltyFAQ?: Entry<LoyaltyFAQ>) => {
  return {
    title: loyaltyFAQ?.fields?.title,
    description: loyaltyFAQ?.fields?.description,
  };
};

const filterOutInvalidLoyaltyFAQ = (
  entry: Partial<LoyaltyFAQ>,
): entry is Required<LoyaltyFAQ> => {
  return Boolean(entry.title && entry.description);
};

const EMPTY_FAQ: Entry<LoyaltyFAQ>[] = [];
