import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Platform, View } from 'react-native';
import type {
  BottomTabBarProps,
  BottomTabNavigationOptions,
} from '@react-navigation/bottom-tabs';
import { useNavigation } from '@react-navigation/native';
import type {
  NativeStackHeaderProps,
  NativeStackNavigationOptions,
} from '@react-navigation/native-stack';
import { theme, TYPE_CONFIG, useResponsive } from '@sg/garnish';

import { useNavigateToLastInteractedStore } from '@order/LastInteractedStore';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';

import { DesktopHeader } from '../components/Header/DesktopHeader';
import { useHeaderRight } from '../components/Header/Header.hooks';
import type {
  HeaderCloseIconItemProps,
  HeaderContentProps,
} from '../components/Header/Header.props';
import { MainTabBar } from '../components/MainTabBar';
import { getCustomBackButtonIcon } from './AppNavigation.backButton';
import type {
  AppStackParamList,
  AuthStackScreenProps,
  ModalStackScreenProps,
} from './AppNavigation.props';
import {
  giftCardCheckoutScreenMessages,
  giftCardConfirmationScreenMessages,
  giftCardRedemptionScreenMessages,
  giftCardsStackScreenMessages,
  redeemGiftCardScreenMessages,
} from './messages';

// Screen options for the app stack.
export const useAppStackOptions = () => {
  const modalContentStyle = useMobileTransparentScreenContentStyle();
  const modalPresentationStyle = useMobileTransparentModalScreenPresentation();

  return useMemo<
    Record<keyof AppStackParamList | 'Navigator', NativeStackNavigationOptions>
  >(
    () => ({
      Navigator: {
        ...DEFAULT_STACK_SCREEN_OPTIONS,
        headerShown: false,
      },
      MainTabs: {
        ...DEFAULT_STACK_SCREEN_OPTIONS,
      },
      Modal: {
        ...DEFAULT_STACK_SCREEN_OPTIONS,
        contentStyle: modalContentStyle,
        presentation: modalPresentationStyle,
      },
      Auth: {
        ...DEFAULT_STACK_SCREEN_OPTIONS,
        presentation: 'modal',
      },
      Error: {
        ...DEFAULT_STACK_SCREEN_OPTIONS,
        presentation: 'fullScreenModal',
      },
    }),
    [modalContentStyle, modalPresentationStyle],
  );
};

// Screen options for the main tabs.
export const useMainTabsOptions = () => {
  const { formatMessage } = useIntl();
  const { t } = useLocalizationContext();

  const { track } = useTelemetry();
  const { navigate } = useNavigation();
  const { currentBreakpoint } = useResponsive();

  const TabBar = useCallback(
    (props: BottomTabBarProps) => {
      // Bottom tabs only enabled on mobile views.
      if (!currentBreakpoint.isXS) {
        return null;
      }

      return <MainTabBar {...props} />;
    },
    [currentBreakpoint.isXS],
  );

  // Menu tab action.
  const navigateToLastInteractedStore = useNavigateToLastInteractedStore();

  const navigateToGiftCardsScreen = useCallback(() => {
    navigate('MainTabs', {
      screen: 'GiftCardsTab',
      params: { screen: 'GiftCards' },
      initial: true,
    });
  }, [navigate]);

  return useMemo(() => {
    const sharedMainTabsOptions = {
      Navigator: DEFAULT_TABS_OPTIONS,
      TabBar,

      // NOTE: Normally we'd prefer responsive logic, over Platform -- but this
      // relates to platform specific navigation idioms. It's possible that we
      // could align on history but it needs to be reviewed more thoroughly. The
      // important thing is that browser history behaves more conventionally.
      BackBehavior:
        Platform.OS === 'web' ? ('history' as const) : ('firstRoute' as const), // firstRoute is default
    };

    return {
      ...sharedMainTabsOptions,

      HomeTab: {
        title: t('nav-bar.home.title'),
        tabBarTestID: 'nav-bar.home',
        iconName: 'IconHomeAlternative',
        onPress() {
          track('tab-bar.home');
        },
      },
      MenuTab: {
        title: t('nav-bar.menu.title'),
        tabBarTestID: 'nav-bar.order',
        customNavigate: navigateToLastInteractedStore,
        onPress() {
          track('tab-bar.menu');
        },
        iconName: 'IconMenuBowl',
      },
      LoyaltyTab: {
        title: t('nav-bar.loyalty.title'),
        tabBarTestID: 'nav-bar.loyalty',
        iconName: 'IconLoyaltyCoupon',
        onPress() {
          track('tab-bar.loyalty');
        },
      },
      ScanTab: {
        title: t('nav-bar.scan.title'),
        tabBarTestID: 'nav-bar.scan',
        iconName: 'IconScan',
        onPress() {
          track('tab-bar.scan');
        },
      },
      GiftCardsTab: {
        title: formatMessage(giftCardsStackScreenMessages.screenTitle),
        tabBarTestID: 'nav-bar.gift-cards',
        customNavigate: navigateToGiftCardsScreen,
        shouldForceOnPress: true,
        iconName: 'IconPromo',
        onPress() {
          track('tab-bar.gift');
        },
      },
      AccountTab: {
        title: t('nav-bar.account.title'),
        tabBarTestID: 'nav-bar.account',
        iconName: 'IconAccount',
        onPress() {
          track('tab-bar.account');
        },
      },
    };
  }, [
    TabBar,
    t,
    navigateToLastInteractedStore,
    formatMessage,
    navigateToGiftCardsScreen,
    track,
  ]);
};

// Screen options for the home tab stack.
export const useHomeTabStackOptions = () => {
  const { t } = useLocalizationContext();

  const navigatorScreenOptions = useTabStackNavigatorScreenOptions({
    mobileHeaderShown: true,
  });

  const homeTabScreenOptions = useTabStackNavigatorScreenOptions({
    mobileHeaderShown: false,
  });

  return useMemo(
    () => ({
      Navigator: {
        ...navigatorScreenOptions,
      },
      Home: {
        ...DEFAULT_SCREEN_OPTIONS,
        ...homeTabScreenOptions,
        customDocumentTitle: t('page-title.home'),
      },
    }),
    [homeTabScreenOptions, navigatorScreenOptions, t],
  );
};

// Screen options for the menu tab stack.
export const useMenuTabStackOptions = () => {
  const { t } = useLocalizationContext();
  const navigatorScreenOptions = useTabStackNavigatorScreenOptions();

  const defaultHeader = useStackHeader({ mobileHeaderShown: true });

  const defaultScreenOptions = useMemo<NativeStackNavigationOptions>(
    () => ({ header: defaultHeader }),
    [defaultHeader],
  );

  const menuHeader = useStackHeader({
    mobileHeaderShown: true,
    dividerHidden: true,
  });

  const menuHeaderScreenOptions = useMemo<NativeStackNavigationOptions>(
    () => ({ header: menuHeader }),
    [menuHeader],
  );

  return useMemo(
    () => ({
      Navigator: {
        ...navigatorScreenOptions,
      },
      Locations: {
        ...defaultScreenOptions,
        customDocumentTitle: t('page-title.locations'),
      },
      MenuWithoutSlug: {
        ...menuHeaderScreenOptions,
      },
      Menu: {
        ...menuHeaderScreenOptions,
      },
      DeliveryMenu: {
        ...menuHeaderScreenOptions,
      },
    }),
    [defaultScreenOptions, menuHeaderScreenOptions, navigatorScreenOptions, t],
  );
};

// Screen options for the loyalty tab stack.
export const useLoyaltyTabStackOptions = () => {
  const { t } = useLocalizationContext();

  const navigatorScreenOptions = useTabStackNavigatorScreenOptions({
    mobileHeaderShown: true,
  });

  return useMemo(
    () => ({
      Navigator: {
        ...navigatorScreenOptions,
      },
      SweetpassHome: {
        ...DEFAULT_SCREEN_OPTIONS,
        customDocumentTitle: t('page-title.sweetpass'),
      },
    }),
    [navigatorScreenOptions, t],
  );
};

// Screen options for the scan tab stack.
export const useScanTabStackOptions = () => {
  const { t } = useLocalizationContext();

  return useMemo(
    () => ({
      Navigator: DEFAULT_TABS_OPTIONS as NativeStackNavigationOptions,
      ScanInStore: {
        ...DEFAULT_STACK_SCREEN_OPTIONS,
        headerShown: true,
        title: t('scan-in-store.title'),
        headerTitle: t('scan-in-store.title'),
        customDocumentTitle: t('page-title.scan-in-store'),
      },
    }),
    [t],
  );
};

// Screen options for the "Gift Cards" tab stack.
export const useGiftCardsTabStackOptions = () => {
  const { formatMessage } = useIntl();

  // ─────────────────────────────────────────────────────────────────────

  const navigatorScreenOptions = useTabStackNavigatorScreenOptions({
    mobileHeaderShown: false,
  });
  const screenWithMobileHeaderOptions = useStackHeader({
    mobileHeaderShown: true,
  });

  // ─────────────────────────────────────────────────────────────────────

  return useMemo(
    () => ({
      Navigator: navigatorScreenOptions,
      GiftCards: {
        ...DEFAULT_SCREEN_OPTIONS,
        title: formatMessage(giftCardsStackScreenMessages.screenTitle),
        headerTitle: formatMessage(giftCardsStackScreenMessages.screenTitle),
        customDocumentTitle: formatMessage(
          giftCardsStackScreenMessages.screenCustomDocumentTitle,
        ),
      },
      GiftCardCheckout: {
        ...DEFAULT_SCREEN_OPTIONS,
        header: screenWithMobileHeaderOptions,
        headerBackVisible: false, // we render custom back button instead
        title: formatMessage(giftCardCheckoutScreenMessages.screenTitle),
        customDocumentTitle: formatMessage(
          giftCardCheckoutScreenMessages.screenCustomDocumentTitle,
        ),
      },
      GiftCardConfirmation: {
        ...DEFAULT_SCREEN_OPTIONS,
        header: screenWithMobileHeaderOptions,
        title: formatMessage(giftCardConfirmationScreenMessages.screenTitle),
        customDocumentTitle: formatMessage(
          giftCardConfirmationScreenMessages.screenCustomDocumentTitle,
        ),
      },
      GiftCardRedemption: {
        ...DEFAULT_SCREEN_OPTIONS,
        title: formatMessage(giftCardRedemptionScreenMessages.screenTitle),
        customDocumentTitle: formatMessage(
          giftCardRedemptionScreenMessages.screenCustomDocumentTitle,
        ),
      },
    }),
    [formatMessage, navigatorScreenOptions, screenWithMobileHeaderOptions],
  );
};

// Screen options for the loyalty tab stack.
export const useAccountTabStackOptions = () => {
  const { t } = useLocalizationContext();
  const navigatorScreenOptions = useTabStackNavigatorScreenOptions({
    mobileHeaderShown: true,
  });

  // ─── Flags ───────────────────────────────────────────────────────────

  const isInAppGiftCardEnabled = useFeatureFlag(
    'CELS-1449-in-app-gift-cards-enabled',
  );

  // ─────────────────────────────────────────────────────────────────────

  return useMemo(
    () => ({
      Navigator: {
        ...navigatorScreenOptions,
      },
      AccountMenu: {
        ...DEFAULT_SCREEN_OPTIONS,
        customDocumentTitle: t('page-title.account.menu'),
      },
      Profile: {
        ...DEFAULT_SCREEN_OPTIONS,
        title: t('account.profile.title'),
        customDocumentTitle: t('page-title.account.info'),
      },
      SweetpassMembership: {
        ...DEFAULT_SCREEN_OPTIONS,
        title: t('account.sweetpass-membership.title'),
        customDocumentTitle: t('page-title.account.sweetpass-membership'),
      },
      CreditAndPromoCodes: {
        ...DEFAULT_SCREEN_OPTIONS,
        title: t('account.credit-and-promo-codes.title'),
        customDocumentTitle: t('page-title.account.credit-and-promo-codes'),
      },
      PaymentAndGiftCards: {
        ...DEFAULT_SCREEN_OPTIONS,
        title: isInAppGiftCardEnabled
          ? t('account.payments.title')
          : t('account.payment-and-gift-cards.title'),
        customDocumentTitle: isInAppGiftCardEnabled
          ? t('page-title.account.payments')
          : t('page-title.account.payment-and-gift-cards'),
      },
      Addresses: {
        ...DEFAULT_SCREEN_OPTIONS,
        title: t('account.addresses.title'),
        customDocumentTitle: t('page-title.account.addresses'),
      },
      Orders: {
        ...DEFAULT_SCREEN_OPTIONS,
        title: t('account.orders.title'),
        customDocumentTitle: t('page-title.account.orders'),
      },
      Favorites: {
        ...DEFAULT_SCREEN_OPTIONS,
        title: t('account.favorites.title'),
        customDocumentTitle: t('page-title.account.favorites'),
      },
      ReferFriend: {
        ...DEFAULT_SCREEN_OPTIONS,
        title: t('account.refer-friend.title'),
        customDocumentTitle: t('page-title.account.refer-friend'),
      },
      AccountDietaryRestrictions: {
        ...DEFAULT_SCREEN_OPTIONS,
        title: t('account.dietary-restrictions.title'),
        customDocumentTitle: t('page-title.account.dietary-restrictions'),
      },
    }),
    [navigatorScreenOptions, t, isInAppGiftCardEnabled],
  );
};

// Screen options for the modal stack.
export const useModalStackOptions = ({ navigation }: ModalStackScreenProps) => {
  const { t } = useLocalizationContext();
  const { formatMessage } = useIntl();

  const navigatorScreenOptions = useModalStackNavigatorScreenOptions({
    allowsTransparentModals: true,
    close: { onPress: navigation.goBack },
  });

  const defaultScreenOptions = useModalStackScreenOptions();

  const visibleHeader = useStackHeader({
    ...defaultScreenOptions,
    mobileHeaderShown: true,
  });

  const screenOptions = useMemo<NativeStackNavigationOptions>(
    () => ({ ...defaultScreenOptions, header: visibleHeader }),
    [defaultScreenOptions, visibleHeader],
  );

  const modalOptions = useMobileTransparentModalStackScreenOptions({
    ...defaultScreenOptions,
    mobileHeaderShown: true,
  });

  const bagScreenOptions = useMemo(
    () => ({
      ...defaultScreenOptions,
      ...modalOptions,
      headerShown: true,
    }),
    [defaultScreenOptions, modalOptions],
  );

  // Other than the navigator/default, all should either be:
  // - screenOptions - for when the screen is a screen.
  // - modalOptions - for when the screen is a modal.
  // - some edge case like the bag.
  return useMemo(
    () => ({
      Navigator: { ...navigatorScreenOptions },

      // Ordering flow is all visible headers.
      ProductDetails: { ...screenOptions },
      EditProductDetails: { ...screenOptions },
      DeliveryProductDetails: { ...screenOptions },
      DeliveryEditProductDetails: { ...screenOptions },
      Checkout: {
        ...screenOptions,
        customDocumentTitle: t('page-title.checkout'),
      },
      OrderStatus: {
        ...screenOptions,
        headerBackVisible: false,
        headerLeft: () => null,
        customDocumentTitle: t('page-title.order-status'),
      },
      ShareLineItem: { ...screenOptions },

      // Bag is shown as a drawer on desktop.
      Bag: {
        ...bagScreenOptions,
        customDocumentTitle: t('page-title.cart'),
      },

      ReorderScreen: {
        ...modalOptions,
        headerShown: false,
      },

      // Reorder flow shows as a transparent modal in desktop.
      ReorderConfirmLocation: { ...modalOptions },
      ReorderChangeLocation: { ...modalOptions },
      ReorderConflictReview: { ...modalOptions },
      ReorderActiveBagWarning: { ...modalOptions },
      RateOrder: {
        ...modalOptions,
        headerBackVisible: false,
        headerLeft: () => null,
        title: t('rate-order.title'),
      },

      // These are transparent forms used in desktop as well.
      DietaryRestrictions: {
        ...modalOptions,
        headerBackVisible: false,
        headerLeft: () => null,
      },

      ScanInStore: {
        ...modalOptions,
        headerLeft: () => null,
        headerShown: true,
        headerTitle: t('scan-in-store.title'),
        title: t('scan-in-store.title'),
      },

      CreditAdd: {
        ...screenOptions,
        title: t('account.scan-to-pay.enter-promo-code'),
      },

      // These are for the mobile account, aren't used in desktop.
      CreditDetails: {
        ...screenOptions,
        title: t('account.credit.mobile-label'),
      },
      PaymentAndGiftCards: {
        ...screenOptions,
        title: t('account.payment-and-gift-cards.title'),
      },

      // OneTrust Personal Data Management
      PersonalData: {
        ...screenOptions,
        title: t('personal-data.title'),
      },
      PersonalDataDetails: { ...modalOptions },

      DeliveryOrderInFlight: { ...modalOptions },

      AddGiftCard: {
        ...screenOptions,
        title: t('account.gift-cards.add-gift-card'),
      },
      RedeemGiftCard: {
        ...screenOptions,
        title: formatMessage(redeemGiftCardScreenMessages.screenTitle),
      },

      // Sweetpass
      SweetpassBenefits: {
        ...screenOptions,
        customDocumentTitle: t('page-title.sweetpass-benefits'),
      },
      SweetpassUpgrade: {
        ...screenOptions,
        headerStyle: {
          backgroundColor: theme.colors.GREEN_5,
        },
        title: t('sweetpass-plus'),
      },
      SweetpassCheckout: {
        ...screenOptions,
        title: t('sweetpass-plus'),
      },
      SweetpassSwitchSubscription: {
        ...screenOptions,
        title: t('sweetpass-change-plan'),
      },

      // OrderProduct - Deep linking to order a product.
      OrderProductConfirmProduct: {
        ...modalOptions,
        headerBackVisible: false,
        headerLeft: () => null,
      },
      OrderProductConfirmLocation: { ...modalOptions },
      OrderProductSearchLocation: { ...modalOptions },
    }),
    [
      t,
      formatMessage,
      bagScreenOptions,
      navigatorScreenOptions,
      screenOptions,
      modalOptions,
    ],
  );
};

// Screen options for the auth stack.
export const useAuthStackOptions = ({ navigation }: AuthStackScreenProps) => {
  const { t } = useLocalizationContext();

  const navigatorScreenOptions = useModalStackNavigatorScreenOptions({
    mobileHeaderShown: true,
    close: { onPress: navigation.goBack },
  });

  return useMemo(
    () => ({
      Navigator: { ...navigatorScreenOptions },
      JoinOrSignIn: {
        ...DEFAULT_SCREEN_OPTIONS,
        customDocumentTitle: t('page-title.join-or-sign-in'),
      },
      Login: {
        ...DEFAULT_SCREEN_OPTIONS,
        customDocumentTitle: t('page-title.sign-in'),
      },
      Join: {
        ...DEFAULT_SCREEN_OPTIONS,
        customDocumentTitle: t('page-title.join'),
      },
    }),
    [navigatorScreenOptions, t],
  );
};

// Screen options for error stack.
export const useErrorStackOptions = () => {
  const navigatorScreenOptions = useTabStackNavigatorScreenOptions({
    mobileHeaderShown: false,
  });

  return useMemo(
    () => ({
      Navigator: {
        ...navigatorScreenOptions,
      },
      NotFound: {
        ...DEFAULT_SCREEN_OPTIONS,
      },
    }),
    [navigatorScreenOptions],
  );
};

// ———————— STACK HEADER ——————————————————————

type NativeStackHeaderProp = NativeStackNavigationOptions['header'];

/**
 * Returns a function which may be passed to the `header` property
 * of a stack navigator's screen options.
 *
 * @param {HeaderContentProps} props - additional header content props
 */
const useStackHeader = (props?: UseStackHeaderProps): NativeStackHeaderProp => {
  const { currentBreakpoint } = useResponsive();
  const { mobileHeaderShown, ...headerContentProps } = props ?? {};

  if (currentBreakpoint.isXS && mobileHeaderShown) {
    return undefined;
  }

  if (currentBreakpoint.isXS && !mobileHeaderShown) {
    return () => <View />;
  }

  return (headerProps: NativeStackHeaderProps) => (
    <DesktopHeader {...headerProps} {...headerContentProps} />
  );
};

type UseStackHeaderProps = Readonly<{
  mobileHeaderShown?: boolean;
}> &
  HeaderContentProps;

// ———————— CONTENT STYLE ——————————————————————

type ContentStyleOption = NativeStackNavigationOptions['contentStyle'];

const DEFAULT_CONTENT_STYLE: ContentStyleOption = {
  backgroundColor: theme.colors.APP_BACKGROUND,
};

const TRANSPARENT_CONTENT_STYLE: ContentStyleOption = {
  backgroundColor: theme.colors.OPACITY.TRANSPARENT,
};

/**
 * The value of a `contentStyle` property for screens
 * which appear as "transparent modals" in the desktop form factor.
 */
const useMobileTransparentScreenContentStyle = (): ContentStyleOption => {
  const { currentBreakpoint } = useResponsive();

  return currentBreakpoint.isXS
    ? DEFAULT_CONTENT_STYLE
    : TRANSPARENT_CONTENT_STYLE;
};

type PresentationOption = NativeStackNavigationOptions['presentation'];

/**
 * The value of a 'presentation` property for screens
 * which appear as "transparent modals" on web.
 */
const useMobileTransparentModalScreenPresentation = (): PresentationOption => {
  const { currentBreakpoint } = useResponsive();

  return currentBreakpoint.isXS ? 'modal' : 'transparentModal';
};

const DEFAULT_HEADER_TITLE_STYLE = TYPE_CONFIG.BODY[
  '18'
] as NativeStackNavigationOptions['headerTitleStyle'];

// ———————— SCREEN OPTIONS ——————————————————————

/**
 * Default value of `screenOptions` for all screens.
 */
const DEFAULT_SCREEN_OPTIONS = {
  title: 'sweetgreen',
  headerBackImageSource: getCustomBackButtonIcon(),
};

/**
 * Default value of `screenOptions` for a native stack navigator.
 */
const DEFAULT_STACK_SCREEN_OPTIONS: NativeStackNavigationOptions = {
  ...DEFAULT_SCREEN_OPTIONS,
  headerShadowVisible: false,
  headerTintColor: theme.colors.GREEN_1,
  headerStyle: {
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
  headerTitleStyle: DEFAULT_HEADER_TITLE_STYLE,
  headerTitleAlign: 'center',
  headerBackTitleVisible: false,
  headerTitle: '',
  contentStyle: DEFAULT_CONTENT_STYLE,
};

const DEFAULT_TABS_OPTIONS: BottomTabNavigationOptions = {
  headerShown: false,
};

/**
 * The value of `screenOptions` for a native stack navigator contained in a tab.
 */
const useTabStackNavigatorScreenOptions = (
  props?: UseStackHeaderProps,
): NativeStackNavigationOptions => {
  const header = useStackHeader(props);

  return {
    ...DEFAULT_STACK_SCREEN_OPTIONS,
    headerShown: true,
    header,
  };
};

/**
 * The value of `screenOptions` for a native stack navigator contained in a modally presented screen.
 *
 * @param {Object} options - additional modal stack options
 * @param {HeaderCloseIconItemProps} options.close - Specifies the close button `onPress` handler, accessibility strings, and `testID`.
 * @param {boolean} options.allowsTransparentModals - If true, configures the contentStyle property to have a transparent background.
 */
const useModalStackNavigatorScreenOptions = ({
  close,
  allowsTransparentModals,
  ...stackHeaderProps
}: UseModalStackNavigatorScreenOptionsProps): NativeStackNavigationOptions => {
  const { currentBreakpoint } = useResponsive();
  const header = useStackHeader(stackHeaderProps);
  const headerRight = useHeaderRight({ close });
  const contentStyle =
    allowsTransparentModals && !currentBreakpoint.isXS
      ? TRANSPARENT_CONTENT_STYLE
      : DEFAULT_CONTENT_STYLE;
  const options: NativeStackNavigationOptions = {
    ...DEFAULT_STACK_SCREEN_OPTIONS,
    headerShown: true,
    header,
    headerRight,
    contentStyle,
  };

  return options;
};

type UseModalStackNavigatorScreenOptionsProps = Readonly<{
  close?: HeaderCloseIconItemProps;
  allowsTransparentModals?: boolean;
}> &
  UseStackHeaderProps;

/**
 * The value of `screenOptions` native stack navigator contained in a modally presented screen.
 */
const useModalStackScreenOptions = (): NativeStackNavigationOptions => {
  return {
    ...DEFAULT_SCREEN_OPTIONS,
    contentStyle: DEFAULT_CONTENT_STYLE,
  };
};

/**
 * The value of `options` for a stack screen presented as a "transparent modal" on web.
 */
const useMobileTransparentModalStackScreenOptions = (
  props?: UseStackHeaderProps,
): NativeStackNavigationOptions => {
  const { currentBreakpoint } = useResponsive();
  const header = useStackHeader(props);
  const contentStyle = useMobileTransparentScreenContentStyle();
  const options: NativeStackNavigationOptions = {
    ...DEFAULT_SCREEN_OPTIONS,
    presentation: currentBreakpoint.isXS ? 'card' : 'transparentModal',
    headerShown: currentBreakpoint.isXS,
    header,
    contentStyle,
  };

  return options;
};
