import React from 'react';

import { LoadingAnimation } from '@order/components';
import { useDeliveryOrderInFlight } from '@order/hooks';
import { useFeatureFlag } from '@order/LaunchDarkly';

import { HomeScreenContent, HomeScreenContentV2 } from './components';
import {
  useHomeData,
  useHomeHeroCTA,
  useHomeScreenLoadedContent,
} from './hooks';

export const HomeScreen = () => {
  const isHomeRedesignEnabled = useFeatureFlag(
    'cels-2385-short-home-screen-redesign-enabled',
    { listenForChanges: true },
  );

  if (isHomeRedesignEnabled) {
    return <HomeScreenContentV2 />;
  }

  return <HomeScreenV1 />;
};

const HomeScreenV1 = () => {
  const loadedContent = useHomeScreenLoadedContent();

  const data = useHomeData();

  useDeliveryOrderInFlight(); // results later used from cache.

  const handlePressHeroCTA = useHomeHeroCTA(loadedContent.heroCTA?.action);

  if (data.isLoading) return <LoadingAnimation />;

  return (
    <HomeScreenContent
      onPressHeroCTA={handlePressHeroCTA}
      loadedContent={loadedContent}
      {...data}
    />
  );
};
