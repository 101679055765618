import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigation } from '@react-navigation/native';
import { FadeView } from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import { FloatingButtons } from '@order/components';
import { Home } from '@order/features/home';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useTelemetry } from '@order/Telemetry';

import {
  type useHomeContent,
  useHomeNavigationWithTelemetry,
} from '../../hooks';
import { HomeScreenBanner } from '../HomeScreenBanner';

export const HomeScreenContentV2XS = (props: HomeScreenContentProps) => {
  const { homeContent, bagPressed, ctaPressed } = props;

  const isLoggedIn = useIsLoggedIn();
  const navigation = useNavigation();

  const isReorderModalEnabled = useFeatureFlag(
    'CELS-2534-reorder-modal-enabled',
    { listenForChanges: true },
  );
  const { track } = useTelemetry();

  const { navigateToAccount } = useHomeNavigationWithTelemetry();

  // ─── Flags ───────────────────────────────────────────────────────────

  const shouldRenderReorderButton = isReorderModalEnabled && isLoggedIn;

  const isInAppGiftCardEnabled = useFeatureFlag(
    'CELS-1449-in-app-gift-cards-enabled',
  );

  const isNavigationRedesignEnabled = useFeatureFlag(
    'CELS-2678-navigation-redesign-enabled',
    { listenForChanges: true },
  );

  // Account button shouldn't be rendered if there is an account button on the navbar.
  const shouldRenderAccountButton =
    isInAppGiftCardEnabled || isNavigationRedesignEnabled;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onReorderPress = useCallback(() => {
    navigation.navigate('Modal', { screen: 'Reorder' });

    track('home.reorder.cta_tapped');
  }, [navigation, track]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <>
      <Home.ContainerXS>
        <Home.HeroImageXS
          imageUrl={homeContent.mainImageXS}
          imageA11yLabel={homeContent.mainImageA11yXS}
        >
          <Home.ContentWrapperXS>
            <Home.HeaderXS
              onPressAccount={
                shouldRenderAccountButton ? navigateToAccount : undefined
              }
            >
              <Home.EyebrowText
                text={homeContent.eyebrowText}
                fontColor={homeContent.eyebrowTextColorXS}
              />

              <Home.HeroText
                text={homeContent.heroText}
                fontColor={homeContent.heroTextColorXS}
              />

              <Home.HeaderCta
                accessibilityLabel={homeContent.ctaText}
                deepLink={homeContent.ctaDeepLink}
                onPressCta={ctaPressed}
              >
                {homeContent.ctaText}
              </Home.HeaderCta>
            </Home.HeaderXS>
          </Home.ContentWrapperXS>
        </Home.HeroImageXS>
      </Home.ContainerXS>

      <FloatingButtons.Container anchor="full">
        <Home.FloatingButtons>
          <FadeView show={shouldRenderReorderButton} keepMounted>
            <FloatingButtons.Default
              palette="oatmeal"
              size="large"
              onPress={onReorderPress}
            >
              <FormattedMessage
                defaultMessage="Reorder"
                description="Home screen | Reorder button | Label"
              />
            </FloatingButtons.Default>
          </FadeView>

          <FloatingButtons.Bag onPress={bagPressed} />
        </Home.FloatingButtons>

        <HomeScreenBanner />
      </FloatingButtons.Container>
    </>
  );
};

type HomeScreenContentProps = {
  homeContent: ReturnType<typeof useHomeContent>['homeContent'];
  ctaPressed: () => void;
  bagPressed: () => void;
};
