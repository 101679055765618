import { useCallback } from 'react';
import { addMinutes, formatISO } from 'date-fns';
import { useClient } from 'urql';

import { ignoreTimezone } from '@order/utils';

import {
  BagTimesPollingDocument,
  type BagTimesPollingQuery,
} from '../../GraphQL/BagTimesPolling.generated';

// ────────────────────────────────────────────────────────────────────────────

/**
 * A hook for fetching wanted times for use in machine polling.
 */
export const useFetchWantedTimes = () => {
  const client = useClient();

  // ─── Queries ──────────────────────────────────────────────────────────────

  const fetchWantedTimes = useCallback(async () => {
    const queryRewards = client.query<BagTimesPollingQuery>;

    const response = await queryRewards(
      BagTimesPollingDocument,
      {},
      { requestPolicy: 'network-only' },
    ).toPromise();

    const availableTimes = response.data?.cart?.availableWantedTimes ?? [];

    const wantedTimes = (availableTimes ?? [])
      .map(mapTime)
      .filter(Boolean) as string[];

    return { wantedTimes };
  }, [client.query]);

  return { fetchWantedTimes };
};

// ─── Helpers ────────────────────────────────────────────────────────────────

const mapTime = ({ time, deliveryOffset }: WantedTime) => {
  const validTime = ignoreTimezone(time);

  if (!validTime) return undefined;

  return formatISO(addMinutes(validTime, deliveryOffset));
};

// ─── Types ──────────────────────────────────────────────────────────────────

type WantedTime = {
  time: string;
  deliveryOffset: number;
};
