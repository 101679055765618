import React from 'react';
import { LoadingDots, Modal, useResponsive } from '@sg/garnish';

import { Loyalty } from '@order/features/loyalty';
import { useLoyaltyContent } from '@order/hooks';

import { useLoyaltyPointHistory } from '../../hooks';

export const LoyaltyPointHistoryModal = (
  props: LoyaltyPointHistoryModalProps,
) => {
  const { availablePoints, visible, startOrder, onRequestClose } = props;
  const { minWidth } = useResponsive();

  // ─── Query ────────────────────────────────────────────────────────────────

  const {
    pointHistory,
    canLoadMorePointHistory,
    isLoadingPointHistory,
    handleLoadMorePointHistory,
  } = useLoyaltyPointHistory();

  const hasPointsHistory = pointHistory.length > 0;

  // ─── Faq ──────────────────────────────────────────────────────────────────

  const { faqPointHistory } = useLoyaltyContent();

  // ──────────────────────────────────────────────────────────────────────────

  return (
    <Modal
      maxHeight="80%"
      fitHeight={minWidth.isSM}
      visible={visible}
      onRequestClose={onRequestClose}
    >
      <Loyalty.PointsHistory.Container onClose={onRequestClose}>
        <Loyalty.PointsHistory.Header availablePoints={availablePoints} />

        {isLoadingPointHistory && !hasPointsHistory ? <LoadingDots /> : null}

        {!isLoadingPointHistory && !hasPointsHistory ? (
          <Loyalty.PointsHistory.EmptyState onPress={startOrder} />
        ) : null}

        {pointHistory.map((pointHistoryEntry) => (
          <Loyalty.PointsHistory.Row
            key={`${pointHistoryEntry.value}-${pointHistoryEntry.date}`}
            {...pointHistoryEntry}
          />
        ))}

        {canLoadMorePointHistory && hasPointsHistory ? (
          <Loyalty.PointsHistory.SeeMoreCta
            isLoading={isLoadingPointHistory}
            onPress={handleLoadMorePointHistory}
          />
        ) : null}

        {faqPointHistory.length > 0 ? (
          <Loyalty.FAQ entries={faqPointHistory} />
        ) : null}
      </Loyalty.PointsHistory.Container>
    </Modal>
  );
};

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyPointHistoryModalProps = {
  availablePoints: number;
  visible: boolean;
  startOrder: () => void;
  onRequestClose: () => void;
};
