import {
  LoyaltyBag,
  LoyaltyChallenge,
  LoyaltyFAQ,
  LoyaltyHome,
  LoyaltyHowItWorks,
  LoyaltyIconSgReward,
  LoyaltyPointsHistory,
  LoyaltyReward,
  LoyaltyRewardTerms,
} from './components';

export const Loyalty = {
  Bag: LoyaltyBag,
  Home: LoyaltyHome,
  FAQ: LoyaltyFAQ,
  HowItWorks: LoyaltyHowItWorks,
  PointsHistory: LoyaltyPointsHistory,
  IconSgReward: LoyaltyIconSgReward,
  Challenge: LoyaltyChallenge,
  Reward: LoyaltyReward,
  RewardTerms: LoyaltyRewardTerms,
};
