import React from 'react';

import { ProductCard } from '@order/components';
import { useDietaryRestrictions } from '@order/components';
import type { PartialOrderConflict } from '@order/graphql';
import { ConflictType } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

export const ConflictCard = ({ conflict }: ConflictCardProps) => {
  const { t, formatPrice, formatNumber } = useLocalizationContext();
  const getRestrictionNotice = useDietaryRestrictions();

  const unavailableNotice = [
    ConflictType.DeliveryIneligible,
    ConflictType.ProductUnavailable,
  ].includes(conflict.type)
    ? t('reorder.conflicts.product-unavailable')
    : t('reorder.conflicts.ingredients-unavailable', {
        ingredients: conflict.unavailableIngredients
          .map((ingredient) => ingredient.name)
          .join(', '),
      });

  return (
    <ProductCard
      key={conflict.product.id}
      restrictionNotice={getRestrictionNotice(
        conflict.product.dietaryProperties,
      )}
      unavailableNotice={
        conflict.type === ConflictType.NoConflict
          ? undefined
          : unavailableNotice
      }
      name={conflict.product.name ?? ''}
      description={conflict.product.description}
      label={conflict.product.label?.name}
      price={formatPrice(conflict.product.cost ?? 0, 'USD')}
      calories={`${formatNumber(conflict.product.calories ?? 0)} cal`}
      cloudinaryImageSrc={conflict.product.asset?.url ?? ''}
    />
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type ConflictCardProps = Readonly<{ conflict: PartialOrderConflict }>;
