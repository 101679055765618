import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import {
  BodyText,
  CloudinaryImage,
  type CloudinaryTransformConfig,
  theme,
} from '@sg/garnish';

import type {
  DeliveryOrderDetail,
  DeliveryOrderDetailInput,
  Product,
} from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';

import { UpsellProductBagIconLink } from './components';
import { upsellProductMessages } from './UpsellProduct.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const UpsellProduct = (props: UpsellProductProps) => {
  const { product, deliveryOrderDetail, isAddingUpsell, addUpsellToBag } =
    props;

  const { formatPrice } = useLocalizationContext();
  const { track } = useTelemetry();
  const { formatMessage } = useIntl();

  // ─────────────────────────────────────────────────────────────────────

  const oneClickAddToBagBtnAccessibilityLabel = formatMessage(
    upsellProductMessages.oneClickA11yLabel,
    { product_name: product.name },
  );
  const formattedPrice = formatPrice(
    product.channelCost ?? product.cost,
    'USD',
  );

  // ─── Helpers ─────────────────────────────────────────────────────────

  const deliveryDetails = useMemo<DeliveryOrderDetailInput | undefined>(() => {
    if (!deliveryOrderDetail) return;

    return {
      ...deliveryOrderDetail,
      addressId: deliveryOrderDetail.address?.id ?? '',
    };
  }, [deliveryOrderDetail]);

  const addToBag = useCallback(async () => {
    track('category_upsells.one_click_add_to_bag');
    addUpsellToBag({ product, deliveryDetails });
  }, [deliveryDetails, product, track, addUpsellToBag]);

  // ─────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container} testID="upsell-product.container">
      <CloudinaryImage
        baseUrl={product.imageUrl}
        config={CLOUDINARY_IMG_CONFIG}
        contentFit="contain"
        style={styles.image}
        aria-label={product.name}
      />

      <View style={styles.textContainer}>
        <BodyText bold sizeMatch={['14']} numberOfLines={2}>
          {product.name}
        </BodyText>

        <BodyText sizeMatch={['14']}>{formattedPrice}</BodyText>
      </View>

      <View style={styles.oneClickAddWrapper}>
        <UpsellProductBagIconLink
          aria-label={oneClickAddToBagBtnAccessibilityLabel}
          isLoading={isAddingUpsell}
          isDisabled={product.outOfStock}
          onPress={addToBag}
        />
      </View>
    </View>
  );
};

// ─── Constants ──────────────────────────────────────────────────────────────

const CARD_IMG_SIZE = {
  width: 80,
  height: 60,
};

const CLOUDINARY_IMG_CONFIG: CloudinaryTransformConfig = [
  { crop: 'crop', width: 0.5, height: 0.7 },
  { width: CARD_IMG_SIZE.width },
];

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  image: {
    width: CARD_IMG_SIZE.width,
    height: CARD_IMG_SIZE.height,
  },
  textContainer: {
    flex: 1,
    marginHorizontal: theme.spacing['4'],
  },
  oneClickAddWrapper: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginRight: theme.spacing['4'],
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type UpsellProductProps = {
  product: ProductUpsell;
  deliveryOrderDetail?: DeliveryOrderDetail;
  isAddingUpsell: boolean;
  addUpsellToBag: (payload: {
    product: Partial<Product>;
    deliveryDetails?: DeliveryOrderDetailInput;
  }) => void;
};

type ProductUpsell = {
  id: string;
  name: string;
  cost: number;
  channelCost?: number;
  imageUrl: string;
  outOfStock: boolean;
};
