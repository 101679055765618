import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { BodyText, DisplayText, useResponsive } from '@sg/garnish';

export const LoyaltyHomeHeader = (props: LoyaltyHomeHeaderProps) => {
  const { firstName, availablePoints } = props;
  const { match } = useResponsive();

  return (
    <View style={match([styles.containerXs, styles.containerSm])}>
      <BodyText sizeMatch={['18', '40']}>
        <FormattedMessage {...messages.title} values={{ name: firstName }} />
      </BodyText>

      <DisplayText sizeMatch={['32', '80']}>
        <FormattedMessage
          {...messages.subtitle}
          values={{ points: availablePoints }}
        />
      </DisplayText>
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  title: {
    defaultMessage: 'Hi {name}, you have',
    description: 'Loyalty Home | Header | Title',
  },
  subtitle: {
    defaultMessage: '{points} points',
    description: 'Loyalty Home | Header | Subtitle',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerXs: {
    gap: theme.spacing['2'],
    padding: theme.spacing['4'],
  },
  containerSm: {
    gap: theme.spacing['6'],
    padding: theme.spacing['16'],
    alignItems: 'center',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyHomeHeaderProps = {
  firstName: string;
  availablePoints: number;
};
