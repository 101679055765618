import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  ANIMATED_DIALOG_ITEM_PADDING_STYLE,
  Button,
  DisplayText,
  Form,
  LabelText,
  theme,
  TYPE_CONFIG,
} from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * The gift card form for the bag.
 */
export const BagGiftCardForm = (props: BagGiftCardFormProps) => {
  const { isLoading, onSubmit } = props;

  const { formatMessage } = useIntl();

  // ─── Form & Validation ────────────────────────────────────────────────────

  const validationSchema = useMemo(() => {
    const giftCardCodeValidation = yup
      .string()
      .required(formatMessage(messages.requiredGiftCardNumber));

    const registrationCodeValidation = yup
      .string()
      .required(formatMessage(messages.requiredRegistrationCode));

    return yup
      .object({
        giftCardCode: giftCardCodeValidation,
        registrationCode: registrationCodeValidation,
      })
      .required();
  }, [formatMessage]);

  const form = useForm<BagGiftCardFormData>({
    defaultValues: { giftCardCode: '', registrationCode: '' },
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  const { control, formState } = form;
  const { dirtyFields } = formState;
  const shouldDisableApplyCta = !(
    dirtyFields.giftCardCode && dirtyFields.registrationCode
  );

  // ──────────────────────────────────────────────────────────────────────────

  const submitForm = useCallback(() => {
    const { giftCardCode, registrationCode } = form.getValues();

    onSubmit(giftCardCode, registrationCode);
  }, [form, onSubmit]);

  return (
    <View style={styles.container}>
      <DisplayText style={styles.title} sizeMatch={['32']}>
        <FormattedMessage
          defaultMessage="Redeem gift card"
          description="Bag | Gift card form | Title"
        />
      </DisplayText>

      <View style={styles.content}>
        <View style={styles.fieldContainer}>
          <LabelText sizeMatch={['12']}>
            {formatMessage(messages.giftCardNumber)}
          </LabelText>

          <Form.TextFieldWithBackground
            name="giftCardCode"
            hideSubmitButton
            hideNoticeSpace
            style={styles.field}
            control={control}
            rules={{ required: true }}
            placeholder={formatMessage(messages.giftCardNumberPlaceholder)}
            accessibilityLabel={formatMessage(messages.giftCardNumber)}
            disabled={isLoading}
            onSubmit={submitForm}
          />
        </View>

        <View style={styles.fieldContainer}>
          <LabelText sizeMatch={['12']}>
            {formatMessage(messages.registrationCode)}
          </LabelText>

          <Form.TextFieldWithBackground
            name="registrationCode"
            hideSubmitButton
            hideNoticeSpace
            style={styles.field}
            control={control}
            rules={{ required: true }}
            placeholder={formatMessage(messages.registrationCodePlaceholder)}
            accessibilityLabel={formatMessage(messages.registrationCode)}
            disabled={isLoading}
            onSubmit={submitForm}
          />
        </View>
      </View>

      <Button
        size="large-wide"
        style={styles.cta}
        disabled={shouldDisableApplyCta}
        isLoading={isLoading}
        accessibilityLabel={formatMessage(messages.applyGiftCardCtaA11y)}
        onPress={submitForm}
      >
        <FormattedMessage {...messages.submitButtonLabel} />
      </Button>
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  giftCardNumber: {
    defaultMessage: 'Gift card number',
    description: 'Bag | Gift card | Gift card number',
  },
  registrationCode: {
    defaultMessage: 'Registration code',
    description: 'Bag | Gift card | Registration code',
  },
  giftCardNumberPlaceholder: {
    defaultMessage: 'Enter number',
    description: 'Bag | Gift card | Enter number',
  },
  registrationCodePlaceholder: {
    defaultMessage: 'Enter code',
    description: 'Bag | Gift card | Enter code',
  },
  applyGiftCardCtaA11y: {
    defaultMessage: 'Apply gift card',
    description: 'Bag | Gift card | CTA a11y',
  },
  submitButtonLabel: {
    defaultMessage: 'Redeem',
    description: 'Redeem Gift Card Form | Form submit button label',
  },
  requiredGiftCardNumber: {
    defaultMessage: 'Gift card number is required',
    description: 'Bag | Gift card | Required gift card number',
  },
  requiredRegistrationCode: {
    defaultMessage: 'Registration code is required',
    description: 'Bag | Gift card | Required gift card reg code',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    ...ANIMATED_DIALOG_ITEM_PADDING_STYLE,
    gap: theme.spacing['6'],
  },
  content: {
    gap: theme.spacing['4'],
  },
  title: {
    marginBottom: theme.spacing['2'],
  },
  fieldContainer: {
    flexGrow: 1,
    gap: theme.spacing['1'],
  },
  field: {
    ...TYPE_CONFIG.BODY['18'],
  },
  cta: {
    alignSelf: 'center',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagGiftCardFormProps = Readonly<{
  isLoading: boolean;
  onSubmit: (code: string, regCode: string) => void;
}>;

type BagGiftCardFormData = Readonly<{
  giftCardCode: string;
  registrationCode: string;
}>;
