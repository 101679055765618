import React from 'react';
import type { AccessibilityProps } from 'react-native';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';
import type { ImageTransform } from 'cloudinary-tiny-js';

import { IllusEmpty_2 } from '../../../assets';
import { useResponsive } from '../../../hooks';
import { Card } from '../../Card';
import { FallbackImage } from '../../Image';

// ────────────────────────────────────────────────────────────────────────────────

export const LineItemImage = (props: ProductImageProps) => {
  const { cloudinaryImageUrl, size, accessibilityLabel, isDisabled, onPress } =
    props;

  const { match } = useResponsive();

  const isPressable = Boolean(!isDisabled && onPress);
  const cloudinaryConfig =
    size === 'large' ? CLOUDINARY_CONFIG_LARGE : CLOUDINARY_CONFIG_REGULAR;

  return (
    <Card
      onPress={isPressable ? onPress : undefined}
      accessibilityLabel={isPressable ? accessibilityLabel : undefined}
      accessibilityRole={isPressable ? 'imagebutton' : undefined}
      backgroundColor={theme.colors.OATMEAL}
    >
      <FallbackImage
        baseUrl={cloudinaryImageUrl}
        defaultImage={IllusEmpty_2}
        contentFit="contain"
        style={match([styles.imgXS, styles.imgSM])}
        cloudinaryConfig={cloudinaryConfig}
      />
    </Card>
  );
};

// ─── CONSTANTS ──────────────────────────────────────────────────────────────────

const CLOUDINARY_CONFIG_REGULAR: readonly ImageTransform[] = [
  { crop: 'crop', height: 0.57, width: 0.5, x: 0.25, y: 0.25 },
  { width: 120 },
];

const CLOUDINARY_CONFIG_LARGE: readonly ImageTransform[] = [
  { crop: 'crop', height: 0.6, width: 0.4 },
  { width: 120 },
];

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  imgXS: {
    width: 100,
    height: 75,
  },
  imgSM: {
    width: 120,
    height: 90,
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type ProductImageProps = Readonly<{
  cloudinaryImageUrl: string;
  size: 'regular' | 'large';
  isDisabled?: boolean;
  onPress?: () => void;
}> &
  Pick<AccessibilityProps, 'accessibilityLabel'>;
