/* eslint-disable functional/immutable-data */

import React, { useCallback, useEffect } from 'react';
import { type ModalProps as RNModalProps } from 'react-native';
import { Modal as RNModal } from 'react-native';

import { useResponsive } from '../../hooks';
import * as ModalSubComponents from './components';
import { useModalMachineIntegration } from './hooks';
import { useModalContext } from './Modal.provider';
import type { BaseModalProps, PaletteProps } from './Modal.types';

export const Modal = (props: ModalProps) => {
  const {
    id,
    visible,
    palette,
    children,
    animationType: animationTypeProp,
    offset,
    size = 'small',
    fitHeight = false,
    maxWidth,
    maxHeight,
    withSafeAreaView,
    onRequestClose,
    onShowing,
    onShown,
    onDismissing,
    onDismissed,
    ...modalProps
  } = props;

  const modalContext = useModalContext();
  const shouldRenderBackdrop = !modalProps.transparent;

  // ─── Animation Type ──────────────────────────────────────────────────
  // For small sliding modals, we use fade as we manually animate the slide.

  const { match } = useResponsive();

  const fallbackAnimationType = animationTypeProp ?? match(['slide', 'fade']);
  const hasRequiredSizeForSlidingAnimation = ['small', 'medium'].includes(size);
  const hasRequiredAnimationTypeForSlidingAnimation =
    fallbackAnimationType === 'slide';

  const shouldManuallyAnimateSlidingContent =
    shouldRenderBackdrop &&
    hasRequiredSizeForSlidingAnimation &&
    hasRequiredAnimationTypeForSlidingAnimation;

  const animationType = shouldManuallyAnimateSlidingContent
    ? 'fade'
    : fallbackAnimationType;

  // ─── Modal Machine ───────────────────────────────────────────────────

  const { modalState, modalServiceHelpers } = useModalMachineIntegration({
    visible,
    animationType,
    onShowing,
    onShown,
    onDismissing,
    onDismissed,
  });
  const { isShowing, isShown, isDismissing } = modalState;
  const isModalShown = (isShown || isShowing) && !isDismissing;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onModalShow = useCallback(() => {
    modalServiceHelpers.sendShowEvent();
    modalContext?.setActiveModal({ id });
  }, [id, modalContext, modalServiceHelpers]);

  const onModalDismiss = useCallback(() => {
    modalServiceHelpers.sendDismissEvent();
    modalContext?.resetActiveModal();
  }, [modalContext, modalServiceHelpers]);

  // ─── Effects ─────────────────────────────────────────────────────────

  useEffect(() => {
    return modalContext?.resetActiveModal;
  }, [modalContext?.resetActiveModal]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <RNModal
      statusBarTranslucent
      {...modalProps}
      visible={visible}
      transparent
      animationType={animationType}
      onRequestClose={onRequestClose}
      onShow={onModalShow}
      onDismiss={onModalDismiss}
    >
      <ModalSubComponents.ModalWrapper>
        <ModalSubComponents.ModalContainer
          size={size}
          fitHeight={fitHeight}
          offset={offset}
          isModalShown={isModalShown}
          isAnimated={shouldManuallyAnimateSlidingContent}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
        >
          <ModalSubComponents.ModalContent
            palette={palette}
            shouldFitToFill={size === 'full'}
            withSafeAreaView={withSafeAreaView}
          >
            {children}
          </ModalSubComponents.ModalContent>
        </ModalSubComponents.ModalContainer>

        {shouldRenderBackdrop ? (
          <ModalSubComponents.ModalBackdrop onPress={onRequestClose} />
        ) : null}
      </ModalSubComponents.ModalWrapper>
    </RNModal>
  );
};

// ─── Types ─────────────────────────────────────────────────────────────

type ModalProps = BaseModalProps &
  Readonly<{
    /**
     * Modals can have an optional `id`, which can be used to identify
     * them amongst other modals and perform conditional actions depending on that.
     */
    id?: string;

    /**
     * Accessibility label for the modal itself.
     * Without this, the modal will show up unannounced.
     */
    accessibilityLabel?: string;

    /**
     * Accessibility label for the backdrop pressable.
     */
    backdropAccessibilityLabel?: string;

    /**
     * Determines whether modal content should be wrapped in the `SafeArea` container.
     */
    withSafeAreaView?: boolean;

    /**
     * Fires immediately when the {visibility} is changed to {true}.
     */
    onShowing?: () => void;

    /**
     * Fires when the "show" animation is finished and the modal is fully shown.
     */
    onShown?: () => void;

    /**
     * Fires immediately when the {visibility} is changed to {false}.
     */
    onDismissing?: () => void;

    /**
     * Fires when the "dismiss" animation is finished and the modal is fully hidden.
     */
    onDismissed?: () => void;
  }> &
  Omit<RNModalProps, 'onShow' | 'onDismiss'> &
  PaletteProps;

// ───────────────────────────────────────────────────────────────────────

Modal.Row = ModalSubComponents.ModalRow;
Modal.CloseBtn = ModalSubComponents.ModalCloseBtn;
Modal.Header = ModalSubComponents.ModalHeader;
Modal.Headline = ModalSubComponents.ModalHeadline;
Modal.Subhead = ModalSubComponents.ModalSubhead;
Modal.FloatingCloseBtn = ModalSubComponents.ModalFloatingCloseBtn;
Modal.BodyText = ModalSubComponents.ModalBodyText;
Modal.Image = ModalSubComponents.ModalImage;
Modal.Footer = ModalSubComponents.ModalFooter;
Modal.WithTelemetry = Modal; // To be overwritten by clients.
