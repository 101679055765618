import React, { useCallback, useEffect } from 'react';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  BodyText,
  LoadingAnimation,
  ModalDialogue,
  useResponsive,
  useToggleState,
} from '@sg/garnish';
import { RedemptionChannel } from '@sg/graphql-schema';

import { useIsLoggedIn } from '@order/AuthMachine';
import { SignedOutView } from '@order/components';
import { Loyalty } from '@order/features/loyalty';
import { useNavigateToLastOrderMenu } from '@order/hooks';

import { LoyaltyHowItWorksModal, LoyaltyPointHistoryModal } from './components';
import { useLoyaltyInfo, useTermsBeingShown } from './hooks';

export const LoyaltyHomeScreen = () => {
  const isLoggedIn = useIsLoggedIn();
  const { isLoading, firstName, availablePoints, challenges, rewards } =
    useLoyaltyInfo(isLoggedIn);

  // ─── Points History Modal ─────────────────────────────────────────────────

  const {
    value: isShowingPointsHistoryModal,
    toggleOn: showPointsHistoryModal,
    toggleOff: hidePointsHistoryModal,
  } = useToggleState();

  // ─── How it Works Modal ───────────────────────────────────────────────────

  const {
    value: isShowingHowItWorksModal,
    toggleOn: showHowItWorksModal,
    toggleOff: hideHowItWorksModal,
  } = useToggleState();

  // ─── Terms Modal ──────────────────────────────────────────────────────────

  const { termsBeingShown, showTerms, hideTerms } = useTermsBeingShown();

  // ─── Navigation ───────────────────────────────────────────────────────────

  const { currentBreakpoint } = useResponsive();
  const navigation = useNavigation();
  const navigateToLastOrderMenu = useNavigateToLastOrderMenu();

  const clearModals = useCallback(() => {
    hideTerms();
    hidePointsHistoryModal();
    hideHowItWorksModal();
  }, [hideHowItWorksModal, hidePointsHistoryModal, hideTerms]);

  const startOrder = useCallback(() => {
    clearModals();
    navigateToLastOrderMenu();
  }, [clearModals, navigateToLastOrderMenu]);

  useEffect(() => {
    navigation.setOptions({ headerShown: !currentBreakpoint.isXS });
  }, [navigation, currentBreakpoint.isXS]);

  useFocusEffect(
    useCallback(() => {
      return clearModals;
    }, [clearModals]),
  );

  // ──────────────────────────────────────────────────────────────────────────

  if (!isLoggedIn) return <SignedOutView />;

  if (isLoading) return <LoadingAnimation />;

  // ──────────────────────────────────────────────────────────────────────────

  return (
    <Loyalty.Home.Container>
      <Loyalty.Home.Header
        firstName={firstName}
        availablePoints={availablePoints}
      />

      <Loyalty.Home.Body>
        <Loyalty.PointsHistory.Cta onPress={showPointsHistoryModal} />

        {currentBreakpoint.isXS ? (
          <>
            <BodyText sizeMatch={['14']}>•</BodyText>

            <Loyalty.HowItWorks.Cta onPress={showHowItWorksModal} />
          </>
        ) : null}
      </Loyalty.Home.Body>

      {challenges.length > 0 ? (
        <Loyalty.Home.ChallengesContainer>
          {challenges.map((challenge) => {
            return (
              <Loyalty.Challenge
                key={challenge.id}
                title={challenge.title}
                descriptiveTitle={challenge.descriptiveTitle}
                imageUrl={challenge.assetUrl}
              />
            );
          })}
        </Loyalty.Home.ChallengesContainer>
      ) : null}

      {rewards.length > 0 ? (
        <Loyalty.Home.RewardsContainer>
          {rewards.map((reward) => {
            const isInStoreOnly =
              reward.redemptionChannel === RedemptionChannel.InStore;
            const onShowTerms = reward.terms ? showTerms(reward) : undefined;
            const onStartOrder = isInStoreOnly ? undefined : startOrder;

            return (
              <Loyalty.Reward
                key={reward.id}
                id={reward.id}
                title={reward.name}
                points={reward.points}
                imageUrl={reward.assetUrl}
                expirationDate={reward.expirationDate}
                redeemableAt={reward.redeemableAt}
                isInStoreOnly={isInStoreOnly}
                onShowTerms={onShowTerms}
                onStartOrder={onStartOrder}
              />
            );
          })}
        </Loyalty.Home.RewardsContainer>
      ) : null}

      <Loyalty.Home.Footer />

      <LoyaltyPointHistoryModal
        availablePoints={availablePoints}
        visible={isShowingPointsHistoryModal}
        startOrder={startOrder}
        onRequestClose={hidePointsHistoryModal}
      />

      <LoyaltyHowItWorksModal
        visible={isShowingHowItWorksModal}
        onRequestClose={hideHowItWorksModal}
      />

      <ModalDialogue
        visible={Boolean(termsBeingShown)}
        onRequestClose={hideTerms}
      >
        {termsBeingShown?.terms ? (
          <Loyalty.RewardTerms
            title={termsBeingShown.name}
            terms={termsBeingShown?.terms}
            expirationDate={termsBeingShown?.expirationDate}
          />
        ) : null}
      </ModalDialogue>
    </Loyalty.Home.Container>
  );
};
