import type { ComponentProps, ReactNode } from 'react';
import React, { memo, useRef } from 'react';
import { View } from 'react-native';
import { GestureDetector } from 'react-native-gesture-handler';
import { useSharedValue } from 'react-native-reanimated';
import 'setimmediate'; // see https://github.com/software-mansion/react-native-reanimated/issues/4140

import { usePressableState, useWebKeyListener } from '../../hooks';
import { CarouselDots, CarouselStage } from './components/';
import {
  useCarouselAnimatedStyles,
  useCarouselGestures,
  useTrackCarouselActiveSlide,
} from './hooks';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders a customizable cross-platform carousel component driven by the
 * `react-native-reanimated` and `react-native-gesture-handler` libraries
 * to support performant animations.
 *
 * @see {@link https://docs.swmansion.com/react-native-reanimated/ RN Reanimated}
 * @see {@link https://docs.swmansion.com/react-native-gesture-handler/docs/ RN Gesture Handler}
 */
export const Carousel = memo((props: CarouselProps) => {
  const {
    children,
    initialSlideIndex = 0,
    gap = 0,
    stagePadding = 0,
    navControlAccessibilityLabel,
    navControlAccessibilityHint,
  } = props;

  const slidesCount = React.Children.count(children);
  const hasMultipleSlides = slidesCount > 1;

  const containerRef = useRef(null);
  const { isFocused } = usePressableState(containerRef);

  // ─── Animated Values ─────────────────────────────────────────────────

  const carouselSlideWidth = useSharedValue(0);
  const carouselTranslateXStart = useSharedValue(0);
  const carouselTranslateX = useSharedValue(0);
  const carouselScale = useSharedValue(1);

  const { carouselActiveSlideIndexSharedValue, carouselActiveSlideIndex } =
    useTrackCarouselActiveSlide({
      initialSlideIndex,
    });

  // ─── Animated Styles ─────────────────────────────────────────────────

  const { carouselAnimatedStyles, carouselItemAnimatedStyles } =
    useCarouselAnimatedStyles({
      carouselActiveSlideIndexSharedValue,
      carouselScale,
      carouselSlideWidth,
      carouselTranslateX,
      gap,
      stagePadding,
    });

  const {
    gestureHandler,
    swipeToActiveSlideOnCarouselLayout,
    swipeToSlide,
    swipeToThePrevSlide,
    swipeToTheNextSlide,
  } = useCarouselGestures({
    carouselActiveSlideIndexSharedValue,
    carouselScale,
    carouselSlideWidth,
    carouselTranslateX,
    carouselTranslateXStart,
    gap,
    slidesCount,
    stagePadding,
  });

  // ─── a11y Helpers ────────────────────────────────────────────────────

  useWebKeyListener('ArrowRight', swipeToTheNextSlide, !isFocused);
  useWebKeyListener('ArrowLeft', swipeToThePrevSlide, !isFocused);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View ref={containerRef} focusable>
      <CarouselContainer
        withGestures={hasMultipleSlides}
        gesturesHandler={gestureHandler}
      >
        <CarouselStage
          style={carouselAnimatedStyles}
          itemStyle={carouselItemAnimatedStyles}
          onLayout={swipeToActiveSlideOnCarouselLayout}
        >
          {children}
        </CarouselStage>
      </CarouselContainer>

      {hasMultipleSlides ? (
        <CarouselDots
          slidesCount={slidesCount}
          activeSlideIndex={carouselActiveSlideIndex}
          accessibilityLabel={navControlAccessibilityLabel}
          accessibilityHint={navControlAccessibilityHint}
          onPress={swipeToSlide}
        />
      ) : null}
    </View>
  );
});

// ─────────────────────────────────────────────────────────────────────────────

const CarouselContainer = (props: CarouselContainerProps) => {
  const { children, withGestures, gesturesHandler } = props;

  if (withGestures) {
    return (
      <GestureDetector gesture={gesturesHandler}>{children}</GestureDetector>
    );
  }

  return <View>{children}</View>;
};

// ─── Types ───────────────────────────────────────────────────────────────────

type CarouselProps = Readonly<{
  children: ReactNode;
  gap?: number;
  initialSlideIndex?: number;
  stagePadding?: number;

  /**
   * A generic a11y label for carousel navigation controls.
   * NOTE: The matching slide number will be attached to the label. */
  navControlAccessibilityLabel: string;

  /**
   * A generic a11y hint for carousel navigation controls.
   * NOTE: The matching slide number will be attached to the hint. */
  navControlAccessibilityHint?: string;
}>;

type CarouselContainerProps = Readonly<{
  children: ReactNode;
  withGestures: boolean;
  gesturesHandler: ComponentProps<typeof GestureDetector>['gesture'];
}>;
