import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { BodyText, IconLink, theme } from '@sg/garnish';

import { LoyaltyIconSgReward } from '../../LoyaltyIconSgReward';

/**
 * Header for Loyalty on Bag.
 */
export const BagLoyaltyHeader = (props: BagLoyaltyHeaderProps) => {
  const { title, onPressInfo } = props;
  const { formatMessage } = useIntl();

  return (
    <View style={styles.container}>
      <View style={styles.container}>
        <LoyaltyIconSgReward palette="black-fill" />

        <BodyText sizeMatch={['18']} bold>
          {title}
        </BodyText>
      </View>

      <IconLink
        name="IconInfo"
        width={24}
        height={24}
        accessibilityLabel={formatMessage(messages.infoIconA11y)}
        style={styles.infoIcon}
        onPress={onPressInfo}
      />
    </View>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing['4'],
  },
  infoIcon: {
    width: 32,
    height: 32,
  },
});

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  infoIconA11y: {
    defaultMessage: 'See more info',
    description: 'Bag | Loyalty Header | Info Icon A11y',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagLoyaltyHeaderProps = {
  title: string;
  onPressInfo: () => void;
};
