import { LoyaltyPointsHistoryContainer } from './LoyaltyPointsHistoryContainer';
import { LoyaltyPointsHistoryCta } from './LoyaltyPointsHistoryCta';
import { LoyaltyPointsHistoryEmptyState } from './LoyaltyPointsHistoryEmptyState';
import { LoyaltyPointsHistoryHeader } from './LoyaltyPointsHistoryHeader';
import { LoyaltyPointsHistoryRow } from './LoyaltyPointsHistoryRow';
import { LoyaltyPointsHistorySeeMoreCta } from './LoyaltyPointsHistorySeeMoreCta';

export const LoyaltyPointsHistory = {
  Container: LoyaltyPointsHistoryContainer,
  Header: LoyaltyPointsHistoryHeader,
  Row: LoyaltyPointsHistoryRow,
  Cta: LoyaltyPointsHistoryCta,
  SeeMoreCta: LoyaltyPointsHistorySeeMoreCta,
  EmptyState: LoyaltyPointsHistoryEmptyState,
};
