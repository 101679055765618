import React, { type ComponentProps } from 'react';
import { StyleSheet, View } from 'react-native';

import { IconLink } from '../../../Icon';
import { CUSTOM_NAVIGATION_HEADER_HORIZONTAL_PADDING } from '../../CustomNavigationHeader.constants';

// ─────────────────────────────────────────────────────────────────────────────

const CustomNavigationHeaderButtonRight = (
  props: CustomNavigationHeaderButtonProps,
) => {
  return <CustomNavigationHeaderButtonTemplate position="right" {...props} />;
};

const CustomNavigationHeaderButtonLeft = (
  props: CustomNavigationHeaderButtonProps,
) => {
  return <CustomNavigationHeaderButtonTemplate position="left" {...props} />;
};

const CustomNavigationHeaderButtonTemplate = (
  props: CustomNavigationHeaderButtonTemplateProps,
) => {
  const { position, ...restProps } = props;

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles = [
    styles.buttonContainer,
    position === 'right'
      ? styles.buttonContainerRight
      : styles.buttonContainerLeft,
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={containerStyles}>
      <IconLink
        palette="muted"
        iconSize={24}
        width={40}
        height={40}
        {...restProps}
      />
    </View>
  );
};

// ─────────────────────────────────────────────────────────────────────────────

export const CustomNavigationHeaderButton = {
  Right: CustomNavigationHeaderButtonRight,
  Left: CustomNavigationHeaderButtonLeft,
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  buttonContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    zIndex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainerRight: {
    right: CUSTOM_NAVIGATION_HEADER_HORIZONTAL_PADDING,
  },
  buttonContainerLeft: {
    left: CUSTOM_NAVIGATION_HEADER_HORIZONTAL_PADDING,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomNavigationHeaderButtonTemplateProps = {
  position: 'right' | 'left';
} & ComponentProps<typeof IconLink>;

type CustomNavigationHeaderButtonProps = ComponentProps<typeof IconLink>;
