import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Button, TextLink, useResponsive } from '@sg/garnish';

export const LoyaltyPointsHistoryCta = (
  props: LoyaltyPointsHistoryCtaProps,
) => {
  const { onPress } = props;

  const { currentBreakpoint, match } = useResponsive();
  const { formatMessage } = useIntl();

  const label = match([
    formatMessage(messages.viewHistory),
    formatMessage(messages.pointsHistory),
  ]);

  if (currentBreakpoint.isXS) {
    return (
      <TextLink
        underline
        sizeMatch={['14']}
        accessibilityRole="button"
        accessibilityLabel={formatMessage(messages.accessibilityLabel)}
        onPress={onPress}
      >
        {label}
      </TextLink>
    );
  }

  return (
    <Button
      palette="secondary"
      accessibilityRole="button"
      accessibilityLabel={formatMessage(messages.accessibilityLabel)}
      onPress={onPress}
    >
      {label}
    </Button>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  accessibilityLabel: {
    defaultMessage: 'Open points history screen',
    description: 'Loyalty | Points History | Accessibility label',
  },
  pointsHistory: {
    defaultMessage: 'Points history',
    description: 'Loyalty | Points History | Points history',
  },
  viewHistory: {
    defaultMessage: 'View history',
    description: 'Loyalty | Points History | View history',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyPointsHistoryCtaProps = {
  onPress: () => void;
};
