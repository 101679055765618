import React from 'react';
import { StyleSheet, View } from 'react-native';
import { format } from 'date-fns';
import { BodyText, DisplayText, TextLinkify, theme } from '@sg/garnish';

/**
 * Terms for the loyalty reward.
 */
export const LoyaltyRewardTerms = (props: LoyaltyRewardTermsProps) => {
  const { title, terms, expirationDate } = props;

  const expiration = expirationDate
    ? format(new Date(expirationDate), 'MMM d')
    : null;

  return (
    <View style={styles.container}>
      <DisplayText sizeMatch={['24']}>{title}</DisplayText>

      {expiration ? (
        <BodyText sizeMatch={['12']} style={styles.expirationLabel}>
          {expiration}
        </BodyText>
      ) : null}

      <BodyText sizeMatch={['16']}>
        <TextLinkify>{terms}</TextLinkify>
      </BodyText>
    </View>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: theme.spacing['4'],
    paddingBottom: theme.spacing['10'],
    gap: theme.spacing['2'],
  },
  expirationLabel: {
    color: theme.colors.GRAY,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyRewardTermsProps = {
  title: string;
  terms: string;
  expirationDate?: string | null;
};
