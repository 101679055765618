import React, { type PropsWithChildren } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { theme } from '@sg/garnish';

export const HowItWorksContainer = (props: PropsWithChildren) => {
  const { children } = props;

  return (
    <ScrollView contentContainerStyle={styles.container}>{children}</ScrollView>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    paddingHorizontal: theme.spacing[3],
  },
});
