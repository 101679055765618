import React, { useCallback } from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import type { RouteProp } from '@react-navigation/native';
import {
  useFocusEffect,
  useIsFocused,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import {
  BodyText,
  Button,
  Modal,
  theme,
  TransparentModal,
  useResponsive,
} from '@sg/garnish';

import { ProductCard } from '@order/components';
import { LoadingAnimation, NotFoundView } from '@order/components';
import { useLastInteractedStore } from '@order/LastInteractedStore';
import { useLocalizationContext } from '@order/Localization';
import { useLastValidOrder } from '@order/Order';
import { useTrackEffect } from '@order/Telemetry';

import type { ModalStackParamList } from '../../../../navigation/AppNavigation.props';
import { useOrderProductConfirmProductScreenData } from './hooks/useOrderProductConfirmProductScreenData';

export const OrderProductConfirmProductScreen = () => {
  const { t } = useLocalizationContext();

  const { params } =
    useRoute<RouteProp<ModalStackParamList, 'OrderProductConfirmProduct'>>();
  const { productSlug } = params ?? {};

  const isFocused = useIsFocused();

  const { baseProduct, isFetchingBaseProduct } =
    useOrderProductConfirmProductScreenData({ productSlug });

  if (!isFocused) return null;

  if (isFetchingBaseProduct) {
    return (
      <OrderProductConfirmProductContainer>
        <Modal.Row style={styles.notLoadedViewContainer}>
          <LoadingAnimation size="large" />
        </Modal.Row>
      </OrderProductConfirmProductContainer>
    );
  }

  if (!baseProduct?.isDeepLinkingEnabled) {
    return (
      <OrderProductConfirmProductContainer>
        <Modal.Row style={styles.notLoadedViewContainer}>
          <NotFoundView
            withoutFooter
            description={
              baseProduct && !baseProduct.isDeepLinkingEnabled
                ? t('order-product.not-available')
                : undefined
            }
          />
        </Modal.Row>
      </OrderProductConfirmProductContainer>
    );
  }

  return (
    <OrderProductConfirmProductContainer>
      <OrderProductConfirmProductLoaded baseProduct={baseProduct} />
    </OrderProductConfirmProductContainer>
  );
};

// ─── Subcomponents ───────────────────────────────────────────────────────────

const OrderProductConfirmProductContainer = (
  props: Pick<ViewProps, 'children'>,
) => {
  const navigation = useNavigation();
  const { match, minWidth } = useResponsive();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const dismissModal = useCallback(() => {
    navigation.getParent()?.goBack();
  }, [navigation]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <TransparentModal
      fitHeight
      size={match(['full', 'medium'])}
      onClose={dismissModal}
      mobileEdges={['right', 'bottom', 'left']}
    >
      {minWidth.isSM ? <Modal.FloatingCloseBtn onPress={dismissModal} /> : null}

      {props.children}
    </TransparentModal>
  );
};

const OrderProductConfirmProductLoaded = (
  props: OrderProductConfirmProductLoadedProps,
) => {
  const { baseProduct } = props;

  const { params } =
    useRoute<RouteProp<ModalStackParamList, 'OrderProductConfirmProduct'>>();
  const { productSlug } = params ?? {};

  const navigation = useNavigation();
  const { t } = useLocalizationContext();
  const { match, currentBreakpoint, minWidth } = useResponsive();
  const { fetchLastValidOrder, isFetchingOrders } = useLastValidOrder();
  const lastInteractedStore = useLastInteractedStore();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleContinuePress = useCallback(() => {
    navigation.navigate('Modal', {
      screen: lastInteractedStore
        ? 'OrderProductConfirmLocation'
        : 'OrderProductSearchLocation',
      params: { productSlug },
    });
  }, [navigation, productSlug, lastInteractedStore]);

  const descriptionSize = currentBreakpoint.isXS ? 4 : 3;
  const productCardContainerStyles = [
    styles.productCardContainer,
    match([styles.productCardContainerXS, styles.productCardContainerSM]),
  ];

  // ─── Effects ─────────────────────────────────────────────────────────

  useFocusEffect(fetchLastValidOrder); // fetch the last placed order on mount

  useTrackEffect('deep-linking.start');

  return (
    <>
      {baseProduct.heading ? (
        <Modal.Headline
          size={match([1, 2, 3])}
          style={match([undefined, styles.headerSM])}
        >
          {baseProduct.heading}
        </Modal.Headline>
      ) : null}

      <Modal.Row style={styles.contentContainer}>
        {baseProduct.subheading ? (
          <BodyText size={descriptionSize}>{baseProduct.subheading}</BodyText>
        ) : null}

        <View style={productCardContainerStyles}>
          <ProductCard
            name={baseProduct.name}
            description={baseProduct.description}
            label={baseProduct.isSeasonal ? t('general.seasonal') : ''}
            cloudinaryImageSrc={baseProduct?.asset?.url}
          />
        </View>
      </Modal.Row>

      <Modal.Footer
        style={match([styles.footerXS, styles.footerSM])}
        withoutTopBorder={minWidth.isSM}
      >
        <Button
          size="large"
          disabled={isFetchingOrders}
          onPress={handleContinuePress}
          testID="order-product.confirm-product-continue"
          accessibilityLabel={t('order-product.continue')}
          accessibilityHint={t('order-product.continue')}
        >
          {t('order-product.continue')}
        </Button>
      </Modal.Footer>
    </>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  headerSM: {
    marginTop: theme.spacing['2'],
    paddingTop: theme.spacing['10'],
  },
  contentContainer: {
    flex: 1,
  },
  notLoadedViewContainer: {
    flex: 1,
    justifyContent: 'center',
    paddingVertical: theme.spacing['6'],
  },
  productCardContainer: {
    paddingTop: theme.spacing['6'],
    width: '100%',
    alignSelf: 'center',
  },
  productCardContainerXS: {
    maxWidth: 400,
  },
  productCardContainerSM: {
    maxWidth: 342,
  },
  footerXS: {
    paddingVertical: theme.spacing['4'],
  },
  footerSM: {
    paddingTop: theme.spacing['6'],
    paddingHorizontal: theme.spacing['10'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type OrderProductConfirmProductLoadedProps = Readonly<{
  baseProduct: Exclude<
    ReturnType<typeof useOrderProductConfirmProductScreenData>['baseProduct'],
    null
  >;
}>;
