import {
  ProductCardV2AddToBagContainer,
  ProductCardV2AllergenNotice,
  ProductCardV2Container,
  ProductCardV2Description,
  ProductCardV2Footer,
  ProductCardV2Heading,
  ProductCardV2Image,
  ProductCardV2Label,
  ProductCardV2LabelContainer,
  ProductCardV2ProductDetail,
  ProductCardV2ProductDetailsContainer,
  ProductCardV2Tag,
  ProductCardV2UnavailableLabel,
} from './components';

export const ProductCardV2 = {
  Container: ProductCardV2Container,
  Tag: ProductCardV2Tag,
  Image: ProductCardV2Image,
  Heading: ProductCardV2Heading,
  Description: ProductCardV2Description,
  LabelContainer: ProductCardV2LabelContainer,
  Label: ProductCardV2Label,
  UnavailableLabel: ProductCardV2UnavailableLabel,
  Footer: ProductCardV2Footer,
  ProductDetailsContainer: ProductCardV2ProductDetailsContainer,
  ProductDetail: ProductCardV2ProductDetail,
  AllergenNotice: ProductCardV2AllergenNotice,
  AddToBagContainer: ProductCardV2AddToBagContainer,
};
