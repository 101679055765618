import React from 'react';
import { type ViewProps } from 'react-native';
import { Platform } from 'react-native';
import Animated, {
  Extrapolation,
  interpolate,
  type SharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import {
  ANIMATED_HEADER_SIZE,
  HEADER_SCROLL_REQUIREMENT,
} from './AnimatedHeader.constants';

export const AnimatedHeaderContent = (props: AnimatedHeaderContentProps) => {
  const { children, scrollOffset, ...rest } = props;
  const { childrenStyle } = useAnimatedHeaderContentStyle({ scrollOffset });

  return (
    <Animated.View pointerEvents="box-none" style={childrenStyle} {...rest}>
      {children}
    </Animated.View>
  );
};

const useAnimatedHeaderContentStyle = (props: AnimatedHeaderContentProps) => {
  const { scrollOffset } = props;

  const { top } = useSafeAreaInsets();
  const topInset = Platform.OS === 'android' ? top : 0;
  const headerHeight = ANIMATED_HEADER_SIZE + topInset;
  const amountNeededToShowHeader = headerHeight * HEADER_SCROLL_REQUIREMENT;

  const childrenStyle = useAnimatedStyle(
    () => ({
      flex: 1,
      opacity: interpolate(
        scrollOffset?.value ?? 0,
        [headerHeight, amountNeededToShowHeader],
        [0, 1],
        Extrapolation.CLAMP,
      ),
    }),
    [scrollOffset, headerHeight, amountNeededToShowHeader],
  );

  return { childrenStyle };
};

// ─── Types ──────────────────────────────────────────────────────────────────

type AnimatedHeaderContentProps = ViewProps & {
  scrollOffset?: SharedValue<number>;
};
