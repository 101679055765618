/* eslint-disable react/no-array-index-key */

import React, { memo, useCallback, useMemo, useRef } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import Animated, {
  interpolateColor,
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
} from 'react-native-reanimated';
import { theme } from '@garnish/constants';

import { usePressableState } from '../../../../hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const CarouselDots = memo((props: CarouselDotsProps) => {
  const {
    slidesCount,
    activeSlideIndex,
    accessibilityLabel,
    accessibilityHint,
    onPress,
  } = props;

  const dots = useMemo(
    () => Array.from({ length: slidesCount }),
    [slidesCount],
  );

  return (
    <View style={styles.container}>
      {dots.map((dot, index) => (
        <CarouselDot
          key={index}
          isActive={activeSlideIndex === index}
          index={index}
          accessibilityLabel={accessibilityLabel}
          accessibilityHint={accessibilityHint}
          onPress={onPress}
        />
      ))}
    </View>
  );
});

// ─── Components ──────────────────────────────────────────────────────────────

const CarouselDot = memo((props: CarouselDotProps) => {
  const { index, accessibilityLabel, accessibilityHint, isActive, onPress } =
    props;

  const pressableRef = useRef(null);
  const { isHovered } = usePressableState(pressableRef);

  // ─── a11y ────────────────────────────────────────────────────────────

  const readableIndex = index + 1;
  const accessibilityLabelWithIndex = `${accessibilityLabel} ${readableIndex}`;
  const accessibilityHintWithIndex = accessibilityHint
    ? `${accessibilityHint} ${readableIndex}`
    : undefined;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleOnPress = useCallback(() => {
    if (isActive) return;

    onPress(index);
  }, [index, isActive, onPress]);

  // ─── Styles ──────────────────────────────────────────────────────────

  const dotHover = useDerivedValue(() =>
    withTiming(isHovered ? 1 : 0, { duration: theme.transitions.base }),
  );

  const dotAnimatedStyle = useAnimatedStyle(() => {
    const { INACTIVE, ACTIVE } = DOT_BG_COLORS;

    if (isActive) {
      return {
        backgroundColor: interpolateColor(
          dotHover.value,
          [0, 1],
          [ACTIVE.IDLE, ACTIVE.HOVER],
        ),
      };
    }

    return {
      backgroundColor: interpolateColor(
        dotHover.value,
        [0, 1],
        [INACTIVE.IDLE, INACTIVE.HOVER],
      ),
    };
  });

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Pressable
      ref={pressableRef}
      style={styles.dotContainer}
      accessibilityRole="button"
      accessibilityLabel={accessibilityLabelWithIndex}
      accessibilityHint={accessibilityHintWithIndex}
      accessibilityState={{ selected: isActive }}
      onPress={handleOnPress}
    >
      <Animated.View style={[styles.dot, dotAnimatedStyle]} />
    </Pressable>
  );
});

// ─── Constants ───────────────────────────────────────────────────────────────

const DOT_SIZE = theme.spacing['2'];

const DOT_BG_COLORS = {
  INACTIVE: {
    IDLE: theme.colors.GRAY,
    HOVER: theme.colors.LIGHT,
  },
  ACTIVE: {
    IDLE: theme.colors.KALE,
    HOVER: theme.colors.KALE_HOVER,
  },
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: theme.spacing['6'],
  },
  dotContainer: {
    padding: theme.spacing['1'],
  },
  dot: {
    width: DOT_SIZE,
    height: DOT_SIZE,
    borderRadius: DOT_SIZE,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CarouselDotsProps = Readonly<{
  slidesCount: number;
  activeSlideIndex: number;
  accessibilityLabel: string;
  accessibilityHint?: string;
  onPress: (slideIndex: number) => void;
}>;

type CarouselDotProps = Readonly<{
  index: number;
  isActive: boolean;
  accessibilityLabel: string;
  accessibilityHint?: string;
  onPress: (slideIndex: number) => void;
}>;
