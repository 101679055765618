import React from 'react';
import { StyleSheet, View, type ViewProps } from 'react-native';
import { theme } from '@garnish/constants';

import {
  type AlertContainerPalette,
  paletteStyles,
} from './AlertContainer.palettes';

// ─────────────────────────────────────────────────────────────────────────────

export const AlertContainer = (props: AlertContainerProps) => {
  const { palette, children, style, ...restProps } = props;

  const containerStyles = [styles.container, paletteStyles[palette], style];

  return (
    <View style={containerStyles} {...restProps}>
      {children}
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    gap: theme.spacing['2'],
    borderRadius: theme.radius.medium,
    paddingVertical: theme.spacing['2'],
    paddingHorizontal: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type AlertContainerProps = {
  palette: AlertContainerPalette;
} & ViewProps;
