/* eslint-disable @typescript-eslint/no-var-requires */

export const IllusBerry = require('./quinoa/Illus-Berry.png');
export const IllusFlower = require('./quinoa/Illus-Flower.png');
export const IllusSprout = require('./quinoa/Illus-Sprout.png');

export const IllusCarrotPremium = require('./kale/Illus-RewardCard_Carrot_Premium.png');
export const IllusPeasPremium = require('./kale/Illus-RewardCard_Peas_Premium.png');
export const IllusMushroomPremium = require('./kale/Illus-RewardCard_Mushroom_Premium.png');
export const IllusTomatoPremium = require('./kale/Illus-RewardCard_Tomato_Premium.png');
