import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { BodyText, Collapsible, DisplayText, VStack } from '@sg/garnish';

export const LoyaltyFAQ = (props: LoyaltyFAQProps) => {
  const { entries } = props;

  return (
    <View style={styles.container}>
      <DisplayText sizeMatch={['32']}>
        <FormattedMessage {...messages.title} />
      </DisplayText>

      <VStack hasDivider>
        {entries.map((entry) => (
          <LoyaltyFaqEntry key={entry.title} entry={entry} />
        ))}
      </VStack>
    </View>
  );
};

const LoyaltyFaqEntry = (props: LoyaltyFAQEntryProps) => {
  const { entry } = props;

  return (
    <Collapsible>
      <Collapsible.Summary>
        <BodyText sizeMatch={['18']}>{entry.title}</BodyText>
      </Collapsible.Summary>

      <Collapsible.Details>
        <BodyText sizeMatch={['12']}>{entry.description}</BodyText>
      </Collapsible.Details>
    </Collapsible>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  title: {
    defaultMessage: 'FAQ',
    description: 'Loyalty | FAQ | Title',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: theme.spacing['6'],
    gap: theme.spacing['4'],
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type FAQEntry = {
  title: string;
  description: string;
};

type LoyaltyFAQProps = {
  entries: FAQEntry[];
};

type LoyaltyFAQEntryProps = {
  entry: FAQEntry;
};
