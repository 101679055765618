import React, { useCallback, useRef } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  HostedFrame,
  type HostedFrameRef,
  theme,
  useResponsive,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';

import { CreditCardFormControlledContent } from '../../components/CreditCard/CreditCardFormControlledContent';
import { CreditCardFormErrorState } from '../../components/CreditCard/CreditCardFormErrorState';
import { CreditCardFormLoadingState } from '../../components/CreditCard/CreditCardFormLoadingState';
import { useCreditCardForm } from '../../hooks/useCreditCardForm';
import {
  useStripeFormSubmission,
  useStripeKeys,
  useStripeMessages,
} from './hooks';
import { FRAME_HEIGHT, getStripeSource, STRIPE_FRAME_ID } from './utils';

export const StripeCreditCardForm = (props: StripeCreditCardFormProps) => {
  const {
    excludedFields,
    isSubmitting,
    isApplePayReady,
    handleOnSave,
    handleOnApplePay,
  } = props;

  // ─── Context ─────────────────────────────────────────────────────────

  const hostedFrameRef = useRef<HostedFrameRef>(null);
  const { match } = useResponsive();
  const { track } = useTelemetry();
  const { t } = useLocalizationContext();

  // ─── Controlled Form State ───────────────────────────────────────────

  const { formData, updateForm } = useCreditCardForm({ default: true });

  // ─── Submission Results ──────────────────────────────────────────────

  const handleSuccess = useCallback(
    (id: string) => {
      track('payment_credit.save_card');

      handleOnSave({
        id,
        nickname: formData.nickname,
        isDefault: formData.default,
      });
    },
    [formData.default, formData.nickname, track, handleOnSave],
  );

  // ─── Styles ──────────────────────────────────────────────────────────

  const webViewBackgroundColor = MAIN_BACKGROUND_COLOR;
  const containerBackgroundColor = styles.container;

  // ─── Stripe Initialization ───────────────────────────────────────────

  const { handleSubmitStripeForm, handleStripeError } = useStripeFormSubmission(
    { hostedFrameRef },
  );

  const { stripeKey, clientSecret, isFetchingStripeKeys, isMissingStripeKeys } =
    useStripeKeys();

  const postalCodeErrorMessage = t('credit-card-form.error.banner.zipCode');
  const stripeWebSource = getStripeSource({
    stripeKey,
    clientSecret,
    postalCodeErrorMessage,
    backgroundColor: webViewBackgroundColor,
  });

  const { isSubmittingStripe, handleStripeMessages } = useStripeMessages({
    onSuccess: handleSuccess,
    onError: handleStripeError,
  });

  // ─── Rendering Control ───────────────────────────────────────────────

  if (isMissingStripeKeys) {
    return <CreditCardFormErrorState />;
  }

  return (
    <CreditCardFormControlledContent
      canSubmitForm
      mode="add"
      isSaving={isSubmittingStripe || isSubmitting}
      form={formData}
      excludedFields={excludedFields}
      isApplePayReady={isApplePayReady}
      onFormChange={updateForm}
      onSubmitForm={handleSubmitStripeForm}
      onApplePay={handleOnApplePay}
    >
      <View style={[containerBackgroundColor, styles.wrapper]}>
        <CreditCardFormLoadingState
          style={[containerBackgroundColor, styles.wrapper]}
          show={isFetchingStripeKeys}
        />

        <HostedFrame
          ref={hostedFrameRef}
          frameId={STRIPE_FRAME_ID}
          frameHeight={FRAME_HEIGHT}
          title={t('credit-card-form.title')}
          isLoading={isFetchingStripeKeys}
          source={stripeWebSource}
          onMessage={handleStripeMessages}
          style={containerBackgroundColor}
          containerStyle={[
            containerBackgroundColor,
            styles.wrapper,
            match([styles.containerXs, styles.containerMd]),
            isFetchingStripeKeys && styles.loadingFields,
          ]}
        />
      </View>
    </CreditCardFormControlledContent>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const MAIN_BACKGROUND_COLOR = theme.colors.APP_BACKGROUND;

// ─── Types ───────────────────────────────────────────────────────────────────

type StripeCreditCardFormProps = Readonly<{
  excludedFields?: ReadonlyArray<'nickname' | 'isDefault'>;
  isSubmitting: boolean;
  isApplePayReady?: boolean;
  handleOnSave: (payload: CreditCardForm) => void;
  handleOnApplePay?: () => void;
}>;

type CreditCardForm = Readonly<{
  id: string;
  nickname: string;
  isDefault: boolean;
}>;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    height: FRAME_HEIGHT,
  },
  container: {
    backgroundColor: MAIN_BACKGROUND_COLOR,
  },
  containerXs: {
    paddingHorizontal: theme.spacing['4'],
  },
  containerMd: {
    paddingHorizontal: theme.spacing['6'],
  },
  // for web views to function properly on Android, their container must be at least 1x1 in size
  loadingFields: {
    flex: 0,
    width: 1,
    height: 1,
  },
});
