import React, { type ComponentProps } from 'react';

import { IconLink } from '../Icon';
import { ICON_SIZE } from './AnimatedHeader.constants';

export const AnimatedHeaderIcon = (props: ComponentProps<typeof IconLink>) => {
  const { name, accessibilityLabel, onPress } = props;

  return (
    <IconLink
      palette="dark-kale"
      name={name}
      width={ICON_SIZE}
      height={ICON_SIZE}
      accessibilityLabel={accessibilityLabel}
      onPress={onPress}
    />
  );
};
