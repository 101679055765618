import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { BodyText, Button, IllusEmployeeBag, Image, theme } from '@sg/garnish';

export const LoyaltyPointsHistoryEmptyState = (
  props: LoyaltyPointsHistoryEmptyStateProps,
) => {
  const { onPress } = props;
  const { formatMessage } = useIntl();

  return (
    <View style={styles.container}>
      <Image
        source={IllusEmployeeBag}
        style={styles.image}
        aria-label={formatMessage(messages.imageA11yLabel)}
      />

      <BodyText style={styles.message} bold sizeMatch={['16']}>
        {formatMessage(messages.title)}
      </BodyText>

      <BodyText style={styles.message} sizeMatch={['16']}>
        {formatMessage(messages.subtitle)}
      </BodyText>

      <Button
        palette="kaleLightest"
        size="large-wide"
        aria-label={formatMessage(messages.cta)}
        onPress={onPress}
      >
        {formatMessage(messages.cta)}
      </Button>
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  title: {
    defaultMessage: 'No history',
    description: 'Loyalty | Points History | Title',
  },
  subtitle: {
    defaultMessage: 'You will see your points history here.',
    description: 'Loyalty | Points History | Subtitle',
  },
  imageA11yLabel: {
    defaultMessage: 'Employee holding an empty bag',
    description: 'Loyalty | Points History | Image a11y label',
  },
  cta: {
    defaultMessage: 'Order now',
    description: 'Loyalty | Points History | Cta',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    gap: theme.spacing['4'],
    alignItems: 'center',
  },
  image: {
    width: 220,
    height: 220,
  },
  message: {
    textAlign: 'center',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyPointsHistoryEmptyStateProps = {
  onPress: () => void;
};
