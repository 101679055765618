import { BagLoyaltyHeader } from './BagLoyaltyHeader';
import { BagLoyaltyInfo } from './BagLoyaltyInfo';
import { BagLoyaltySignedOutCta } from './BagLoyaltySignedOutCta';
import { BagLoyaltySubtitle } from './BagLoyaltySubtitle';

export const LoyaltyBag = {
  LoyaltyHeader: BagLoyaltyHeader,
  LoyaltyInfo: BagLoyaltyInfo,
  LoyaltySignedOutCta: BagLoyaltySignedOutCta,
  LoyaltySubtitle: BagLoyaltySubtitle,
};
