import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { type ScrollViewProps, StyleSheet, View } from 'react-native';
import Animated, {
  useAnimatedRef,
  useScrollViewOffset,
} from 'react-native-reanimated';
import { type AddressType, AnimatedHeader, BodyText } from '@sg/garnish';

import { BagViewMenuCta } from '../../Ctas';
import { BagChannelAndLocationInfo } from '../../Header';

// Without the empty fragment around the header, the styling breaks.
/* eslint-disable react/jsx-no-useless-fragment */

/**
 * ScrollView for the bag.
 * Contains a header with the menu and close buttons.
 * When scrolled displays order channel and location.
 */
export const BagScrollView = (props: BagScrollViewProps) => {
  const {
    children,
    orderChannel,
    locationName,
    viewMenuCta,
    viewMenuA11y,
    isDisabled,
    onViewMenu,
    requestChannelOrLocationChange,
    requestDeliveryPreferencesChange,
    onHeaderCloseBtnPress,
  } = props;

  const { formatMessage } = useIntl();
  const animatedRef = useAnimatedRef<Animated.ScrollView>();
  const scrollOffset = useScrollViewOffset(animatedRef);

  return (
    <Animated.ScrollView
      automaticallyAdjustKeyboardInsets
      ref={animatedRef}
      style={styles.bagScrollView}
      contentContainerStyle={styles.bagScrollView}
      stickyHeaderIndices={[0]}
    >
      <>
        <AnimatedHeader.Container scrollOffset={scrollOffset} palette="oatmeal">
          <AnimatedHeader.Button alignment="start">
            <View>
              <BagViewMenuCta
                cta={viewMenuCta}
                a11yLabel={viewMenuA11y}
                isDisabled={isDisabled}
                onPress={onViewMenu}
              />
            </View>
          </AnimatedHeader.Button>

          <AnimatedHeader.Content scrollOffset={scrollOffset}>
            <BodyText style={styles.headerText} sizeMatch={['16']}>
              <FormattedMessage {...messages.title} />
            </BodyText>

            <BagChannelAndLocationInfo
              orderChannel={orderChannel}
              locationName={locationName}
              requestChannelOrLocationChange={requestChannelOrLocationChange}
              requestDeliveryPreferencesChange={
                requestDeliveryPreferencesChange
              }
            />
          </AnimatedHeader.Content>

          <AnimatedHeader.CloseButton
            accessibilityLabel={formatMessage(messages.closeBag)}
            onPress={onHeaderCloseBtnPress}
          />
        </AnimatedHeader.Container>
      </>

      {children}
    </Animated.ScrollView>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  title: {
    defaultMessage: 'Your bag',
    description: 'Bag | Header | Title',
  },
  closeBag: {
    defaultMessage: 'Close Bag',
    description: 'Bag | Header | Close bag button',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  headerText: {
    textAlign: 'center',
  },
  bagScrollView: {
    flexGrow: 1,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagScrollViewProps = ScrollViewProps & {
  orderChannel: AddressType;
  locationName: string;
  viewMenuCta: string;
  viewMenuA11y: string;
  isDisabled: boolean;
  onViewMenu: () => void;
  requestChannelOrLocationChange: () => void;
  requestDeliveryPreferencesChange: () => void;
  onHeaderCloseBtnPress: () => void;
};
