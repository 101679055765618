import type { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import type {
  CompositeScreenProps,
  LinkingOptions,
  NavigationProp,
  NavigationState,
  NavigatorScreenParams,
  PartialState,
  RouteProp,
} from '@react-navigation/native';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ReactNavigation {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface RootParamList extends AppStackParamList {}
  }
}

export type AppLinkingOptions = LinkingOptions<AppStackParamList>;
export type AppNavigationState = NavigationState<AppStackParamList>;
export type PartialAppNavigationState = PartialState<AppNavigationState>;

export type AppNavigationProp = NavigationProp<AppStackParamList>;
export type AppRouteProp = RouteProp<AppStackParamList>;

// ============================================================
// AppStack
// ============================================================

export const AppStackNavigator =
  createNativeStackNavigator<AppStackParamList>();

export type AppStackParamList = Readonly<{
  MainTabs: NavigatorScreenParams<MainTabsParamList>;
  Modal: NavigatorScreenParams<ModalStackParamList>;
  Auth: NavigatorScreenParams<AuthStackParamList>;
  Error: NavigatorScreenParams<ErrorStackParamList>;
}>;

export type AppStackChild<
  Keys extends keyof AppStackParamList = keyof AppStackParamList,
> = keyof Pick<AppStackParamList, Keys>;

export type AppStackChildProps<T extends AppStackChild> =
  NativeStackScreenProps<AppStackParamList, T>;

// ============================================================
// AppStack > MainTabs
// ============================================================

export const MainTabsNavigator = createBottomTabNavigator<MainTabsParamList>();

export type MainTabsParamList = Readonly<{
  HomeTab: NavigatorScreenParams<HomeTabStackParamList>;
  MenuTab: NavigatorScreenParams<MenuTabStackParamList>;
  LoyaltyTab: NavigatorScreenParams<LoyaltyTabStackParamList>;
  ScanTab: NavigatorScreenParams<ScanTabStackParamList>;
  AccountTab: NavigatorScreenParams<AccountTabStackParamList>;
  GiftCardsTab: NavigatorScreenParams<GiftCardsTabStackParamList>;
}>;

export type MainTabsScreenProps = AppStackChildProps<'MainTabs'>;

export type MainTabName<
  Keys extends keyof MainTabsParamList = keyof MainTabsParamList,
> = keyof Pick<MainTabsParamList, Keys>;

export type MainTabsChildProps<T extends MainTabName> = CompositeScreenProps<
  BottomTabScreenProps<MainTabsParamList, T>,
  MainTabsScreenProps
>;

// ============================================================
// AppStack > MainTabs > HomeTab
// ============================================================

export const HomeTabStackNavigator =
  createNativeStackNavigator<HomeTabStackParamList>();

export type HomeTabStackParamList = Readonly<{
  // /
  Home: undefined;
}>;

export type HomeTabScreenProps = MainTabsChildProps<'HomeTab'>;

export type HomeTabStackScreenName<
  Keys extends keyof HomeTabStackParamList = keyof HomeTabStackParamList,
> = keyof Pick<HomeTabStackParamList, Keys>;

export type HomeTabStackChildProps<T extends HomeTabStackScreenName> =
  CompositeScreenProps<
    NativeStackScreenProps<HomeTabStackParamList, T>,
    HomeTabScreenProps
  >;

export type HomeScreenProps = HomeTabStackChildProps<'Home'>;

// ============================================================
// AppStack > MainTabs > MenuTab
// ============================================================

export const MenuTabStackNavigator =
  createNativeStackNavigator<MenuTabStackParamList>();

export type MenuTabStackParamList = Readonly<{
  // /locations
  Locations: undefined;
  // /menu
  MenuWithoutSlug: undefined;
  // /:restaurantSlug/menu
  Menu: { restaurantSlug: string };
  // /delivery/:addressId
  DeliveryMenu: {
    addressId: string;
  };
  // /:restaurantSlug/collection/:collectionSlug
  MenuCollection: {
    restaurantSlug: string;
    menuCollectionId: string;
    addressId?: string;
  };
}>;

export type MenuTabScreenProps = MainTabsChildProps<'MenuTab'>;

export type MenuTabStackScreenName<
  Keys extends keyof MenuTabStackParamList = keyof MenuTabStackParamList,
> = keyof Pick<MenuTabStackParamList, Keys>;

export type MenuTabStackChildProps<T extends MenuTabStackScreenName> =
  CompositeScreenProps<
    NativeStackScreenProps<MenuTabStackParamList, T>,
    MenuTabScreenProps
  >;

export type LocationsScreenProps = MenuTabStackChildProps<'Locations'>;
export type MenuWithoutSlugScreenProps =
  MenuTabStackChildProps<'MenuWithoutSlug'>;
export type MenuScreenProps = MenuTabStackChildProps<'Menu'>;
export type DeliveryMenuScreenProps = MenuTabStackChildProps<'DeliveryMenu'>;

// ============================================================
// AppStack > MainTabs > LoyaltyTab
// ============================================================

export const LoyaltyTabStackNavigator =
  createNativeStackNavigator<LoyaltyTabStackParamList>();

export type LoyaltyTabStackParamList = Readonly<{
  // /sweetpass
  SweetpassHome?: {
    target?: string;
    // /sweetpass?challengeId=123&optIn=true
    // Handle challenge opt-in on focus if challengeId and optIn are present in the query params.
    challengeId?: string;
    optIn?: string;
  };
}>;

export type LoyaltyTabScreenProps = MainTabsChildProps<'LoyaltyTab'>;

export type LoyaltyTabStackScreenName<
  Keys extends keyof LoyaltyTabStackParamList = keyof LoyaltyTabStackParamList,
> = keyof Pick<LoyaltyTabStackParamList, Keys>;

export type LoyaltyTabStackChildProps<T extends LoyaltyTabStackScreenName> =
  CompositeScreenProps<
    NativeStackScreenProps<LoyaltyTabStackParamList, T>,
    LoyaltyTabScreenProps
  >;

export type SweetpassHomeScreenProps =
  LoyaltyTabStackChildProps<'SweetpassHome'>;

// ============================================================
// AppStack > MainTabs > ScanTab
// ============================================================

export const ScanTabStackNavigator =
  createNativeStackNavigator<ScanTabStackParamList>();

export type ScanTabStackParamList = Readonly<{
  ScanInStore: undefined;
}>;

export type ScanTabScreenProps = MainTabsChildProps<'ScanTab'>;

export type ScanTabStackScreenName<
  Keys extends keyof ScanTabStackParamList = keyof ScanTabStackParamList,
> = keyof Pick<ScanTabStackParamList, Keys>;

export type ScanTabStackChildProps<T extends ScanTabStackScreenName> =
  CompositeScreenProps<
    NativeStackScreenProps<ScanTabStackParamList, T>,
    ScanTabScreenProps
  >;

// ─── AppStack > MainTabs > GiftCardsTab ──────────────────────────────────────

export const GiftCardsTabStackNavigator =
  createNativeStackNavigator<GiftCardsTabStackParamList>();

export type GiftCardsTabStackParamList = Readonly<{
  GiftCards: undefined;
  GiftCardCheckout: { assetId: string };
  GiftCardConfirmation: { orderId: string; referrer?: 'gift-card-orders' };
  GiftCardRedemption: { giftCardId: string };
}>;

export type GiftCardsTabScreenProps = MainTabsChildProps<'GiftCardsTab'>;

// ============================================================
// AppStack > MainTabs > AccountTab
// ============================================================

export const AccountTabStackNavigator =
  createNativeStackNavigator<AccountTabStackParamList>();

export type AccountTabScreenId =
  | 'profile'
  | 'sweetpass-membership'
  | 'credit-and-promo-codes'
  | 'payment-and-gift-cards'
  | 'addresses'
  | 'orders'
  | 'favorites'
  | 'refer-friend'
  | 'dietary-restrictions';

export type AccountTabStackParamList = Readonly<{
  // /account/menu
  AccountMenu: undefined;

  // /account/info
  Profile: undefined;

  // /account/sweetpass/membership
  SweetpassMembership: undefined;

  // /account/credit-and-promo-codes
  CreditAndPromoCodes: undefined;

  // /account/payment-and-gift-cards
  PaymentAndGiftCards: { giftCardId?: string } | undefined;

  // /account/addresses
  Addresses: undefined;

  // /account/orders/:tabId?
  Orders: { tabId?: 'orders' | 'gift-cards' } | undefined;

  // /account/favorites
  Favorites: undefined;

  // /account/refer-friend
  ReferFriend: undefined;

  // /account/dietary-restrictions
  AccountDietaryRestrictions: undefined;
}>;

export type AccountTabScreenProps = MainTabsChildProps<'AccountTab'>;

export type AccountTabStackScreenName<
  Keys extends keyof AccountTabStackParamList = keyof AccountTabStackParamList,
> = keyof Pick<AccountTabStackParamList, Keys>;

export type AccountTabStackChildProps<T extends AccountTabStackScreenName> =
  CompositeScreenProps<
    NativeStackScreenProps<AccountTabStackParamList, T>,
    AccountTabScreenProps
  >;

export type AccountMenuScreenProps = AccountTabStackChildProps<'AccountMenu'>;

export type ProfileScreenProps = AccountTabStackChildProps<'Profile'>;

export type CreditAndPromoCodesScreenProps =
  AccountTabStackChildProps<'CreditAndPromoCodes'>;

export type PaymentAndGiftCardsScreenProps =
  AccountTabStackChildProps<'PaymentAndGiftCards'>;

export type SweetpassMembershipScreenProps =
  AccountTabStackChildProps<'SweetpassMembership'>;

export type AddressesScreenProps = AccountTabStackChildProps<'Addresses'>;

export type OrdersScreenProps = AccountTabStackChildProps<'Orders'>;

export type FavoritesScreenProps = AccountTabStackChildProps<'Favorites'>;

export type ReferFriendScreenProps = AccountTabStackChildProps<'ReferFriend'>;

export type AccountDietaryRestrictionsScreenProps =
  AccountTabStackChildProps<'AccountDietaryRestrictions'>;

// ============================================================
// AppStack > Modal
// ============================================================

export const ModalStackNavigator =
  createNativeStackNavigator<ModalStackParamList>();

export type ModalStackParamList = Readonly<{
  // /:restaurantSlug/:productSlug
  ProductDetails: {
    restaurantSlug: string;
    productSlug: string;
    ingredientIds?: string;
    name?: string;
    reorderLineItemId?: string;
  };
  // /:restaurantSlug/:productSlug/edit/:lineItemId
  EditProductDetails: {
    restaurantSlug: string;
    productSlug: string;
    lineItemId: string;
  };

  // /delivery/:addressId/:productSlug
  DeliveryProductDetails: {
    addressId: string;
    productSlug: string;
    ingredientIds?: string;
    name?: string;
    reorderLineItemId?: string;
  };

  // /delivery/:addressId/:productSlug/edit/:lineItemId
  DeliveryEditProductDetails: {
    addressId: string;
    productSlug: string;
    lineItemId: string;
  };

  // /reorder
  Reorder: undefined;

  // /reorder/location/confirm
  ReorderConfirmLocation: undefined;
  // /reorder/locations
  ReorderChangeLocation: undefined;
  // /reorder/conflicts
  ReorderConflictReview: undefined;
  // /reorder/active-bag-warning
  ReorderActiveBagWarning: undefined;

  // /bag
  Bag:
    | {
        // refers to the screen or action that initiated navigation to the bag
        referrer?: 'sweetpass-upgrade' | 'Menu' | 'DeliveryMenu';
      }
    | undefined;

  // /checkout
  Checkout: undefined;

  // /order
  OrderStatus: {
    orderId: string;
  };

  // /scan-in-store
  ScanInStore: undefined;

  // /credit-and-promo-codes
  CreditAndPromoCodes: undefined;

  // /payment-and-gift-cards
  PaymentAndGiftCards: undefined;

  // /credit/add
  CreditAdd: undefined;
  // /credit/scan
  CreditScan: undefined;
  // /credit
  CreditDetails: undefined;

  // /add-gift-card
  AddGiftCard: { giftCardId?: string } | undefined;

  // /redeem-gift-card
  RedeemGiftCard: { giftCardId?: string } | undefined;

  // /dietary-restrictions
  DietaryRestrictions: undefined;

  // /feedback/:orderId
  RateOrder: { orderId: string };

  // /share/:lineItemSlug
  ShareLineItem: { lineItemSlug: string };

  // /ca-data-requests
  PersonalData: undefined;
  PersonalDataDetails: { action: 'access' | 'delete' };

  // delivery in flight modal, no path
  DeliveryOrderInFlight: { orderId: string };

  // /sweetpass/benefits
  SweetpassBenefits: undefined;

  // /sweetpass/upgrade
  SweetpassUpgrade:
    | {
        // refers to the screen that must be accessed following a successful sweetpass tier upgrade
        redirectToAfterUpgrade?: 'bag' | 'order-status';
        redirectToOrderId?: string;
        planId?: string;
        campaignId?: string;
      }
    | undefined;

  // /sweetpass/checkout
  SweetpassCheckout: {
    planId?: string;
    campaignId?: string;

    // refers to the screen that must be accessed following a successful sweetpass tier upgrade
    redirectToAfterUpgrade?: 'bag' | 'order-status' | 'account-membership';
    redirectToOrderId?: string;
  };

  // /sweetpass/change-plan
  SweetpassSwitchSubscription: {
    planId?: string;

    // refers to the screen that must be accessed following a successful sweetpass tier upgrade
    redirectToAfterUpgrade: 'account-membership';
  };

  // /order-product/:productSlug
  OrderProductConfirmProduct: { productSlug: string };

  // /order-product/:productSlug/confirm-location
  OrderProductConfirmLocation: { productSlug: string };

  // /order-product/:productSlug/search-location
  OrderProductSearchLocation: { productSlug: string };
}>;

export type ModalStackScreenProps = AppStackChildProps<'Modal'>;

export type ModalStackScreenName<
  Keys extends keyof ModalStackParamList = keyof ModalStackParamList,
> = keyof Pick<ModalStackParamList, Keys>;

export type ModalStackChildProps<T extends ModalStackScreenName> =
  CompositeScreenProps<
    NativeStackScreenProps<ModalStackParamList, T>,
    ModalStackScreenProps
  >;

export type ProductDetailsScreenProps = ModalStackChildProps<'ProductDetails'>;
export type EditProductDetailsScreenProps =
  ModalStackChildProps<'EditProductDetails'>;

export type DeliveryProductDetailsScreenProps =
  ModalStackChildProps<'DeliveryProductDetails'>;
export type DeliveryEditProductDetailsScreenProps =
  ModalStackChildProps<'DeliveryEditProductDetails'>;
export type DeliveryOrderInFlightScreenProps =
  ModalStackChildProps<'DeliveryOrderInFlight'>;

export type ReorderActiveBagWarningScreenProps =
  ModalStackChildProps<'ReorderActiveBagWarning'>;
export type ReorderConfirmLocationScreenProps =
  ModalStackChildProps<'ReorderConfirmLocation'>;
export type ReorderChangeLocationScreenProps =
  ModalStackChildProps<'ReorderChangeLocation'>;
export type ReorderConflictReviewScreenProps =
  ModalStackChildProps<'ReorderConflictReview'>;

export type BagScreenProps = ModalStackChildProps<'Bag'>;
export type CheckoutScreenProps = ModalStackChildProps<'Checkout'>;

export type OrderStatusScreenProps = ModalStackChildProps<'OrderStatus'>;

export type CreditAddScreenProps = ModalStackChildProps<'CreditAdd'>;
export type CreditScanScreenProps = ModalStackChildProps<'CreditScan'>;
export type CreditDetailsScreenProps = ModalStackChildProps<'CreditDetails'>;

export type DietaryRestrictionsScreenProps =
  ModalStackChildProps<'DietaryRestrictions'>;

export type RateOrderScreenProps = ModalStackChildProps<'RateOrder'>;
export type ShareLineItemScreenProps = ModalStackChildProps<'ShareLineItem'>;

export type PersonalDataScreenProps = ModalStackChildProps<'PersonalData'>;
export type PersonalDataDetailsScreenProps =
  ModalStackChildProps<'PersonalDataDetails'>;

export type SweetpassBenefitsScreenProps =
  ModalStackChildProps<'SweetpassBenefits'>;
export type SweetpassUpgradeScreenProps =
  ModalStackChildProps<'SweetpassUpgrade'>;
export type SweetpassCheckoutScreenProps =
  ModalStackChildProps<'SweetpassCheckout'>;
export type SweetpassSwitchSubscriptionScreenProps =
  ModalStackChildProps<'SweetpassSwitchSubscription'>;

export type OrderProductConfirmProductScreenProps =
  ModalStackChildProps<'OrderProductConfirmProduct'>;
export type OrderProductConfirmLocationScreenProps =
  ModalStackChildProps<'OrderProductConfirmLocation'>;
export type OrderProductSearchLocationScreenProps =
  ModalStackChildProps<'OrderProductSearchLocation'>;

// ============================================================
// AppStack > Auth
// ============================================================

export type AuthRedirectTarget =
  | 'home'
  | 'sweetpass'
  | 'sweetpassBenefits'
  | 'sweetpassUpgrade'
  | 'sweetpassUpgradeBag'
  | 'locations'
  | 'bag'
  | 'checkout'
  | 'add-gift-card'
  | 'gift-cards'
  | 'gift-redeem'
  | AccountTabScreenId;

export const AuthStackNavigator =
  createNativeStackNavigator<AuthStackParamList>();

export type AuthStackParamList = Readonly<{
  // /join-or-sign-in
  JoinOrSignIn: {
    redirect?: AuthRedirectTarget;
  } & AuthStackRedirectParams;
  // //login
  Login: {
    redirect?: AuthRedirectTarget;
  } & AuthStackRedirectParams;
  // /join
  Join: {
    redirect?: AuthRedirectTarget;
  } & AuthStackRedirectParams;
}>;

type AuthStackSweetpassParamList = {
  redirectToPlanId?: string;
  redirectToCampaignId?: string;
};

type AuthStackGiftCardRedemptionParamList = {
  redirectToGiftCardId?: string;
};

export type AuthStackRedirectParams = AuthStackSweetpassParamList &
  AuthStackGiftCardRedemptionParamList;

export type AuthStackScreenProps = AppStackChildProps<'Auth'>;

// ============================================================
// AppStack > Error
// ============================================================

export const ErrorStackNavigator =
  createNativeStackNavigator<ErrorStackParamList>();

export type ErrorStackParamList = Readonly<{
  // /*
  NotFound: undefined;
  // /:restaurantSlug
  NotFoundWithRestaurantSlug: {
    restaurantSlug: string;
  };
}>;

export type ErrorStackScreenProps = AppStackChildProps<'Error'>;

export type ErrorStackScreenName<
  Keys extends keyof ErrorStackParamList = keyof ErrorStackParamList,
> = keyof Pick<ErrorStackParamList, Keys>;

export type ErrorStackChildProps<T extends ErrorStackScreenName> =
  CompositeScreenProps<
    NativeStackScreenProps<ErrorStackParamList, T>,
    ErrorStackScreenProps
  >;

export type NotFoundScreenProps = ErrorStackChildProps<'NotFound'>;
export type NotFoundWithRestaurantSlugScreenProps =
  ErrorStackChildProps<'NotFoundWithRestaurantSlug'>;
