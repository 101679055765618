import React, {
  type ComponentProps,
  forwardRef,
  useCallback,
  useRef,
  useState,
} from 'react';
import type {
  DimensionValue,
  GestureResponderEvent,
  PressableProps,
  View,
  ViewProps,
} from 'react-native';
import { Pressable, StyleSheet } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import useMergedRef from '@react-hook/merged-ref';
import { theme } from '@garnish/constants';

import { usePressableState } from '../../../hooks';
import { webOnlyStyles } from '../../../utils';
import { LoadingDots } from '../../LoadingDots';
import { Icon } from '../Icon';

export const IconLink = forwardRef<View, IconLinkProps>((props, ref) => {
  const {
    testID,
    accessibilityLabel,
    accessibilityHint,
    accessibilityRole,
    palette = 'light',
    style,
    isLoading,
    disabled,
    width,
    height,
    iconSize,
    color,
    hitSlop,
    onPress,
    ...iconProps
  } = props;

  // ─── References ──────────────────────────────────────────────────────────────

  const localRef = useRef(null);
  const mergedRef = useMergedRef(localRef, ref);

  // ─── State ───────────────────────────────────────────────────────────

  const [isPressed, setPressed] = useState(false);
  const pressableState = usePressableState(localRef);

  const { isHovered, isActive, isFocused } = pressableState;
  const isLight = palette !== 'dark';

  const iconColor = getIconColor({
    disabled,
    palette,
    isPressed,
    isHovered,
    isActive,
    isFocused,
  });

  // ─── Styles ──────────────────────────────────────────────────────────

  const dynamicStyle = useStyle(
    () => ({
      width,
      height,
      backgroundColor: getBackgroundColor({
        palette,
        disabled,
        isHovered: isHovered && !disabled && !isLoading,
        isPressed: isPressed && !disabled && !isLoading,
      }),
    }),
    [width, height, isLight, isHovered, isPressed, palette],
  );

  const pressableStyle = [
    styles.container,
    dynamicStyle,
    iconLinkWebStyles,
    isFocused ? iconLinkFocusedWebStyles : undefined,
    style,
  ];

  // ─── Callbacks ───────────────────────────────────────────────────────

  const onPressIn = useCallback(() => {
    setPressed(true);
  }, [setPressed]);
  const onPressOut = useCallback(() => {
    setPressed(false);
  }, [setPressed]);

  const currentColor = color ?? iconColor;

  return (
    <Pressable
      ref={mergedRef}
      testID={testID ?? 'sg-icon-link'}
      accessibilityRole={accessibilityRole ?? 'button'}
      accessibilityLabel={accessibilityLabel}
      accessibilityHint={accessibilityHint}
      accessibilityState={{ disabled, busy: isLoading }}
      style={pressableStyle}
      disabled={disabled || isLoading}
      hitSlop={hitSlop}
      onPress={onPress}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
    >
      {isLoading ? (
        <LoadingDots color={currentColor} />
      ) : (
        <Icon
          color={currentColor}
          {...(iconSize ? { width: iconSize, height: iconSize } : {})}
          {...iconProps}
        />
      )}
    </Pressable>
  );
});

/** Returns the icon color based on pressable state priorities. */
const getIconColor = (params: IconColorSelection): string => {
  const { palette, disabled, isPressed, isActive, isFocused, isHovered } =
    params;

  const colorMap = PALETTE_STYLE_PROPS_MAP[palette];

  if (disabled) return colorMap.icon.disabled;

  if (isPressed) return colorMap.icon.pressed;

  if (isActive) return colorMap.icon.active;

  if (isFocused) return colorMap.icon.focus;

  if (isHovered) return colorMap.icon.hover;

  return colorMap.icon.default;
};

/** Returns the background color based on pressable state priorities. */
const getBackgroundColor = (params: IconColorSelection): string => {
  const { palette, disabled, isPressed, isHovered } = params;

  const colorMap = PALETTE_STYLE_PROPS_MAP[palette];

  if (!disabled && isPressed) return colorMap.background.pressed;

  if (!disabled && isHovered) return colorMap.background.hover;

  return colorMap.background.default;
};

// ─── Constants ───────────────────────────────────────────────────────────────

const PALETTE_STYLE_PROPS_MAP: PaletteStylePropsMap = {
  light: {
    icon: {
      default: theme.colors.CHARCOAL,
      hover: theme.colors.BLACK,
      focus: theme.colors.CHARCOAL,
      pressed: theme.colors.BLACK,
      active: theme.colors.SPINACH,
      disabled: theme.colors.OPACITY.DARK_KALE.LIGHT,
    },
    background: {
      default: theme.colors.OPACITY.TRANSPARENT,
      hover: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
      pressed: theme.colors.OPACITY.DARK_KALE.LIGHTEST,
    },
  },
  kale: {
    icon: {
      default: theme.colors.CREAM,
      hover: theme.colors.CREAM,
      focus: theme.colors.CREAM,
      pressed: theme.colors.CREAM,
      active: theme.colors.CREAM,
      disabled: theme.colors.OPACITY.DARK_KALE.LIGHTER,
    },
    background: {
      default: theme.colors.KALE,
      hover: theme.colors.KALE_HOVER,
      pressed: theme.colors.KALE_HOVER,
    },
  },
  'oatmeal-almost-transparent': {
    icon: {
      default: theme.colors.CREAM,
      hover: theme.colors.CREAM,
      focus: theme.colors.CREAM,
      pressed: theme.colors.CREAM,
      active: theme.colors.CREAM,
      disabled: theme.colors.OPACITY.DARK_KALE.LIGHTER,
    },
    background: {
      default: theme.colors.OPACITY.OATMEAL.ALMOST_TRANSPARENT,
      hover: theme.colors.OPACITY.OATMEAL.LIGHTER,
      pressed: theme.colors.OPACITY.OATMEAL.LIGHTER,
    },
  },
  'dark-kale': {
    icon: {
      default: theme.colors.DARK_KALE,
      hover: theme.colors.BLACK,
      focus: theme.colors.CHARCOAL,
      pressed: theme.colors.BLACK,
      active: theme.colors.SPINACH,
      disabled: theme.colors.OPACITY.DARK_KALE.LIGHTER,
    },
    background: {
      default: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
      hover: theme.colors.OPACITY.DARK_KALE.LIGHTEST,
      pressed: theme.colors.OPACITY.DARK_KALE.LIGHTER,
    },
  },
  muted: {
    icon: {
      default: theme.colors.CHARCOAL,
      hover: theme.colors.BLACK,
      focus: theme.colors.CHARCOAL,
      pressed: theme.colors.BLACK,
      active: theme.colors.SPINACH,
      disabled: theme.colors.OPACITY.DARK_KALE.LIGHT,
    },
    background: {
      default: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
      hover: theme.colors.OPACITY.DARK_KALE.LIGHTEST,
      pressed: theme.colors.OPACITY.DARK_KALE.LIGHTER,
    },
  },
  dark: {
    icon: {
      default: theme.colors.OATMEAL,
      hover: theme.colors.OATMEAL,
      focus: theme.colors.OATMEAL,
      pressed: theme.colors.OATMEAL,
      active: theme.colors.AVOCADO,
      disabled: theme.colors.OPACITY.OATMEAL.LIGHT,
    },
    background: {
      default: theme.colors.OPACITY.TRANSPARENT,
      hover: theme.colors.OPACITY.OATMEAL.ALMOST_TRANSPARENT,
      pressed: theme.colors.OPACITY.OATMEAL.LIGHTEST,
    },
  },
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    borderRadius: theme.radius.xxxlarge,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const iconLinkWebStyles = webOnlyStyles({
  outlineColor: theme.colors.GRAY,
  outlineOffset: 2,
});
const iconLinkFocusedWebStyles = webOnlyStyles({
  outlineStyle: 'solid',
});

// ─── Types ───────────────────────────────────────────────────────────────────

type IconLinkProps = Readonly<{
  onPress?: ((event: GestureResponderEvent) => void) | null;
  palette?: Palette;
  disabled?: boolean;
  isLoading?: boolean;
  style?: ViewProps['style'];
  iconSize?: number;
  width?: DimensionValue;
  height?: DimensionValue;
}> &
  Omit<ComponentProps<typeof Icon>, 'style' | 'width' | 'height'> &
  Pick<
    PressableProps,
    | 'hitSlop'
    | 'accessibilityLabel'
    | 'accessibilityHint'
    | 'accessibilityRole'
    | 'testID'
  >;

type IconColorSelection = Readonly<{
  palette: Palette;
  disabled?: boolean;
  isPressed?: boolean;
  isHovered?: boolean;
  isActive?: boolean;
  isFocused?: boolean;
}>;

type PaletteStylePropsMap = Record<Palette, PaletteStyleProps>;

type PaletteStyleProps = {
  icon: {
    default: string;
    hover: string;
    pressed: string;
    active: string;
    focus: string;
    disabled: string;
  };
  background: {
    default: string;
    hover: string;
    pressed: string;
  };
};

type Palette =
  | 'dark'
  | 'light'
  | 'kale'
  | 'oatmeal-almost-transparent'
  | 'dark-kale'
  | 'muted';
